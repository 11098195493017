import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import './InstallationCompletionDone.scss';


export function InstallationCompletionDone() {

	const l10n = useL10n();

	return (
		<section className="installation-completion-done">
			<img src={`../../assets/lib-oms-ui-domain/images/spinner-done.svg`} alt={'done'} />
			<span className="installation-completion-done__heading">
				{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.done')}
			</span>
		</section>
	);
}
