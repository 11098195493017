import React, { ReactNode } from 'react';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './AnswerSection.scss';

type AnswerSectionProps = {
	children: ReactNode,
	heading: string
};

export function AnswerSection(props: AnswerSectionProps) {
	const { children, heading } = props;
	return (
		<div className="answer-section">
			<div className="answer-section__header">
				<span className="answer-section__icon">
					<Icon name={IconIdentifier.INFO} size={IconSize.MEDIUM} />
				</span>
				<div className="answer-section__header__title">{heading}</div>
			</div>
			<div className="answer-section__items">
				{children}
			</div>
		</div>
	);
}
