import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Something went wrong',
				message: 'An error occurred that was not handled properly',
				resolveAction: {
					label: 'Reload'
				}
			},
			recoverLabel: 'Try again',
			fetchHeading: 'Fetch failed',
			mutateHeading: 'Update failed',
			createHeading: 'Creation failed',
			deleteHeading: 'Deletion failed',
			taskAssignHeading: 'Assigning failed',
			taskCompleteHeading: 'Completion failed'
		}
	}
};

export default literals;
