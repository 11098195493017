import React from 'react';

import { DashboardLayoutProps } from '../app-layout/AppLayout';

import './AppLayoutMobile.scss';

export function AppLayoutMobile(props: DashboardLayoutProps) {
	const { children } = props;

	return (
		<article className="app-layout-mobile">
			{children}
		</article>
	);
}
