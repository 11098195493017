import { ReactNode } from 'react';

import './InstallationCompletionLayout.scss';

type InstallationCompletionlLayoutProps = {
	children: ReactNode
};

/*
* InstallationCompletionlLayout is needed for the proper presentation of InstallationCompletion in the InstallationPartnerServiceApp. However, it is
* NOT defined in InstallationCompletion.scss as it is also needed for proper error and pending state rendering.
* */
export function InstallationCompletionlLayout(props: InstallationCompletionlLayoutProps) {
	const { children } = props;

	return (
		<section className='installation-completion-layout'>
			{children}
		</section>
	);
}
