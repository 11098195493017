import { Route } from 'react-router-dom';

import { useSentryRoutes } from '@abb-emobility/shared/sentry-integration';

import { RoutePath, useRouteUrl } from './Routes';
import { AppointmentsView } from '../views/appointments-view/AppointmentsView';
import { InstallationCompletionView } from '../views/installation-completion-view/InstallationCompletionView';
import { InstallationDetailView } from '../views/installation-detail-view/InstallationDetailView';
import { NotFoundView } from '../views/not-found-view/NotFoundView';

export function Router() {

	const { createRouteUrl } = useRouteUrl();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={createRouteUrl(RoutePath.ROOT)} element={<AppointmentsView />} />
			<Route path={createRouteUrl(RoutePath.APPOINTMENT)} element={<InstallationDetailView />} />
			<Route path={createRouteUrl(RoutePath.INSTALLATION_COMPLETION)} element={<InstallationCompletionView />} />
			<Route path="*" element={<NotFoundView />} />
		</Routes>
	);
}
