import React, { ReactNode } from 'react';

import { CalendarItemAppointment } from '../calendar-item-appointment/CalendarItemAppointment';

import './CalendarItemPicker.scss';

export type CalendarItemPickerProps = {
	hasAppointment?: boolean,
	dayName?: string,
	dayNumber?: string,
	to?: string
};

export function CalendarItemPicker(props: CalendarItemPickerProps) {
	const { hasAppointment, dayName, dayNumber, to } = props;

	const renderAppointment = (): ReactNode => {
		if (!hasAppointment) {
			return null;
		}
		return (
			<div className="calendar-item-picker__appointment">
				<CalendarItemAppointment />
			</div>
		);
	};

	return (
		<a className="calendar-item-picker" href={to}>
			{renderAppointment()}
			<div className="calendar-item-picker__content">
				<span className="calendar-item-picker__content__item--day">{dayName}</span>
				<span className="calendar-item-picker__content__item--date">{dayNumber}</span>
			</div>
		</a>
	);
}
