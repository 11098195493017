import React from 'react';

import { InstallationPartnerOrderInformationBasicAnswersModel } from '@abb-emobility/oms/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { convertApiPersistedAnswersToPersistedAnswers } from '@abb-emobility/shared/questionnaire';
import { CollectionItemContentSection, OrderComment, SectionHeader } from '@abb-emobility/shared/ui-primitive';
import { QuestionnaireAnswers } from '@abb-emobility/shared/ui-questionnaire';

type BasicAnswersProps = {
	orderInformation: InstallationPartnerOrderInformationBasicAnswersModel
};

export function OrderInformationBasicAnswers(props: BasicAnswersProps) {
	const { orderInformation } = props;

	const l10n = useL10n();

	const renderNotes = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.precheck.questionnaire.heading')} />
				<QuestionnaireAnswers
					questionnaire={orderInformation.payload.questionnaire}
					answers={convertApiPersistedAnswersToPersistedAnswers(
						orderInformation.payload.answers
					)}
					environment={orderInformation.payload.questionnaireEnvironment}
				/>
			</CollectionItemContentSection>
			{renderNotes()}
		</>
	);
}
