import React, { ReactNode } from 'react';

import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';

import { DownloadListItem } from '../download-list-item/DownloadListItem';

import './AnswerDocument.scss';

export type AnswerDocumentProps = {
	title: string,
	hint?: ReactNode,
	file: DownloadFile
};

export function AnswerDocument(props: AnswerDocumentProps) {
	const { title, hint = null, file } = props;

	const renderHint = (): ReactNode => {
		if (hint === null) {
			return null;
		}
		return (
			<span className="answer-image__hint">{hint}</span>
		);
	};

	return (
		<div className="answer-document">
			<div className="answer-document__title">
				<span>{title}</span>
				{renderHint()}
			</div>
			<div className="answer-document__item">
				<DownloadListItem file={file} />
			</div>
		</div>
	);
}
