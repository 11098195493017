import { createContext, useContext } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { TopbarCaption, TopbarIsVisible, TopbarLink } from './MobileTopbarContent.types';

export type TopbarContextValue = {
	setLink: (link: Nullable<TopbarLink>) => void,
	getLink: () => Nullable<TopbarLink>,
	setCaption: (caption: Nullable<TopbarCaption>) => void,
	getCaption: () => Nullable<TopbarCaption>,
	setIsVisible: (isVisible: boolean) => void,
	getIsVisible: () => boolean
};

export const mobileTopbarContentContext = createContext<TopbarContextValue>({
	setLink: (_link: Nullable<TopbarLink>): void => {
		throw new Error('No topbar provided');
	},
	getLink: (): Nullable<TopbarLink> => {
		throw new Error('No topbar provided');
	},
	setCaption: (_caption: Nullable<TopbarCaption>): void => {
		throw new Error('No topbar provided');
	},
	getCaption: (): Nullable<TopbarCaption> => {
		throw new Error('No topbar provided');
	},
	setIsVisible: (_isVisible: TopbarIsVisible): void => {
		throw new Error('No topbar provided');
	},
	getIsVisible: (): TopbarIsVisible => {
		throw new Error('No topbar provided');
	}
});

export const useTopbar = () => {
	return useContext(mobileTopbarContentContext);
};
