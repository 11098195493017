import React, { ReactNode, useEffect, useState } from 'react';

import { addLocalStorageListener, removeLocalStorageListener } from '@abb-emobility/shared/local-storage';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { identityContext } from './IdentityContext';
import { IdentityHandler } from './IdentityHandler';
import { Identity } from '../foundation/Identity';

export type IdentityProviderProps = {
	children: ReactNode,
	identityScope?: string
};

export const IdentityProvider = (props: IdentityProviderProps): React.JSX.Element => {

	const { children, identityScope } = props;

	const identityHandler = IdentityHandler.get(identityScope);

	const [identity, setIdentity] = useState<Nullable<Identity>>(identityHandler.getIdentity().get());

	useEffect(() => {
		addLocalStorageListener(handleStorageChange);
		return () => {
			removeLocalStorageListener(handleStorageChange);
		};
	}, []);

	const handleStorageChange = () => {
		setIdentity(identityHandler.getIdentity().get());
	};

	const contextValue = {
		setIdentity: (identity: Identity): void => {
			identityHandler.setIdentity(identity);
			setIdentity(identity);
		},
		unsetIdentity: (): void => {
			identityHandler.unsetIdentity();
			setIdentity(null);
		},
		getIdentity: (): Optional<Identity> => {
			return new Optional(identity);
		}
	};

	return (
		<identityContext.Provider value={contextValue}>
			{children}
		</identityContext.Provider>
	);

};
