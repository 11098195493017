export function TermsPt() {

	return (
		<>
			<h1>Declara&ccedil;&atilde;o</h1>
			<p>Confirmo que sou totalmente competente e qualificado de acordo com as leis, regulamentos, legisla&ccedil;&atilde;o local e normas aplic&aacute;veis para realizar o trabalho de instala&ccedil;&atilde;o e configura&ccedil;&atilde;o el&eacute;trica (incluindo quaisquer diplomas, certificados, licen&ccedil;as ou registos necess&aacute;rios) e que tenho autoridade para agir em nome e por conta da empresa de instala&ccedil;&atilde;o nomeada pela ABB E-mobility B.V. Tenho pleno conhecimento e compreendo isto assim como os requisitos para a instala&ccedil;&atilde;o e configura&ccedil;&atilde;o seguras das ABB E-Mobility Wallboxes e cumprirei todas as leis e regulamentos (locais) aplic&aacute;veis, a situa&ccedil;&atilde;o t&eacute;cnica atual, bem como quaisquer instru&ccedil;&otilde;es fornecidas pela ABB E-Mobility B.V., em particular de acordo com o <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">manual de opera&ccedil;&atilde;o e instala&ccedil;&atilde;o.</a></p>
			<p>Aplicam-se os <a href="https://abb.com" target="_blank" rel="noopener noreferrer">termos do utilizador</a> relativos ao acesso e &agrave; utiliza&ccedil;&atilde;o desta aplica&ccedil;&atilde;o.</p>
		</>
	);
}
