import { useContext } from 'react';

import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { CrudCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';

import { installationPartnerOrderInformationCollectionData } from './InstallationPartnerOrderInformationCollectionData';

export const useInstallationPartnerOrderInformationCollectionData = (): CrudCollectionDataProviderValue<AnyInstallationPartnerOrderInformationCollectionItemModel> => {
	return useContext(installationPartnerOrderInformationCollectionData);
};
