import React from 'react';
import { useParams } from 'react-router-dom';

import { InstallerAppointmentCollectionDataProvider } from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { AppLayoutMobileTopbar, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { isToday, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { InstallationCompletion } from '../../components/installation-completion/InstallationCompletion';
import {
	InstallationCompletionDataAssembler
} from '../../components/installation-completion-data-assembler/InstallationCompletionDataAssembler';
import { InstallationCompletionlLayout } from '../../components/installation-completion-layout/InstallationCompletionLayout';
import { MobileTopbar } from '../../components/mobile-topbar/MobileTopbar';
import { MobileTopbarNavigate } from '../../components/mobile-topbar/mobile-topbar-navigate/MobileTopbarNavigate';
import { useTopbar } from '../../components/mobile-topbar-content/MobileTopbarContent.context';
import { useTopbarLinkModifier, useTopbarVisibilityModifier } from '../../components/mobile-topbar-content/MobileTopbarContent.hooks';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function InstallationCompletionView() {

	const params = useParams();
	const l10n = useL10n();
	const topbar = useTopbar();
	const { createRouteUrl } = useRouteUrl();

	const installerAppointmentId = params.installerAppointmentId ?? null;
	if (installerAppointmentId === null) {
		throw new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.generic.message'));
	}

	// Set topbar link
	useTopbarLinkModifier({
		link: createRouteUrl(RoutePath.APPOINTMENT, ['installerAppointmentId', installerAppointmentId]),
		label: l10n.translate('omsInstallationPartnerServiceApp.topbar.installationDetail.backLinkLabel')
	});
	useTopbarVisibilityModifier(true);

	const handleInstallerAppointmentQuery = (installerAppointment: InstallerAppointmentModel): void => {
		// Set topbar contents
		let topbarCaption: string;
		if (isToday(createDateFromTimestamp(installerAppointment.period.start))) {
			topbarCaption = l10n.translate(l10nLiteralFromEnumValue(
				l10n.formatTimestampDayname(installerAppointment.period.start, '', DaynameFormat.FUZZY),
				'omsInstallationPartnerServiceApp.formatter.fuzzyDaynames'
			));
		} else {
			topbarCaption = l10n.formatTimestampDayname(installerAppointment.period.start, '', DaynameFormat.LONG);
		}

		topbarCaption += ' ' + l10n.translate('omsInstallationPartnerServiceApp.topbar.installationDetail.captionPrefix');
		topbarCaption += ' ' + l10n.formatTimestampTime(installerAppointment.period.start);
		topbar.setCaption(topbarCaption);
	};

	return (
		<>
			<AppLayoutMobileTopbar>
				<MobileTopbar fixed={true}>
					<MobileTopbarNavigate />
				</MobileTopbar>
			</AppLayoutMobileTopbar>
			<InstallationCompletionlLayout>
				<UiErrorHandler>
					<InstallerAppointmentCollectionDataProvider
						pendingComponent={SpinnerCircle}
					>
						<InstallationCompletionDataAssembler
							installerAppointmentId={installerAppointmentId}
							onQuery={handleInstallerAppointmentQuery}
						>
							<InstallationCompletion
								installerAppointmentId={installerAppointmentId}
							/>
						</InstallationCompletionDataAssembler>
					</InstallerAppointmentCollectionDataProvider>
				</UiErrorHandler>
			</InstallationCompletionlLayout>
		</>
	);
}
