import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import './CardSection.scss';

export enum Direction {
	ROW = 'ROW',
	COLUMN = 'COLUMN'
}

export type CardSectionProps = {
	children: ReactNode,
	direction?: Direction
};

export function CardSection(props: CardSectionProps) {
	const { children, direction = Direction.ROW } = props;

	return (
		<section className={`card-section ${buildCssClassNameFromEnumValue(direction, 'card-section')}`}>
			{children}
		</section>
	);
}
