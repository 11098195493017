import React, { useEffect } from 'react';

import { useInstallerAppointmentCollectionData } from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AccessDeniedError, NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { AnyTaskEntityDataProvider } from '@abb-emobility/usertask/data-provider';

type InstallationCompletionDataAssemblerProps = {
	installerAppointmentId: ModelPrimaryKey,
	onQuery: (installerAppointment: InstallerAppointmentModel) => void,
	children: React.ReactNode
};

export function InstallationCompletionDataAssembler(props: InstallationCompletionDataAssemblerProps) {

	const { installerAppointmentId, onQuery, children } = props;

	const l10n = useL10n();

	const installerAppointmentData = useInstallerAppointmentCollectionData();
	const installerAppointment = installerAppointmentData
		.queryEntity(installerAppointmentId)
		.getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.installerAppointmentNotFound')));

	useEffect(() => {
		onQuery(installerAppointment);
	}, []);

	// if the completionTaskId is not defined the user cannot / must not complete the task
	const completionTaskId = installerAppointment.completionTaskId ?? null;
	if (completionTaskId === null) {
		throw new AccessDeniedError(l10n.translate('omsInstallationPartnerServiceApp.error.installationCompletion.notAllowed'));
	}

	return (
		<AnyTaskEntityDataProvider
			primaryKey={completionTaskId}
			pendingComponent={SpinnerCircle}
		>
			{children}
		</AnyTaskEntityDataProvider>

	);
}
