import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonTertiary, Card, Cardlayout, CardSection, IconIdentifier } from '@abb-emobility/shared/ui-primitive';

import { useTopbar } from '../../mobile-topbar-content/MobileTopbarContent.context';

import './MobileTopbarNavigate.scss';

export function MobileTopbarNavigate() {

	const navigate = useNavigate();
	const topbar = useTopbar();

	const link = topbar.getLink();
	const date = topbar.getCaption();
	const isVisible = topbar.getIsVisible();

	const handleOnClick = () => {
		const cb = link?.callback ?? null;
		const url = link?.link ?? null;
		if (cb !== null) {
			cb();
		} else if (url !== null) {
			navigate(url);
		}
	};

	const renderTopbarDate = (): ReactNode => {
		if (date === null) {
			return null;
		}

		return (
			<div className="mobile-topbar-navigate__date">
				{date}
			</div>
		);

	};

	const renderTopbar = (): ReactNode => {
		if (!isVisible) {
			return null;
		}
		return (
			<div className={'mobile-topbar-navigate'}>
				<Card>
					<Cardlayout>
						<CardSection>
							<ButtonTertiary onClick={handleOnClick} label={link?.label ?? ''} icon={IconIdentifier.ARROW_LEFT} />
							{renderTopbarDate()}
						</CardSection>
					</Cardlayout>
				</Card>
			</div>
		);
	};

	return (
		<>
			{renderTopbar()}
		</>
	);
}
