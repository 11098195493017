import { TaskCompletionCommentModel } from '@abb-emobility/shared/domain-model';

import { Gravatar } from '../gravatar/Gravatar';

import './OrderComment.scss';

type OrderInformationCommentProps = {
	comment: TaskCompletionCommentModel
};

export function OrderComment(props: OrderInformationCommentProps) {
	const { comment } = props;

	return (
		<section className="order-comment">
			<div className="order-comment__avatar">
				<Gravatar name={comment.author.name} mailAddress={comment.author.mailAddress} />
				<div className="order-comment__avatar__name">{comment.author.name}</div>
			</div>
			<div className="order-comment__text">
				{comment.comment}
			</div>
		</section>
	);
}
