import './Userbar.scss';

import { TopbarProps } from '../topbar/Topbar';

export function Userbar(props: TopbarProps) {

	const { children } = props;

	return (
		<div className="userbar">
			{children}
		</div>
	);
}
