import {
	createDownloadFileFromDownloadFileDto,
	isDownloadFileDto
} from '@abb-emobility/shared/domain-model-foundation';

import { PersistedAnswersDto, PersistedAnswers } from './Answers';

export const convertApiPersistedAnswersToPersistedAnswers = (
	apiPersistedAnswers: PersistedAnswersDto
): PersistedAnswers => {
	const persistedAnswers: PersistedAnswers = {};
	const keys = Object.keys(apiPersistedAnswers);
	for (const key of keys) {
		const apiPersistedAnswer = apiPersistedAnswers[key];
		if (isDownloadFileDto(apiPersistedAnswer)) {
			persistedAnswers[key] =
				createDownloadFileFromDownloadFileDto(
					apiPersistedAnswer
				).getOrUndefined();
			continue;
		}
		persistedAnswers[key] = apiPersistedAnswer;
	}
	return persistedAnswers;
};
