import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InstallerAppointmentCollectionDataProvider } from '@abb-emobility/oms/data-provider';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { AppLayoutMobileTopbar, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { AppointmentCollection } from '../../components/appointment-collection/AppointmentCollection';
import { MobileTopbar } from '../../components/mobile-topbar/MobileTopbar';
import { MobileTopbarUser } from '../../components/mobile-topbar/mobile-topbar-user/MobileTopbarUser';
import {
	useTopbarCaptionModifier,
	useTopbarLinkModifier,
	useTopbarVisibilityModifier
} from '../../components/mobile-topbar-content/MobileTopbarContent.hooks';
import { useBuildPageTitle } from '../../page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function AppointmentsView() {

	const auth = useAuth();
	const navigate = useNavigate();
	const { createRouteUrl } = useRouteUrl();

	usePageTitle(useBuildPageTitle('omsInstallationPartnerServiceApp.pageTitle.appointments'));
	// Reset topbar contents
	useTopbarLinkModifier(null);
	useTopbarCaptionModifier(null);
	useTopbarVisibilityModifier(true);

	const handleNavigate = (installerAppointmentId: string) => {
		navigate(createRouteUrl(RoutePath.APPOINTMENT, ['installerAppointmentId', installerAppointmentId]));
	};

	const handleLogout = () => {
		auth.unauthenticate();
	};

	return (
		<>
			<AppLayoutMobileTopbar>
				<MobileTopbar>
					<MobileTopbarUser onLogout={handleLogout} />
				</MobileTopbar>
			</AppLayoutMobileTopbar>
			<UiErrorHandler>
				<InstallerAppointmentCollectionDataProvider
					pendingComponent={SpinnerCircle}
				>
					<AppointmentCollection onNavigate={handleNavigate} />
				</InstallerAppointmentCollectionDataProvider>
			</UiErrorHandler>
		</>
	);
}
