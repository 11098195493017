import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'Serviço de instalação de wallbox ABB',
		appointmentCollection: {
			heading: 'Datas',
			header: {
				today: 'Hoje',
				upcoming: 'Em breve'
			},
			emptyState: {
				heading: 'Sem marcação',
				message: 'Não foi possível encontrar qualquer marcação.'
			}
		},
		installationCompletion: {
			done: 'Instalação concluída!',
			upload: {
				heading: 'Fornecer documentos'
			},
			confirmation: {
				heading: 'Wallbox instalada',
				text: 'Confirmo por este meio a instalação e a colocação em funcionamento corretas e profissionais da Wallbox fornecida com o seguinte número de série {{serialNumber}}.',
				throttle: 'Por este meio, garanto que o consumo de energia da Wallbox foi reduzido de acordo com as exigências do operador da rede.'
			},
			inspection: {
				heading: 'Inspeção',
				customerPresent: 'Inspeção com o cliente',
				customerNotPresent: 'O cliente não está presente'
			},
			acceptance: {
				heading: 'Aceitação'
			},
			signature: {
				clear: 'Transparente',
				close: 'Fechar',
				done: 'Pronto'
			},
			action: {
				signature: 'Assinatura do cliente',
				complete: 'Instalação completa'
			}
		},
		installationDetail: {
			action: 'Instalação completa',
			heading: 'Instalação',
			appointmentCollection: {
				heading: 'Trabalhos e nomeações',
				item: {
					installationPeriod: {
						preparation: 'Preparação da instalação',
						beginning: 'Início da instalação',
						permanent: 'Permanente',
						end: 'Fim da instalação',
						followUp: 'Acompanhamento da instalação'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Indeterminado',
				standard: 'Padrão',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Nomeações',
			detail: 'Detalhes',
			installationCompletion: 'Conclusão da instalação',
			error: 'Erro',
			notFound: 'Não encontrado'
		},
		formatter: {
			dayNames: [
				'Domingo',
				'Segunda',
				'Terça',
				'Quarta',
				'Quinta',
				'Sexta',
				'Sábado'
			],
			fuzzyDaynames: {
				today: 'hoje',
				tomorrow: 'amanhã',
				yesterday: 'ontem'
			}
		},
		navigation: {
			tasks: 'Tarefas',
			logout: 'Terminar sessão'
		},
		breadcrumbs: {
			orders: 'Síntese da encomenda'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'voltar',
				captionPrefix: ' em '
			},
			installationCompletion: {
				backLinkLabel: 'voltar',
				captionPrefix: ' em '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'A obtenção dos compromissos falhou',
				message: 'Durante o carregamento das informações solicitadas, ocorreu um erro.'
			},
			installationCompletion: {
				heading: 'Falha ao concluir a instalação',
				message: 'Durante a conclusão da tarefa, ocorreu um erro.',
				resolveAction: {
					label: 'Voltar à página de detalhes'
				},
				notAllowed: 'A conclusão da instalação não é permitida.'
			},
			installerAppointmentNotFound: 'A Nomeação do Instalador não foi encontrada',
			orderNotFound: 'Encomenda não encontrada',
			installationDetail: {
				heading: 'Falha na obtenção dos dados de instalação',
				message: 'Durante o carregamento das informações solicitadas, ocorreu um erro.'
			},
			validation: {
				heading: 'Dados inválidos',
				message: 'Os dados que enviou não são válidos.'
			},
			generic: {
				heading: 'Algo correu mal',
				message: 'Ocorreu um erro que não foi tratado durante o processamento',
				resolveAction: {
					retry: {
						label: 'Recarregar'
					}
				}
			},
			offline: {
				heading: 'Ligação de rede indisponível',
				message: 'Ligue-se a uma rede para utilizar a aplicação.'
			},
			notFound: {
				heading: 'Não encontrado',
				message: 'O recurso solicitado não foi encontrado',
				resolveAction: {
					label: 'Painel de controlo'
				}
			},
			task: {
				notFound: {
					message: 'Tarefa não encontrada'
				},
				complete: {
					message: 'Durante a conclusão da tarefa, ocorreu um erro.'
				}
			}
		}
	}
};

export default literals;
