import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Manhã',
				noon: 'Meio-dia',
				afternoon: 'Tarde',
				evening: 'Noite'
			}
		},
		gridOperatorInformation: {
			heading: 'Operador de rede'
		},
		informationHead: {
			orderDate: 'Data da encomenda',
			orderNumber: 'N.º da encomenda'
		},
		imageThumbnail: {
			resolveErrorMessage: 'A resolução do URL da imagem falhou'
		},
		task: {
			status: {
				started: 'Em breve',
				overdue: 'Atrasada',
				escalated: 'Encaminhada',
				done: 'Pronto',
				doneOverdue: 'Pronto',
				doneEscalated: 'Pronto'
			}
		},
		upload: {
			empty: {
				message: 'Nenhum ficheiro selecionado'
			},
			dropMessage: 'Arrastar e largar ficheiros aqui',
			selectButtonLabel: 'Selecionar ficheiro',
			hint: 'Ficheiros suportados: JPG, PNG ou PDF mais pequeno do que {{maxFilesize}} no total.',
			fileSizeWarning: 'Os ficheiros selecionados excedem o tamanho total máximo permitido para os ficheiros. Alguns dos ficheiros selecionados não serão enviados.'
		},
		choose: {
			label: 'Escolher'
		},
		search: {
			foundIn: 'Encontrado em',
			noResults: 'Nenhum resultado encontrado'
		},
		termsAccept: {
			buttonLabel: 'Aceitar'
		},
		collection: {
			filter: {
				filter: 'Filtro',
				none: 'nenhuma',
				active: '{{contagem}} ativa',
				resetFilter: 'Reiniciar filtro',
				group: {
					dueDate: 'Data de vencimento',
					priority: 'Prioridade',
					estimatedCompletionDate: 'Data prevista para a instalação',
					state: 'Estado',
					installationType: 'Instalação',
					runningOrderCount: 'Encomendas a decorrer',
					languageCode: 'Idioma'
				},
				options: {
					dueDateOverdue: 'Atrasada',
					dueDateToday: 'Prazo para entrega hoje',
					priorityHigh: 'Prioridade elevada',
					priorityMedium: 'Prioridade média',
					priorityLow: 'Prioridade baixa',
					statusDone: 'Pronto',
					statusOpen: 'Aberto',
					installationTypeStandard: 'Instalação standard',
					installationTypeDelta: 'Instalação Delta',
					installationTypeUndetermined: 'Indeterminado',
					installationTypeCancelled: 'Cancelado',
					ordersActive: 'Tem encomendas em aberto',
					ordersSettled: 'Tem encomendas liquidadas',
					onSchedule: 'Dentro do prazo',
					delayed: 'Atrasada',
					cancelled: 'Cancelada',
					imported: 'Importado',
					validationFailed: 'Inválido',
					done: 'Pronto',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Ordenar por',
				direction: {
					ascending: 'Ascendente',
					descending: 'Descendente'
				},
				options: {
					priority: 'Prioridade',
					estimatedCompletionDate: 'Data prevista de conclusão',
					status: 'Estado',
					installationDate: 'Data prevista para a instalação',
					orderDate: 'Data da encomenda',
					estimatedDeliveryDate: 'Data prevista para a entrega',
					shippingState: 'Estado do envio',
					name: 'Nome',
					firstOrder: 'Primeira encomenda',
					lastOrder: 'Última encomenda',
					dueDate: 'Data de vencimento'
				}
			}
		}
	}
};

export default literals;
