import { Nullable } from '@abb-emobility/shared/util';

import { TopbarLink } from './MobileTopbarContent.types';

export const buildTopbarLinkChecksum = (link: Nullable<TopbarLink>): string => {
	if (link === null) {
		return '';
	}
	return link.link + '_' + link.label;
};
