import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiAuthEmitter: {
		error: {
			oauth: {
				retry: {
					label: 'Försök igen'
				},
				heading: 'Autentiseringen misslyckades',
				message: 'Något gick fel när du utförde din inloggning.'
			}
		}
	}
};

export default literals;
