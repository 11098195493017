import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Informação básica',
				installationPartnerArrangeAppointment: 'Efetuar marcação',
				installationPartnerExecuteRemotePreCheck: 'Pré-verificação',
				installationPartnerRegisterAtGridOperator: 'Registo do operador de rede',
				installationPartnerProvideGridOperatorDecision: 'Decisão do operador de rede',
				installationPartnerRevokeGridOperatorRegistration: 'Revogação do operador de rede',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Conclusão da instalação'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Preço total (líquido)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Dentro do prazo',
				delayed: 'Atrasada',
				cancelPending: 'Cancelar pendente',
				cancelled: 'Cancelada',
				done: 'Pronto'
			},
			installationType: {
				none: 'Indeterminado',
				undetermined: 'Indeterminado',
				cancelled: 'Cancelado',
				standard: 'Padrão',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Cancelar marcações'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Atenção',
				message: 'O consumo de energia da Wallbox tem de ser reduzido de acordo com os requisitos do operador da rede.'
			},
			informationList: {
				heading: 'Informação',
				emptyState: {
					heading: 'Ainda não estão disponíveis informações sobre a encomenda',
					message: 'À medida que a encomenda avança, encontrará aqui todas as informações relevantes sobre a encomenda.'
				},
				caption: {
					basicAnswers: 'Respostas básicas',
					gridOperatorRegistration: 'Registo do operador de rede',
					gridOperatorDecision: 'Decisão do operador de rede',
					preCheck: 'Verificação prévia',
					offer: 'Oferta',
					approval: 'Aprovação'
				},
				precheck: {
					heading: 'Verificação prévia',
					effort: 'Verificar previamente os resultados: Prazos e esforço de instalação',
					questionnaire: {
						heading: 'Respostas dos clientes'
					},
					installationType: {
						undetermined: 'Indeterminado',
						standard: 'Instalação standard',
						delta: 'Instalação Delta'
					},
					decision: {
						accepted: 'Informação suficiente',
						more: 'Mais informações necessárias',
						denied: 'Instalação não é possível'
					},
					notes: 'Notas',
					hours: 'horas'
				},
				decision: {
					heading: 'Decisão do operador de rede',
					decision: {
						pending: 'O pedido de instalação ainda está pendente',
						accepted: 'O operador da rede concordou com a instalação',
						denied: 'O operador da rede recusou a instalação'
					}
				},
				registration: {
					heading: 'Registo do operador de rede',
					documents: 'Documentos',
					gridOperator: 'Operador de rede'
				},
				quotation: {
					heading: 'Orçamento',
					conditions: 'Condições da oferta'
				},
				complete: {
					success: {
						heading: 'Instalação bem-sucedida'
					},
					notes: 'Notas',
					images: 'Imagens relacionadas com a instalação',
					documents: 'Documentos relacionados com a instalação',
					signature: 'Assinatura do cliente'
				}
			}
		},
		orderLineItems: {
			heading: 'Artigos da linha de encomendas',
			head: {
				title: 'Artigo da linha',
				description: 'Descrição',
				productCode: 'Código do produto ABB'
			},
			serial: 'Número de série da Wallbox',
			shippingStatus: {
				label: 'Estado do envio #{{shipmentNumber}}',
				pending: 'Pendente',
				delivered: 'Entregue',
				expected: 'Entrega prevista em '
			},
			empty: 'Não há itens de linha de ordem disponíveis'
		}
	}
};

export default literals;
