import React from 'react';

import { useInstallationPartnerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { InformationHead } from '@abb-emobility/shared/ui-primitive';
import { isString, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './InstallationDetailHeader.scss';

type HeaderProps = {
	installerAppointment: InstallerAppointmentModel
};

export function InstallationDetailHeader(props: HeaderProps) {

	const { installerAppointment } = props;

	const l10n = useL10n();

	const orderData = useInstallationPartnerOrderEntityData();
	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.orderNotFound')));

	const renderInstallationAddress = (): string => {
		const address = order.installationAddress;
		if (isString(address)) {
			return address;
		}
		return address.street + ', ' + address.zip + ' ' + address.city;
	};

	const renderCompanyName = () => {
		if (order.customer.companyName === undefined) {
			return null;
		}
		return (
			<span className="installation-detail-header__customer-info__name">{order.customer.companyName}</span>
		);
	};

	return (
		<article className="installation-detail-header">
			<InformationHead
				informationKey={l10n.translate('omsInstallationPartnerServiceApp.installationDetail.heading')}
				informationValue={l10n.translate(l10nLiteralFromEnumValue(order.installationType, 'omsInstallationPartnerServiceApp.order.installationType'))}
			/>
			<article className="installation-detail-header__date">
				<span>{l10n.formatTimestampDayname(installerAppointment.period.start)}, {l10n.formatTimestampDate(installerAppointment.period.start)}</span>
				<span>{l10n.formatTimestampTime(installerAppointment.period.start) + ' – ' + l10n.formatTimestampTime(installerAppointment.period.end)}</span>
			</article>
			<article className="installation-detail-header__customer-info">
				{renderCompanyName()}
				<span className="installation-detail-header__customer-info__name">{order.customer.name}</span>
				<span className="installation-detail-header__customer-info__address">{renderInstallationAddress()}</span>
			</article>
			<a className="installation-detail-header__customer-phone" href={`tel:${order.customer.phoneNumber}`}>{order.customer.phoneNumber}</a>
		</article>
	);
}
