import { MD5 } from 'crypto-es/lib/md5';

import { Optional } from '@abb-emobility/shared/util';

export const buildGravatarUrl = (mailAddress?: string, size = 64): Optional<string> => {
	let url = null;
	if (mailAddress !== undefined) {
		url = 'https://www.gravatar.com/avatar/' + MD5(mailAddress.trim().toLowerCase()) + '?s=' + size + '&d=404';
	}
	return new Optional<string>(url);
};
