import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Morgon',
				noon: 'Lunch',
				afternoon: 'Eftermiddag',
				evening: 'Kväll'
			}
		},
		gridOperatorInformation: {
			heading: 'Nätoperatör'
		},
		informationHead: {
			orderDate: 'Orderdatum',
			orderNumber: 'Ordernr.'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Lösning av bild-URL misslyckades'
		},
		task: {
			status: {
				started: 'Kommande',
				overdue: 'Förfallen',
				escalated: 'Eskalerad',
				done: 'Klar',
				doneOverdue: 'Klar',
				doneEscalated: 'Klar'
			}
		},
		upload: {
			empty: {
				message: 'Inga filer valda'
			},
			dropMessage: 'Dra och släpp filer här',
			selectButtonLabel: 'Välj fil',
			hint: 'Filer som stöds: JPG, PNG eller PDF som är mindre än {{maxFilesize}} totalt.',
			fileSizeWarning: 'De filer du valt överskrider den tillåtna maximala totala filstorleken. Vissa av de utvalda filerna kommer inte att skickas in.'
		},
		choose: {
			label: 'Välj'
		},
		search: {
			foundIn: 'Hittad i',
			noResults: 'Inga resultat hittades'
		},
		termsAccept: {
			buttonLabel: 'Godta'
		},
		collection: {
			filter: {
				filter: 'Filter',
				none: 'inga',
				active: '{{count}} aktiva',
				resetFilter: 'Återställ filter',
				group: {
					dueDate: 'Förfallodag',
					priority: 'Prioritet',
					estimatedCompletionDate: 'Planerat installationsdatum',
					state: 'Status',
					installationType: 'Installation',
					runningOrderCount: 'Löpande ordrar',
					languageCode: 'Språk'
				},
				options: {
					dueDateOverdue: 'Förfallen',
					dueDateToday: 'Förfaller idag',
					priorityHigh: 'Hög prioritet',
					priorityMedium: 'Medelhög prioritet',
					priorityLow: 'Låg prioritet',
					statusDone: 'Klar',
					statusOpen: 'Öppen',
					installationTypeStandard: 'Standardinstallation',
					installationTypeDelta: 'Delta-installation',
					installationTypeUndetermined: 'Ej fastställd',
					installationTypeCancelled: 'Avbokad',
					ordersActive: 'Har öppna ordrar',
					ordersSettled: 'Har avklarade ordrar',
					onSchedule: 'Enligt schema',
					delayed: 'Fördröjd',
					cancelled: 'Avbruten',
					imported: 'Importerad',
					validationFailed: 'Ogiltig',
					done: 'Klar',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Sortera efter',
				direction: {
					ascending: 'Stigande',
					descending: 'Fallande'
				},
				options: {
					priority: 'Prioritet',
					estimatedCompletionDate: 'Beräknat datum för färdigställande',
					status: 'Status',
					installationDate: 'Planerat installationsdatum',
					orderDate: 'Orderdatum',
					estimatedDeliveryDate: 'Beräknat leveransdatum',
					shippingState: 'Fraktstatus',
					name: 'Namn',
					firstOrder: 'Första ordern',
					lastOrder: 'Senaste order',
					dueDate: 'Förfallodag'
				}
			}
		}
	}
};

export default literals;
