import { useSyncExternalStore } from 'react';

export const useNetworkAvailable = () => {
	return useSyncExternalStore(subscribe, getSnapshot);
};

const getSnapshot = (): boolean => {
	return navigator.onLine;
};

const subscribe = (callback: () => void): () => void => {
	window.addEventListener('online', callback);
	window.addEventListener('offline', callback);
	return () => {
		window.removeEventListener('online', callback);
		window.removeEventListener('offline', callback);
	};
};
