import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Si è verificato un errore',
				message: 'Si è verificato un errore che non è stato gestito correttamente',
				resolveAction: {
					label: 'Ricarica'
				}
			},
			recoverLabel: 'Riprova',
			fetchHeading: 'Recupero fallito',
			mutateHeading: 'Aggiornamento fallito',
			createHeading: 'Creazione fallita',
			deleteHeading: 'Cancellazione fallita',
			taskAssignHeading: 'Assegnazione fallita',
			taskCompleteHeading: 'Completamento fallito'
		}
	}
};

export default literals;
