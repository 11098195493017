import { ReactNode, useState } from 'react';

import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { readLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';

import { TermsAcceptance } from '../terms-acceptance/TermsAcceptance';

export type AcceptanceInterceptorProps = {
	children: ReactNode,
	localStorageKey: string,
	renderTerms: () => ReactNode
};

export function AcceptanceInterceptor(props: AcceptanceInterceptorProps) {

	const { children, localStorageKey, renderTerms } = props;

	const identity = useIdentity();
	const userId = identity.getIdentity().get()?.userId ?? undefined;
	const [accepted, setAccepted] = useState<boolean>(readLocalStorage<boolean>(localStorageKey, { namespace: userId }).getOrDefault(false));

	const accept = (): void => {
		writeLocalStorage(localStorageKey, true, { namespace: userId });
		setAccepted(true);
	};

	const renderContents = () => {
		if (accepted) {
			return (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{children}
				</>
			);
		}

		return (
			<TermsAcceptance onAccept={accept}>
				{renderTerms()}
			</TermsAcceptance>
		);
	};

	return renderContents();
}
