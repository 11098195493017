import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Basic information',
				installationPartnerArrangeAppointment: 'Arrange appointment',
				installationPartnerExecuteRemotePreCheck: 'Pre-Check',
				installationPartnerRegisterAtGridOperator: 'Registration grid operator',
				installationPartnerProvideGridOperatorDecision: 'Decision grid operator',
				installationPartnerRevokeGridOperatorRegistration: 'Revocation grid operator',
				installationPartnerProvideQuotation: 'Offer',
				installationPartnerCompleteInstallation: 'Installation completion'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Total price (net)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'On schedule',
				delayed: 'Delayed',
				cancelPending: 'Cancel pending',
				cancelled: 'Cancelled',
				done: 'Done'
			},
			installationType: {
				none: 'Undetermined',
				undetermined: 'Undetermined',
				cancelled: 'Cancelled',
				standard: 'Standard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Cancel appointments'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Attention',
				message: 'The power consumption of the wallbox has to get reduced according to the requirements of the grid operator.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'No order information available yet',
					message: 'As the order progresses, you will find all relevant information about the order here.'
				},
				caption: {
					basicAnswers: 'Basic Answers',
					gridOperatorRegistration: 'Grid operator registration',
					gridOperatorDecision: 'Grid operator decision',
					preCheck: 'PreCheck',
					offer: 'Offer',
					approval: 'Approval'
				},
				precheck: {
					heading: 'Precheck',
					effort: 'Precheck results: Installation appointments and effort',
					questionnaire: {
						heading: 'Customer answers'
					},
					installationType: {
						undetermined: 'Undetermined',
						standard: 'Standard installation',
						delta: 'Delta installation'
					},
					decision: {
						accepted: 'Information sufficient',
						more: 'More information needed',
						denied: 'Installation not posible'
					},
					notes: 'Notes',
					hours: 'hours'
				},
				decision: {
					heading: 'Grid Operator Decision',
					decision: {
						pending: 'The installation request is still pending',
						accepted: 'The grid operator agreed with the installation',
						denied: 'The grid operator declined the installation'
					}
				},
				registration: {
					heading: 'Grid Operator Registration',
					documents: 'Documents',
					gridOperator: 'Grid operator'
				},
				quotation: {
					heading: 'Quotation',
					conditions: 'Offer conditions'
				},
				complete: {
					success: {
						heading: 'Installation successful'
					},
					notes: 'Notes',
					images: 'Images related to the installation',
					documents: 'Documents related to the installation',
					signature: 'Signature client'
				}
			}
		},
		orderLineItems: {
			heading: 'Order line items',
			head: {
				title: 'Line item',
				description: 'Description',
				productCode: 'ABB Product Code'
			},
			serial: 'Wallbox serial number',
			shippingStatus: {
				label: 'Status shipment #{{shipmentNumber}}',
				pending: 'Pending',
				delivered: 'Delivered',
				expected: 'Delivery expected on '
			},
			empty: 'No order line items available'
		}
	}
};

export default literals;
