import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Etwas ist schiefgegangen',
				message: 'Ein Fehler ist aufgetreten, der nicht behandelt werden konnte.',
				resolveAction: {
					label: 'Neu laden'
				}
			},
			recoverLabel: 'Erneut versuchen',
			fetchHeading: 'Laden fehlgeschlagen',
			mutateHeading: 'Änderung fehlgeschlagen',
			createHeading: 'Anlage fehlgeschlagen',
			deleteHeading: 'Löschen fehlgeschlagen',
			taskAssignHeading: 'Zuweisen fehlgeschlagen',
			taskCompleteHeading: 'Erledigung fehlgeschlagen'
		}
	}
};

export default literals;
