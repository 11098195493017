import React, { ReactNode } from 'react';

import './AppointmentCollectionSubsection.scss';

export type AppointmentCollectionSubsectionProps = {
	children: ReactNode,
	month?: string
};

export function AppointmentCollectionSubsection(props: AppointmentCollectionSubsectionProps): React.JSX.Element {

	const { children, month } = props;

	const renderSubheader = (): ReactNode => {
		if (month) {
			return (
				<h2 className="appointment-collection-subsection__header">{month}</h2>
			);
		}

		return null;
	};

	return (
		<section className="appointment-collection-subsection">
			{renderSubheader()}
			{children}
		</section>
	);
}
