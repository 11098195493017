import React, { ReactNode, useMemo } from 'react';

import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	AnyQuestion,
	buildAnswerKey,
	isSegmentInEffect,
	PersistedAnswers,
	QuestionnaireEnvironmentModel,
	QuestionnaireSection,
	QuestionnaireSegment, AnyQuestionnaireModel, isQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';
import { AnswerSection } from '@abb-emobility/shared/ui-primitive';

import { AnswerFactory } from './answer-factory/AnswerFactory';

import './QuestionnaireAnswers.scss';

export type QuestionnaireAnswersProps = {
	questionnaire: AnyQuestionnaireModel,
	answers: PersistedAnswers,
	environment: QuestionnaireEnvironmentModel
};

export function QuestionnaireAnswers(props: QuestionnaireAnswersProps) {
	const { questionnaire, answers, environment } = props;

	const l10n = useL10n();

	useMemo(() => {
		if (isQuestionnaireModel(questionnaire)) {
			const applicationLocales = l10n.getLocales();
			const questionnaireLocales = questionnaire.locales;
			for (const [applicationLanguage, applicationLocale] of applicationLocales) {
				if (applicationLanguage in questionnaireLocales) {
					applicationLocale.addLiterals(questionnaireLocales[applicationLanguage], 'questionnaire');
					continue;
				}
				if (!(questionnaire.defaultLanguage in questionnaireLocales)) {
					throw new AppError(`Invalid default language ${questionnaire.defaultLanguage} in questionnaire.`);
				}
				applicationLocale.addLiterals(questionnaireLocales[questionnaire.defaultLanguage], 'questionnaire');
			}
		}
	}, []);

	const renderSegments = (): ReactNode => {
		return questionnaire.segments.map((segment): ReactNode => {
			if (!isSegmentInEffect(segment, answers, environment)) {
				return null;
			}
			return renderSegment(segment);
		});
	};

	const renderSegment = (segment: QuestionnaireSegment): ReactNode => {
		return segment.sections.map((section): ReactNode => {
			const localizedHeading = l10n.translate('questionnaire.' + section.heading, undefined, section.heading);
			return (
				<AnswerSection heading={localizedHeading} key={section.key}>
					{renderQuestions(segment, section)}
				</AnswerSection>
			);
		});
	};

	const renderQuestions = (
		segment: QuestionnaireSegment,
		section: QuestionnaireSection
	): ReactNode => {
		return section.questions.map((question) => {
			return renderQuestion(segment, section, question);
		});
	};

	const renderQuestion = (
		segment: QuestionnaireSegment,
		section: QuestionnaireSection,
		question: AnyQuestion
	): ReactNode => {
		const key = buildAnswerKey(segment, section, question);
		return (
			<AnswerFactory
				keyPrefix={segment.key + '.' + section.key}
				question={question}
				answers={answers}
				key={key}
			/>
		);
	};

	return (
		<article className="questionnaire-answers">
			{renderSegments()}
		</article>
	);
}
