import React from 'react';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';
import { IconIdentifier, InformationIconValue } from '@abb-emobility/shared/ui-primitive';
import { buildCssClassStringFromClassMap, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './InstallationDetailAppointmentCollectionItem.scss';

export type InstallationDetailCollectionItemProps = {
	appointment: InstallerAppointmentModel
};

export function InstallationDetailAppointmentCollectionItem(props: InstallationDetailCollectionItemProps) {

	const { appointment } = props;

	const l10n = useL10n();
	const identity = useIdentity();

	const start = appointment.period.start;
	const end = appointment.period.end;

	const isHighlighted = (): boolean => {
		return appointment.installer.userName === identity.getIdentity().get()?.userName ?? null;
	};

	const appointmentCollectionItemClassMap = {
		'installation-detail-appointment-collection-item': true,
		'installation-detail-appointment-collection-item--isHighlighted': isHighlighted()
	};

	const renderInstallationPeriod = (): string => {
		const period = l10n.translate(l10nLiteralFromEnumValue(
			appointment.installationPeriod,
			'omsInstallationPartnerServiceApp.installationDetail.appointmentCollection.item.installationPeriod'
		));
		return '(' + period + ')';
	};

	return (
		<article className={buildCssClassStringFromClassMap(appointmentCollectionItemClassMap)}>
			<article className="installation-detail-appointment-collection-item__installer">
				<InformationIconValue
					icon={IconIdentifier.USER}
					value={appointment.installer.name ?? '—'}
				/>
			</article>
			<article className="installation-detail-appointment-collection-item__date">
				<InformationIconValue
					icon={IconIdentifier.CALENDAR_BLANK}
					value={l10n.formatTimestampDate(start)}
					weekday={l10n.formatTimestampDayname(start, '', DaynameFormat.SHORT)}
				/>
				<InformationIconValue
					icon={IconIdentifier.CLOCK}
					value={l10n.formatTimestampTime(start) + ' - ' + l10n.formatTimestampTime(end)}
				/>
			</article>
			<article className="installation-detail-appointment-collection-item__type-effort">
				<div>{`${appointment.trade.name} ${renderInstallationPeriod()}`}</div>
			</article>
		</article>
	);

}
