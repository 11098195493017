import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Por la mañana',
				noon: 'Mediodía',
				afternoon: 'Por la tarde',
				evening: 'Por la noche'
			}
		},
		gridOperatorInformation: {
			heading: 'Operador de red'
		},
		informationHead: {
			orderDate: 'Fecha del pedido',
			orderNumber: 'Nº de pedido'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Error al resolver la URL de la imagen'
		},
		task: {
			status: {
				started: 'Próximamente',
				overdue: 'Atrasado',
				escalated: 'Escalado',
				done: 'Hecho',
				doneOverdue: 'Hecho',
				doneEscalated: 'Hecho'
			}
		},
		upload: {
			empty: {
				message: 'No hay archivos seleccionados'
			},
			dropMessage: 'Arrastrar y soltar los archivos aquí',
			selectButtonLabel: 'Elegir archivo',
			hint: 'Archivos compatibles: JPG, PNG o PDF más pequeños que {{maxFilesize}} en total.',
			fileSizeWarning: 'Los archivos seleccionados superan el tamaño máximo total de archivo permitido. Algunos de los archivos seleccionados no se enviarán.'
		},
		choose: {
			label: 'Elegir'
		},
		search: {
			foundIn: 'Encontrado en',
			noResults: 'No se han encontrado resultados'
		},
		termsAccept: {
			buttonLabel: 'Aceptar'
		},
		collection: {
			filter: {
				filter: 'Filtro',
				none: 'ninguna',
				active: '{{cuenta}} activa',
				resetFilter: 'Restablecer filtro',
				group: {
					dueDate: 'Fecha límite',
					priority: 'Prioridad',
					estimatedCompletionDate: 'Fecha de instalación prevista',
					state: 'Estado',
					installationType: 'Instalación',
					runningOrderCount: 'Pedidos en ejecución',
					languageCode: 'Idioma'
				},
				options: {
					dueDateOverdue: 'Atrasado',
					dueDateToday: 'Vence hoy',
					priorityHigh: 'Prioridad alta',
					priorityMedium: 'Prioridad media',
					priorityLow: 'Prioridad baja',
					statusDone: 'Hecho',
					statusOpen: 'Pendiente',
					installationTypeStandard: 'Instalación estándar',
					installationTypeDelta: 'Instalación Delta',
					installationTypeUndetermined: 'Indeterminado',
					installationTypeCancelled: 'Cancelado',
					ordersActive: 'Tiene pedidos pendientes',
					ordersSettled: 'Tiene pedidos liquidados',
					onSchedule: 'Según lo previsto',
					delayed: 'Retrasado',
					cancelled: 'Cancelado',
					imported: 'Importado',
					validationFailed: 'No válido',
					done: 'Hecho',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Ordenar por',
				direction: {
					ascending: 'Ascendente',
					descending: 'Descendente'
				},
				options: {
					priority: 'Prioridad',
					estimatedCompletionDate: 'Fecha estimada de finalización',
					status: 'Estado',
					installationDate: 'Fecha de instalación prevista',
					orderDate: 'Fecha del pedido',
					estimatedDeliveryDate: 'Fecha estimada de entrega',
					shippingState: 'Estado del envío',
					name: 'Nombre',
					firstOrder: 'Primer pedido',
					lastOrder: 'Último pedido',
					dueDate: 'Fecha límite'
				}
			}
		}
	}
};

export default literals;
