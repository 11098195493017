import React, { ReactNode } from 'react';

import './FileListEmpty.scss';

export type DownloadEmptyProps = {
	title?: string,
	message?: string
};

export const FileListEmpty = (props: DownloadEmptyProps) => {

	const { title, message } = props;

	if (title === undefined && message === undefined) {
		return null;
	}

	const renderTitle = (): ReactNode => {
		if (title === undefined) {
			return null;
		}
		return (
			<div className="download-empty__title">{title}</div>
		);
	};

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return null;
		}
		return (
			<div className="download-empty__paragraph">{message}</div>
		);
	};

	return (
		<article className="download-empty">
			{renderTitle()}
			{renderMessage()}
		</article>
	);
};
