import React, { Fragment, ReactNode } from 'react';

import { InstallationPartnerOrderModel, InstallationPartnerOrderShipmentModel } from '@abb-emobility/oms/domain-model';
import { ShippingState } from '@abb-emobility/shared/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	CardHeader,
	Cardlayout,
	CardSection,
	CardSectionTableItem,
	Direction,
	Hint,
	HintLevel,
	IconIdentifier,
	Separator
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import './OrderLineItems.scss';

type OrderLineItemsProps = {
	order: InstallationPartnerOrderModel
	showProductCode?: boolean
};

export function OrderLineItems(props: OrderLineItemsProps) {
	const { order, showProductCode = false } = props;

	const l10n = useL10n();

	const wallboxSerialNumber = order.wallboxSerialNumber ?? null;
	const productCodeVisible = showProductCode && (order.orderLineItems.length > 0);
	const renderLineItems = () => {
		return (
			order.orderLineItems.map((item, index) => {
				const productCode = item.productCode ?? '';
				return (
					<Fragment key={index}>
						<Separator />
						<CardSectionTableItem
							label={`#${String(index + 1).padStart(2, '0')}`}
							value={item.productName ?? ''}
							productCode={showProductCode ? productCode : undefined}
						/>
					</Fragment>
				);
			}));
	};

	const renderSerialNumber = (): ReactNode => {
		if (wallboxSerialNumber === null) {
			return null;
		}
		return (
			<>
				<Separator />
				<CardSectionTableItem
					label={l10n.translate('omsUiDomain.orderLineItems.serial')}
					value={wallboxSerialNumber}
				/>
			</>
		);
	};

	const getTrackingUrlValue = (shipment: InstallationPartnerOrderShipmentModel): Nullable<string> => {
		const trackingUrl = shipment.shippingTrackingUrl ?? null;
		if ((trackingUrl === null) || !trackingUrl.accessible) {
			return null;
		}
		return trackingUrl.value;
	};

	// There are three branches: estimatedDeliveryDate unknown and pending, estimatedDeliveryDate known and pending, estimatedDeliveryDate known and delivered
	const renderShipmentInfo = (shipment: InstallationPartnerOrderShipmentModel, index: number): ReactNode => {
		const estimatedDelivery = shipment.estimatedDeliveryDate ?? null;

		if (estimatedDelivery === null) {
			return (
				<CardSectionTableItem
					label={l10n.translate('omsUiDomain.orderLineItems.shippingStatus.label', new Map([['shipmentNumber', String(index + 1)]]))}
					value={l10n.translate(l10nLiteralFromEnumValue(shipment.shippingState, 'omsUiDomain.orderLineItems.shippingStatus'))}
					key={shipment.id}
					trackingUrl={getTrackingUrlValue(shipment)}
				/>
			);
		} else if (shipment.shippingState === ShippingState.DELIVERED) {
			return (
				<CardSectionTableItem
					label={l10n.translate('omsUiDomain.orderLineItems.shippingStatus.label', new Map([['shipmentNumber', String(index + 1)]]))}
					value={
						l10n.translate('omsUiDomain.orderLineItems.shippingStatus.expected') +
						l10n.formatDate(createDateFromTimestamp(estimatedDelivery)) +
						` (${l10n.translate(l10nLiteralFromEnumValue(shipment.shippingState, 'omsUiDomain.orderLineItems.shippingStatus'))})`
					}
					trackingUrl={getTrackingUrlValue(shipment)}
					key={shipment.id}
				/>
			);
		}

		return (
			<CardSectionTableItem
				label={l10n.translate('omsUiDomain.orderLineItems.shippingStatus.label', new Map([['shipmentNumber', String(index + 1)]]))}
				value={
					l10n.translate('omsUiDomain.orderLineItems.shippingStatus.expected') +
					l10n.formatDate(createDateFromTimestamp(estimatedDelivery))
				}
				trackingUrl={getTrackingUrlValue(shipment)}
				key={shipment.id}
			/>
		);
	};

	const renderShipmentInfos = (): ReactNode => {
		return (order.shipments ?? []).map((shipment, index) => {
			return (
				<Fragment key={index}>
					<Separator />
					{renderShipmentInfo(shipment, index)}
				</Fragment>
			);
		});
	};

	const renderThrottleHint = (): ReactNode => {
		if (!order.throttleWallboxPowerConsumption) {
			return null;
		}
		return (
			<section className="order-line-items__hint-section">
				<Hint
					level={HintLevel.WARNING}
					heading={l10n.translate('omsUiDomain.orderDetail.throttleWallboxHint.caption')}
					message={l10n.translate('omsUiDomain.orderDetail.throttleWallboxHint.message')}
					icon={IconIdentifier.WARNING}
				/>
			</section>
		);
	};

	const renderContent = () => {
		if (order.orderLineItems.length === 0 && (order.shipments === null || order.shipments?.length === 0) && wallboxSerialNumber === null) {
			return (
				<>
					<Separator />
					<span className="order-line-items__empty"> {l10n.translate('omsUiDomain.orderLineItems.empty')}</span>
				</>
			);
		}
		return (
			<>
				{renderLineItems()}
				{renderSerialNumber()}
				{renderShipmentInfos()}
			</>
		);
	};

	return (
		<Card>
			<Cardlayout wide={true}>
				<CardHeader wide={true} heading={l10n.translate('omsUiDomain.orderLineItems.heading')} />
				<CardSection direction={Direction.COLUMN}>
					{renderThrottleHint()}
					<CardSectionTableItem
						head={true}
						label={l10n.translate('omsUiDomain.orderLineItems.head.title')}
						value={l10n.translate('omsUiDomain.orderLineItems.head.description')}
						productCode={productCodeVisible ? l10n.translate('omsUiDomain.orderLineItems.head.productCode') : undefined}
					/>
					{renderContent()}
				</CardSection>
			</Cardlayout>
		</Card>
	);
}
