import React, { ReactNode, useEffect } from 'react';

import {
	InstallationPartnerOrderEntityDataProvider,
	InstallationPartnerOrderInformationCollectionDataProvider,
	useInstallerAppointmentCollectionData
} from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { OrderInformation } from '@abb-emobility/oms/ui-domain';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { ButtonPrimary, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import {
	InstallationDetailAppointmentCollection
} from './installation-detail-appointment-collection/InstallationDetailAppointmentCollection';
import { InstallationDetailHeader } from './installation-detail-header/InstallationDetailHeader';
import { OrderLineItems } from '../order-line-items/OrderLineItems';

import './InstallationDetail.scss';

type InstallationDetailProps = {
	onAction: () => void,
	onQuery: (installerAppointment: InstallerAppointmentModel) => void,
	installerAppointmentId: ModelPrimaryKey
};

export function InstallationDetail(props: InstallationDetailProps) {

	const { onAction, onQuery, installerAppointmentId } = props;

	const l10n = useL10n();

	const installerAppointmentData = useInstallerAppointmentCollectionData();
	const installerAppointment = installerAppointmentData
		.queryEntity(installerAppointmentId)
		.getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.installerAppointmentNotFound')));

	useEffect(() => {
		onQuery(installerAppointment);
	}, []);

	const renderCompletionFooter = (): ReactNode => {
		const completionTaskId = installerAppointment.completionTaskId ?? null;
		if (completionTaskId === null) {
			return null;
		}
		return (
			<div className="installation-detail__footer">
				<ButtonPrimary onClick={onAction} label={l10n.translate('omsInstallationPartnerServiceApp.installationDetail.action')} />
			</div>
		);
	};

	return (
		<UiErrorHandler>
			<InstallationPartnerOrderEntityDataProvider
				pendingComponent={SpinnerCircle}
				primaryKey={installerAppointment.orderId}>

				<InstallationDetailHeader installerAppointment={installerAppointment} />

				<OrderLineItems />

				<UiErrorHandler>
					<InstallationPartnerOrderInformationCollectionDataProvider
						orderId={installerAppointment.orderId}
						pendingComponent={SpinnerCircle}
					>
						<OrderInformation orderId={installerAppointment.orderId} includePricing={false} />
					</InstallationPartnerOrderInformationCollectionDataProvider>
				</UiErrorHandler>

				<InstallationDetailAppointmentCollection orderId={installerAppointment.orderId} />

				{renderCompletionFooter()}

			</InstallationPartnerOrderEntityDataProvider>
		</UiErrorHandler>
	);
}
