import React from 'react';

import { useIdentity } from '@abb-emobility/shared/identity-provider';
import {
	Card,
	Cardlayout,
	CardSection,
	Userbar,
	UserbarSection,
	UserbarSignout,
	UserbarUser
} from '@abb-emobility/shared/ui-primitive';

import './MobileTopbarUser.scss';

type MobileTopbarUserProps = {
	onLogout?: () => void
};

export function MobileTopbarUser(props: MobileTopbarUserProps) {

	const { onLogout } = props;

	const identity = useIdentity();

	return (
		<div className="mobile-topbar-user">
			<Card>
				<Cardlayout>
					<CardSection>
						<Userbar>
							<UserbarSection>
								<UserbarUser
									name={identity.getIdentity().get()?.fullname ?? undefined}
									mailAddress={identity.getIdentity().get()?.mailAddress}
									message="Welcome"
								/>
							</UserbarSection>
							<UserbarSection>
								<UserbarSignout onClick={onLogout} />
							</UserbarSection>
						</Userbar>
					</CardSection>
				</Cardlayout>
			</Card>
		</div>
	);
}
