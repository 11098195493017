import './SpecificationItem.scss';

export type SpecificationItemProps = {
	attribute: string
};

export function SpecificationItem(props: SpecificationItemProps) {
	const { attribute } = props;

	return (
		<li className="specification-item">
			<span className="specification-item__attribute">{attribute}</span>
		</li>
	);
}
