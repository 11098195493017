export function TermsFr() {

	return (
		<>
			<h1>Déclaration</h1>
			<p>Je confirme par la présente que je suis pleinement compétent et qualifié, conformément aux lois, réglementations, législations locales et normes applicables, pour effectuer les travaux d'installation et de configuration électriques (y compris les diplômes, certificats, licences ou enregistrements nécessaires) et que j'ai l'autorité nécessaire pour agir au nom et pour le compte de l'entreprise d'installation désignée par ABB E-mobility B. V. Je connais et comprends parfaitement les exigences relatives à la sécurité de l'installation et de la configuration des ABB E-Mobility Wallboxes et je me conformerai à toutes les lois et réglementations (locales) applicables, à l'état de la technique, ainsi qu'à toutes les instructions fournies par ABB E-Mobility B.V., en particulier conformément au <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">manuel d'utilisation et d'installation].</a></p>
			<p>Les <a href="https://abb.com" target="_blank" rel="noopener noreferrer">conditions d'utilisation</a> relatives à l'accès et à l'utilisation de cette application s'appliquent ici.</p>
		</>
	);
}
