import './BasicInformation.scss';

type BasicInformationProps = {
	label: string,
	description?: string
};

export function BasicInformation(props: BasicInformationProps) {
	const { label, description } = props;

	const renderDescription = () => {
		if (label) {
			return (
				<div className="basic-information__description">{description}</div>
			);
		}
		return;
	};

	return (
		<div className="basic-information">
			<div className="basic-information__label">{label}</div>
			{renderDescription()}
		</div>
	);
}
