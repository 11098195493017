import React from 'react';

import './AppLayoutMobileMain.scss';

import { AppLayoutMainProps } from '../../app-layout/app-layout-main/AppLayoutMain';

export function AppLayoutMobileMain(props: AppLayoutMainProps) {
	const { children } = props;

	return (
		<main className="app-layout-mobile-main">
			{children}
		</main>
	);
}
