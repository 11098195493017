import { ReactNode } from 'react';

import './Card.scss';

export type CardProps = {
	children: ReactNode
};

export function Card(props: CardProps) {
	const { children } = props;

	return (
		<section className="card">
			{children}
		</section>
	);
}
