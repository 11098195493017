import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Matin',
				noon: 'Midi',
				afternoon: 'Après-midi',
				evening: 'Soir'
			}
		},
		gridOperatorInformation: {
			heading: 'Opérateur réseau'
		},
		informationHead: {
			orderDate: 'Date de la commande',
			orderNumber: 'N° de la commande'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Échec de résolution de l’URL de l’image'
		},
		task: {
			status: {
				started: 'À venir',
				overdue: 'En retard',
				escalated: 'Remonté',
				done: 'Terminé',
				doneOverdue: 'Terminé',
				doneEscalated: 'Terminé'
			}
		},
		upload: {
			empty: {
				message: 'Aucun fichier sélectionné'
			},
			dropMessage: 'Glisser-déposer des fichiers ici',
			selectButtonLabel: 'Choisir le fichier',
			hint: 'Fichiers pris en charge : JPG, PNG ou PDF dont la taille totale est inférieure à {{maxFilesize}}.',
			fileSizeWarning: 'Les fichiers que vous avez sélectionnés dépassent la taille totale maximale autorisée. Certains des dossiers sélectionnés ne seront pas soumis.'
		},
		choose: {
			label: 'Choisir'
		},
		search: {
			foundIn: 'Trouvé dans',
			noResults: 'Aucun résultat trouvé'
		},
		termsAccept: {
			buttonLabel: 'Accepter'
		},
		collection: {
			filter: {
				filter: 'Filtrer',
				none: 'aucun(e)',
				active: '{{count}} actif',
				resetFilter: 'Réinitialiser le filtre',
				group: {
					dueDate: 'Date d’échéance',
					priority: 'Priorité',
					estimatedCompletionDate: 'Date d’installation prévue',
					state: 'État',
					installationType: 'Installation',
					runningOrderCount: 'Commandes en cours d’exécution',
					languageCode: 'Langue'
				},
				options: {
					dueDateOverdue: 'En retard',
					dueDateToday: 'À remettre aujourd’hui',
					priorityHigh: 'Priorité élevée',
					priorityMedium: 'Priorité moyenne',
					priorityLow: 'Priorité faible',
					statusDone: 'Terminé',
					statusOpen: 'Ouvert',
					installationTypeStandard: 'Installation standard',
					installationTypeDelta: 'Installation Delta',
					installationTypeUndetermined: 'Indéterminé',
					installationTypeCancelled: 'Annulé',
					ordersActive: 'Comporte des commandes en cours',
					ordersSettled: 'A réglé des commandes',
					onSchedule: 'Dans les délais',
					delayed: 'Retardé',
					cancelled: 'Annulé',
					imported: 'Importe',
					validationFailed: 'Non valide',
					done: 'Terminé',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Trier par',
				direction: {
					ascending: 'Ascendant',
					descending: 'Descendant'
				},
				options: {
					priority: 'Priorité',
					estimatedCompletionDate: 'Date d’achèvement estimée',
					status: 'État',
					installationDate: 'Date d’installation prévue',
					orderDate: 'Date de la commande',
					estimatedDeliveryDate: 'Date de livraison est.',
					shippingState: 'État de l’expédition',
					name: 'Nom',
					firstOrder: 'Première commande',
					lastOrder: 'Dernière commande',
					dueDate: 'Date d’échéance'
				}
			}
		}
	}
};

export default literals;
