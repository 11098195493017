import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Información básica',
				installationPartnerArrangeAppointment: 'Concertar cita',
				installationPartnerExecuteRemotePreCheck: 'Comprobación previa',
				installationPartnerRegisterAtGridOperator: 'Operador de red de registro',
				installationPartnerProvideGridOperatorDecision: 'Decisión de operador de red',
				installationPartnerRevokeGridOperatorRegistration: 'Revocación de operador de red',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Finalización de la instalación'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Precio total (neto)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Según lo previsto',
				delayed: 'Retrasado',
				cancelPending: 'Cancelar pendiente',
				cancelled: 'Cancelado',
				done: 'Hecho'
			},
			installationType: {
				none: 'Indeterminado',
				undetermined: 'Indeterminado',
				cancelled: 'Cancelado',
				standard: 'Norma',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Cancelar citas'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Atención',
				message: 'El consumo de energía del wallbox debe reducirse en función de los requisitos del operador de red.'
			},
			informationList: {
				heading: 'Información',
				emptyState: {
					heading: 'Aún no hay información disponible sobre el pedido',
					message: 'A medida que avance el pedido, encontrará aquí toda la información pertinente sobre el mismo.'
				},
				caption: {
					basicAnswers: 'Respuestas básicas',
					gridOperatorRegistration: 'Registro del operador de red',
					gridOperatorDecision: 'Decisión del operador de red',
					preCheck: 'Comprobación previa',
					offer: 'Oferta',
					approval: 'Aprobación'
				},
				precheck: {
					heading: 'Comprobación previa',
					effort: 'Resultados de la comprobación previa: Citas y esfuerzo de instalación',
					questionnaire: {
						heading: 'Respuestas del cliente'
					},
					installationType: {
						undetermined: 'Indeterminado',
						standard: 'Instalación estándar',
						delta: 'Instalación Delta'
					},
					decision: {
						accepted: 'Información suficiente',
						more: 'Se necesita más información',
						denied: 'Instalación no es posible'
					},
					notes: 'Notas',
					hours: 'horas'
				},
				decision: {
					heading: 'Decisión del operador de red',
					decision: {
						pending: 'La solicitud de instalación sigue pendiente',
						accepted: 'El operador de red está de acuerdo con la instalación',
						denied: 'El operador de red rechazó la instalación'
					}
				},
				registration: {
					heading: 'Registro del operador de red',
					documents: 'Documentos',
					gridOperator: 'Operador de red'
				},
				quotation: {
					heading: 'Presupuesto',
					conditions: 'Condiciones de la oferta'
				},
				complete: {
					success: {
						heading: 'Instalación correcta'
					},
					notes: 'Notas',
					images: 'Imágenes relacionadas con la instalación',
					documents: 'Documentos relacionados con la instalación',
					signature: 'Firma del cliente'
				}
			}
		},
		orderLineItems: {
			heading: 'Partidas del pedido',
			head: {
				title: 'Partida',
				description: 'Descripción',
				productCode: 'Código de producto ABB'
			},
			serial: 'Número de serie de wallbox',
			shippingStatus: {
				label: 'Estado del envío #{{NúmeroDeEnvío}}',
				pending: 'Pendiente',
				delivered: 'Entregado',
				expected: 'Entrega prevista el '
			},
			empty: 'No hay partidas del pedido disponibles'
		}
	}
};

export default literals;
