export function TermsEs() {

	return (
		<>
			<h1>Declaraci&oacute;n</h1>
			<p>Por la presente, confirmo que soy plenamente competente y estoy cualificado de acuerdo con las leyes, reglamentos, legislaci&oacute;n local y normas aplicables para realizar los trabajos de instalaci&oacute;n y configuraci&oacute;n el&eacute;ctrica (incluidos los diplomas, certificados, licencias o registros necesarios) y que tengo autoridad para actuar en nombre y representaci&oacute;n de la empresa instaladora designada por ABB E-mobility B.V. Conozco y comprendo perfectamente esto, as&iacute; como los requisitos para la segura instalaci&oacute;n y configuraci&oacute;n de los Wallboxes de ABB E-Mobility y cumplir&eacute; todas las leyes y normativas (locales) aplicables, aplicar&eacute; los &uacute;ltimos avances t&eacute;cnicos, as&iacute; como todas las instrucciones facilitadas por ABB E-Mobility B.V., en particular de acuerdo con el <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">manual de funcionamiento e instalaci&oacute;n.</a></p>
			<p>Se aplican las <a href="https://abb.com" target="_blank" rel="noopener noreferrer">condiciones de uso</a> en relaci&oacute;n con el acceso y uso de esta aplicaci&oacute;n.</p>
		</>
	);
}
