import React, { ReactNode } from 'react';

import './ImageThumbnailCollection.scss';

export type ImageThumbnailCollectionProps = {
	children: ReactNode
};

export function ImageThumbnailCollection(props: ImageThumbnailCollectionProps) {

	const { children } = props;

	return (
		<div className="image-thumbnail-collection">
			{children}
		</div>
	);
}
