import { createContext } from 'react';

import { AppError } from '@abb-emobility/shared/error';
import { Optional } from '@abb-emobility/shared/util';

import { Identity } from '../foundation/Identity';

export type IdentityContextValue = {
	setIdentity(identity: Identity): void,
	unsetIdentity(): void,
	getIdentity(): Optional<Identity>
};

export const identityContext = createContext<IdentityContextValue>({
	setIdentity: (_identity: Identity): void => {
		throw new AppError('No identity context provided');
	},
	unsetIdentity: (): void => {
		throw new AppError('No identity context provided');
	},
	getIdentity: (): Optional<Identity> => {
		throw new AppError('No identity context provided');
	}
});
