import React, { ReactNode } from 'react';

import './AnswerText.scss';

export type AnswerTextProps = {
	question: string,
	hint?: ReactNode,
	answer: string
};

export function AnswerText(props: AnswerTextProps) {
	const { question, hint = null, answer } = props;

	const renderHint = (): ReactNode => {
		if (hint === null) {
			return null;
		}
		return (
			<div className="answer-text__items__item">
				<span className="answer-text__items__item__hint">{hint}</span>
			</div>
		);
	};

	return (
		<div className="answer-text">
			<div className="answer-text__items">
				<div className="answer-text__items__item">
					<span className="answer-text__items__item__question">{question}:</span>
					<span className="answer-text__items__item__answer">{answer}</span>
				</div>
				{renderHint()}
			</div>
		</div>
	);
}
