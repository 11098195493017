import React, { ReactNode } from 'react';

import { buildCssClassStringFromClassMap, Nullable } from '@abb-emobility/shared/util';

import { Icon } from '../../../icon/Icon';
import { IconIdentifier } from '../../../icon/Icon.enums';
import { LinkIcon } from '../../../link-icon/LinkIcon';

import './CardSectionTableItem.scss';

export type CardSectionTableItemProps = {
	label: string,
	value: string,
	productCode?: string;
	trackingUrl?: Nullable<string>,
	head?: boolean
};

export function CardSectionTableItem(props: CardSectionTableItemProps) {
	const { label, value, productCode = null, head, trackingUrl } = props;

	const itemLabelClassMap = {
		'card-section-table-item__label': true,
		'card-section-table-item__label--head': head
	};

	const itemValueClassMap = {
		'card-section-table-item__value': true,
		'card-section-table-item__value--head': head
	};

	const productCodeClassMap = {
		'card-section-table-item__product-code': true,
		'card-section-table-item__product-code--head': head
	};

	const renderTrackingLink = (): ReactNode => {
		const url = trackingUrl ?? null;
		if (url !== null) {
			return (
				<span className={'card-section-table-item__value__icon'}>
					<LinkIcon linkUrl={url}>
						<Icon name={IconIdentifier.TRUCK} />
					</LinkIcon>
				</span>
			);
		}
		return null;
	};

	const renderAdditionalValue = () => {
		if (productCode === null) {
			return null;
		}
		return (
			<div className={buildCssClassStringFromClassMap(productCodeClassMap)}>
				{productCode}
			</div>
		);
	};

	return (
		<div className="card-section-table-item">
			<div className={buildCssClassStringFromClassMap(itemLabelClassMap)}>
				{label}
			</div>
			{renderAdditionalValue()}
			<div className={buildCssClassStringFromClassMap(itemValueClassMap)}>
				{value}
				{renderTrackingLink()}
			</div>
		</div>
	);
}
