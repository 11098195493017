export function TermsSv() {

	return (
		<>
			<h1>Deklaration</h1>
			<p>Jag bekr&auml;ftar h&auml;rmed att jag &auml;r fullt kompetent och kvalificerad i enlighet med g&auml;llande lagar, f&ouml;rordningar, lokal lagstiftning och standarder f&ouml;r att utf&ouml;ra det elektriska installations- och konfigurationsarbetet (inklusive alla n&ouml;dv&auml;ndiga examensbevis, certifikat, licenser eller registreringar) och har befogenhet att agera f&ouml;r och p&aring; uppdrag av det installationsf&ouml;retag som utsetts av ABB E-mobility B.V. Jag k&auml;nner till och f&ouml;rst&aring;r detta och kraven f&ouml;r s&auml;ker installation och konfiguration av ABB E-Mobility-laddboxar och kommer att f&ouml;lja alla till&auml;mpliga (lokala) lagar och f&ouml;rordningar, den senaste tekniken samt alla instruktioner fr&aring;n ABB E-Mobility B.V. i synnerhet i enlighet med <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">drifts- och installationshandboken</a>.</p>
			<p> <a href="https://abb.com" target="_blank" rel="noopener noreferrer">Anv&auml;ndarvillkoren</a> f&ouml;r &aring;tkomst till och anv&auml;ndning av denna applikation g&auml;ller h&auml;r.</p>
		</>
	);
}
