import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Antwoordtype {{type}} niet gevonden.',
			textValueNotSet: 'niet ingesteld',
			numericValueNotSet: 'niet ingesteld',
			pictureValueNotSet: 'Geen foto meegeleverd',
			documentValueNotSet: 'Geen document verstrekt',
			singleSelectValueNotSet: 'Geen selectie',
			multiSelectValueNotSet: 'Geen selectie',
			consentValueChecked: 'ja',
			consentValueUnchecked: 'nee'
		}
	}
};

export default literals;
