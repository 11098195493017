import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'Servicio de instalación del wallbox de ABB',
		appointmentCollection: {
			heading: 'Terminar',
			header: {
				today: 'Hoy',
				upcoming: 'Próximamente'
			},
			emptyState: {
				heading: 'Sin citas',
				message: 'No se ha podido encontrar ninguna cita.'
			}
		},
		installationCompletion: {
			done: 'Instalación finalizada.',
			upload: {
				heading: 'Proporcionar documentos'
			},
			confirmation: {
				heading: 'Wallbox instalado',
				text: 'Por la presente, confirmo la instalación y puesta en servicio correctas y profesionales del wallbox suministrado con el siguiente número de serie {{serialNumber}}.',
				throttle: 'Por la presente, confirmo que el consumo de potencia del wallbox se redujo de acuerdo con los requisitos del operador de red.'
			},
			inspection: {
				heading: 'Inspección',
				customerPresent: 'Inspección con el cliente',
				customerNotPresent: 'El cliente no está presente'
			},
			acceptance: {
				heading: 'Aceptación'
			},
			signature: {
				clear: 'Borrar',
				close: 'Cerrar',
				done: 'Hecho'
			},
			action: {
				signature: 'Firma del cliente',
				complete: 'Instalación completa'
			}
		},
		installationDetail: {
			action: 'Instalación completa',
			heading: 'Instalación',
			appointmentCollection: {
				heading: 'Operaciones y citas',
				item: {
					installationPeriod: {
						preparation: 'Preparación de la instalación',
						beginning: 'Inicio de la instalación',
						permanent: 'Permanente',
						end: 'Fin de la instalación',
						followUp: 'Seguimiento de la instalación'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Indeterminado',
				standard: 'Norma',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Citas',
			detail: 'Detalles',
			installationCompletion: 'Finalización de la instalación',
			error: 'Error',
			notFound: 'No se ha encontrado'
		},
		formatter: {
			dayNames: [
				'Domingo',
				'Lunes',
				'Martes',
				'Miércoles',
				'Jueves',
				'Viernes',
				'Sábado'
			],
			fuzzyDaynames: {
				today: 'hoy',
				tomorrow: 'mañana',
				yesterday: 'ayer'
			}
		},
		navigation: {
			tasks: 'Tareas',
			logout: 'Cerrar sesión'
		},
		breadcrumbs: {
			orders: 'Información general del pedido'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'volver',
				captionPrefix: ' en '
			},
			installationCompletion: {
				backLinkLabel: 'volver',
				captionPrefix: ' en '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Error en la búsqueda de citas',
				message: 'Se ha producido un error al cargar la información solicitada.'
			},
			installationCompletion: {
				heading: 'Error al completar la instalación',
				message: 'Se produjo un error al completar la tarea.',
				resolveAction: {
					label: 'Volver a la página de detalles'
				},
				notAllowed: 'No se permite completar la instalación.'
			},
			installerAppointmentNotFound: 'No se ha encontrado la cita del instalador',
			orderNotFound: 'Pedido no encontrado',
			installationDetail: {
				heading: 'Error en la obtención de los datos de instalación',
				message: 'Se ha producido un error al cargar la información solicitada.'
			},
			validation: {
				heading: 'Datos no válidos',
				message: 'Los datos enviados no son válidos.'
			},
			generic: {
				heading: 'Algo salió mal',
				message: 'Se ha producido un error que no se ha gestionado durante el renderizado',
				resolveAction: {
					retry: {
						label: 'Volver a cargar'
					}
				}
			},
			offline: {
				heading: 'Conexión de red no disponible',
				message: 'Conéctese a una red para utilizar la aplicación.'
			},
			notFound: {
				heading: 'No encontrado',
				message: 'No se ha encontrado el recurso solicitado',
				resolveAction: {
					label: 'Panel de control'
				}
			},
			task: {
				notFound: {
					message: 'Tarea no encontrada'
				},
				complete: {
					message: 'Se produjo un error al completar la tarea.'
				}
			}
		}
	}
};

export default literals;
