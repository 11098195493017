import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Basisgegevens',
				installationPartnerArrangeAppointment: 'Afspraak maken',
				installationPartnerExecuteRemotePreCheck: 'Voorcontrole',
				installationPartnerRegisterAtGridOperator: 'Registratie netbeheerder',
				installationPartnerProvideGridOperatorDecision: 'Besluit netbeheerder',
				installationPartnerRevokeGridOperatorRegistration: 'Herroeping netbeheerder',
				installationPartnerProvideQuotation: 'Offerte',
				installationPartnerCompleteInstallation: 'Voltooiing van de installatie'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Totale prijs (netto)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Op schema',
				delayed: 'Vertraagd',
				cancelPending: 'In afwachting van annulering',
				cancelled: 'Geannuleerd',
				done: 'Gereed'
			},
			installationType: {
				none: 'Onbepaald',
				undetermined: 'Onbepaald',
				cancelled: 'Geannuleerd',
				standard: 'Standaard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Afspraken annuleren'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Let op',
				message: 'Het stroomverbruik van de wallbox moet worden verlaagd volgens de vereisten van de netbeheerder.'
			},
			informationList: {
				heading: 'Informatie',
				emptyState: {
					heading: 'Nog geen bestelgegevens beschikbaar',
					message: 'Naarmate de bestelling vordert, vind je hier alle relevante informatie over de bestelling.'
				},
				caption: {
					basicAnswers: 'Basisantwoorden',
					gridOperatorRegistration: 'Registratie netbeheerder',
					gridOperatorDecision: 'Beslissing netbeheerder',
					preCheck: 'Voorcontrole',
					offer: 'Offerte',
					approval: 'Goedkeuring'
				},
				precheck: {
					heading: 'Voorcontrole',
					effort: 'Resultaten voorcontrole: Installatieafspraken en -inspanning',
					questionnaire: {
						heading: 'Antwoorden van klant'
					},
					installationType: {
						undetermined: 'Onbepaald',
						standard: 'Standaardinstallatie',
						delta: 'Delta-installatie'
					},
					decision: {
						accepted: 'Voldoende informatie',
						more: 'Meer informatie nodig',
						denied: 'Installatie niet mogelijk'
					},
					notes: 'Opmerkingen',
					hours: 'uur'
				},
				decision: {
					heading: 'Besluit netbeheerder',
					decision: {
						pending: 'Het installatieverzoek is nog in behandeling',
						accepted: 'De netbeheerder is akkoord met de installatie',
						denied: 'De netbeheerder heeft de installatie afgewezen'
					}
				},
				registration: {
					heading: 'Registratie netbeheerder',
					documents: 'Documenten',
					gridOperator: 'Netbeheerder'
				},
				quotation: {
					heading: 'Offerte',
					conditions: 'Offertevoorwaarden'
				},
				complete: {
					success: {
						heading: 'Installatie geslaagd'
					},
					notes: 'Opmerkingen',
					images: 'Afbeeldingen bij de installatie',
					documents: 'Documenten met betrekking tot de installatie',
					signature: 'Handtekening klant'
				}
			}
		},
		orderLineItems: {
			heading: 'Productregels bestelling',
			head: {
				title: 'Productregel',
				description: 'Beschrijving',
				productCode: 'ABB productcode'
			},
			serial: 'Serienummer wallbox',
			shippingStatus: {
				label: 'Status zending #{{shipmentNumber}}',
				pending: 'In afwachting',
				delivered: 'Geleverd',
				expected: 'Levering verwacht op '
			},
			empty: 'Geen productregels beschikbaar'
		}
	}
};

export default literals;
