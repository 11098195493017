import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'ABB wallbox installatieservice',
		appointmentCollection: {
			heading: 'Data',
			header: {
				today: 'Vandaag',
				upcoming: 'Komende'
			},
			emptyState: {
				heading: 'Geen afspraken',
				message: 'Kon geen afspraken vinden.'
			}
		},
		installationCompletion: {
			done: 'Installatie voltooid!',
			upload: {
				heading: 'Documenten verstrekken'
			},
			confirmation: {
				heading: 'Geïnstalleerde Wallbox',
				text: 'Hierbij bevestig ik de juiste en professionele installatie en ingebruikname van de geleverde wallbox met het volgende serienummer {{serialNumber}}.',
				throttle: 'Hierbij bevestig ik dat het stroomverbruik van de wallbox werd verminderd volgens de eisen van de netbeheerder.'
			},
			inspection: {
				heading: 'Inspectie',
				customerPresent: 'Inspectie met de klant',
				customerNotPresent: 'Klant is niet aanwezig'
			},
			acceptance: {
				heading: 'Aanvaarding'
			},
			signature: {
				clear: 'Wissen',
				close: 'Afsluiten',
				done: 'Gereed'
			},
			action: {
				signature: 'Handtekening van de klant',
				complete: 'Volledige installatie'
			}
		},
		installationDetail: {
			action: 'Volledige installatie',
			heading: 'Installatie',
			appointmentCollection: {
				heading: 'Vakgroepen en afspraken',
				item: {
					installationPeriod: {
						preparation: 'Installatievoorbereiding',
						beginning: 'Begin van de installatie',
						permanent: 'Permanent',
						end: 'Einde installatie',
						followUp: 'Follow-up van installatie'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Onbepaald',
				standard: 'Standaard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Afspraken',
			detail: 'Details',
			installationCompletion: 'Voltooiing installatie',
			error: 'Fout',
			notFound: 'Niet gevonden'
		},
		formatter: {
			dayNames: [
				'zondag',
				'maandag',
				'dinsdag',
				'woensdag',
				'donderdag',
				'vrijdag',
				'zaterdag'
			],
			fuzzyDaynames: {
				today: 'vandaag',
				tomorrow: 'morgen',
				yesterday: 'gisteren'
			}
		},
		navigation: {
			tasks: 'Taken',
			logout: 'Afmelden'
		},
		breadcrumbs: {
			orders: 'Besteloverzicht'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'terug',
				captionPrefix: ' op '
			},
			installationCompletion: {
				backLinkLabel: 'terug',
				captionPrefix: ' op '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Afspraken ophalen mislukt',
				message: 'Tijdens het laden van de opgevraagde informatie is een fout opgetreden.'
			},
			installationCompletion: {
				heading: 'De installatie is mislukt',
				message: 'Tijdens het uitvoeren van de taak is een fout opgetreden.',
				resolveAction: {
					label: 'Terug naar detailpagina'
				},
				notAllowed: 'Voltooiing van de installatie is niet toegestaan.'
			},
			installerAppointmentNotFound: 'Afspraak installateur niet gevonden',
			orderNotFound: 'Bestelling niet gevonden',
			installationDetail: {
				heading: 'Het ophalen van de installatiegegevens is mislukt',
				message: 'Tijdens het laden van de opgevraagde informatie is een fout opgetreden.'
			},
			validation: {
				heading: 'Ongeldige gegevens',
				message: 'De verzonden gegevens zijn ongeldig.'
			},
			generic: {
				heading: 'Er ging iets mis',
				message: 'Er is een fout opgetreden die niet is afgehandeld tijdens het renderen',
				resolveAction: {
					retry: {
						label: 'Opnieuw laden'
					}
				}
			},
			offline: {
				heading: 'Netwerkverbinding niet beschikbaar',
				message: 'Maak verbinding met een netwerk om de applicatie te gebruiken.'
			},
			notFound: {
				heading: 'Niet gevonden',
				message: 'De opgevraagde bron is niet gevonden',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			task: {
				notFound: {
					message: 'Taak niet gevonden'
				},
				complete: {
					message: 'Tijdens het uitvoeren van de taak is een fout opgetreden.'
				}
			}
		}
	}
};

export default literals;
