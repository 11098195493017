import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './Tag.scss';

export type TagProps = {
	label: string
	noWrap?: boolean
};

export const Tag = (props: TagProps) => {
	const { label, noWrap = null } = props;

	const classMap = {
		'tag__label': true,
		'tag__label--noWrap': noWrap ?? false
	};
	return (
		<div className="tag">
			<span className={buildCssClassStringFromClassMap(classMap)}>{label}</span>
		</div>
	);
};
