import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'Servizio di installazione centralina a parete di ABB',
		appointmentCollection: {
			heading: 'Date',
			header: {
				today: 'Oggi',
				upcoming: 'Prossimamente'
			},
			emptyState: {
				heading: 'Nessun appuntamento',
				message: 'Non è stato possibile trovare alcun appuntamento.'
			}
		},
		installationCompletion: {
			done: 'Installazione completata!',
			upload: {
				heading: 'Fornire documenti'
			},
			confirmation: {
				heading: 'Centralina a parete installata',
				text: 'Con la presente confermo la corretta e professionale installazione e messa in funzione della centralina a parete consegnata con il seguente numero di serie {{serialNumber}}.',
				throttle: 'Garantisco che il consumo energetico della centralina a parete è stato ridotto in base ai requisiti dei gestori di rete.'
			},
			inspection: {
				heading: 'Ispezione',
				customerPresent: 'Ispezione con il cliente',
				customerNotPresent: 'Il cliente non è presente'
			},
			acceptance: {
				heading: 'Accettazione'
			},
			signature: {
				clear: 'Cancella',
				close: 'Chiudi',
				done: 'Fatto'
			},
			action: {
				signature: 'Firma del cliente',
				complete: 'Completa installazione'
			}
		},
		installationDetail: {
			action: 'Completa installazione',
			heading: 'Installazione',
			appointmentCollection: {
				heading: 'Attività e appuntamenti',
				item: {
					installationPeriod: {
						preparation: 'Preparazione all\'installazione',
						beginning: 'Inizio dell\'installazione',
						permanent: 'Permanente',
						end: 'Fine dell\'installazione',
						followUp: 'Follow-up dell\'installazione'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Indeterminato',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Appuntamenti',
			detail: 'Dettagli',
			installationCompletion: 'Installazione completata',
			error: 'Errore',
			notFound: 'Non trovato'
		},
		formatter: {
			dayNames: [
				'Domenica',
				'Lunedì',
				'Martedì',
				'Mercoledì',
				'Giovedì',
				'Venerdì',
				'Sabato'
			],
			fuzzyDaynames: {
				today: 'oggi',
				tomorrow: 'domani',
				yesterday: 'ieri'
			}
		},
		navigation: {
			tasks: 'Attività',
			logout: 'Esci'
		},
		breadcrumbs: {
			orders: 'Panoramica ordine'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'indietro',
				captionPrefix: ' in '
			},
			installationCompletion: {
				backLinkLabel: 'indietro',
				captionPrefix: ' in '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Il recupero degli appuntamenti non è riuscito',
				message: 'Si è verificato un errore durante il caricamento delle informazioni richieste.'
			},
			installationCompletion: {
				heading: 'Il completamento dell\'installazione non è riuscito',
				message: 'Si è verificato un errore durante il completamento dell\'attività.',
				resolveAction: {
					label: 'Torna alla pagina dei dettagli'
				},
				notAllowed: 'Il completamento dell\'installazione non è consentito.'
			},
			installerAppointmentNotFound: 'L\'appuntamento con l\'installatore non è stato trovato',
			orderNotFound: 'Ordine non trovato',
			installationDetail: {
				heading: 'Il recupero dei dati di installazione non è riuscito',
				message: 'Si è verificato un errore durante il caricamento delle informazioni richieste.'
			},
			validation: {
				heading: 'Dati non validi',
				message: 'I dati inviati non sono validi.'
			},
			generic: {
				heading: 'Si è verificato un errore',
				message: 'Si è verificato un errore che non è stato gestito durante il render',
				resolveAction: {
					retry: {
						label: 'Ricarica'
					}
				}
			},
			offline: {
				heading: 'Connessione di rete non disponibile',
				message: 'Per utilizzare l\'applicazione, collegarsi a una rete.'
			},
			notFound: {
				heading: 'Non trovato',
				message: 'La risorsa richiesta non è stata trovata',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			task: {
				notFound: {
					message: 'Attività non trovata'
				},
				complete: {
					message: 'Si è verificato un errore durante il completamento dell\'attività.'
				}
			}
		}
	}
};

export default literals;
