import { Tag } from '../tag/Tag';

import './InformationHead.scss';

export type InformationHeadProps = {
	informationKey: string,
	informationValue?: string
};

export function InformationHead(props: InformationHeadProps) {

	const { informationKey, informationValue } = props;

	const renderValue = () => {
		if (informationValue) {
			return <Tag label={informationValue} />;
		}

		return;
	};

	return (
		<article className="information-head">
			<span className="information-head__information-key">
				{informationKey}
			</span>
			{renderValue()}
		</article>
	);
}
