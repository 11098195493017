import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Algo correu mal',
				message: 'Ocorreu um erro que não foi tratado corretamente',
				resolveAction: {
					label: 'Recarregar'
				}
			},
			recoverLabel: 'Tente novamente',
			fetchHeading: 'A procura falhou',
			mutateHeading: 'A atualização falhou',
			createHeading: 'A criação falhou',
			deleteHeading: 'A eliminação falhou',
			taskAssignHeading: 'A atribuição falhou',
			taskCompleteHeading: 'A conclusão falhou'
		}
	}
};

export default literals;
