import React, { ReactNode, useEffect, useState } from 'react';

import { UrlResolver } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';
import { useModalDialogueManager } from '../modal-dialogue-manager/ModalDialogueManager.context';
import { ImageModalContent } from '../modal-image/ImageModalContent';
import { Notification } from '../notification/Notification';
import { NotificationLevel } from '../notification/Notification.enums';

import './ImageThumbnail.scss';

export type ImageThumbnailProps = {
	file: DownloadFile
};

export function ImageThumbnail(props: ImageThumbnailProps) {
	const { file } = props;

	const auth = useAuth();
	const l10n = useL10n();
	const modalDialogueManager = useModalDialogueManager();
	const documentStoreApiBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_API_BASE_URL'])
		.getOrThrow(new AppError('No document store API base URL provided'));
	const documentStoreMediaBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_MEDIA_BASE_URL'])
		.getOrThrow(new AppError('No document store media base URL provided'));
	const [imageUrl, setImageUrl] = useState<Nullable<string>>(null);
	const [urlResolveError, setUrlResolveError] = useState<Nullable<AppError>>(null);

	useEffect(() => {
		const urlResolver = new UrlResolver();
		const resolveUrl = async (): Promise<void> => {
			try {
				const resolvedUrl = await urlResolver.resolve(documentStoreApiBaseUrl, documentStoreMediaBaseUrl, file.url, auth.getToken().get());
				setImageUrl(resolvedUrl);
				setUrlResolveError(null);
			} catch (e) {
				if ((e as Error)?.name !== 'AbortError') {
					setUrlResolveError(e as AppError);
				}
			}
		};
		void resolveUrl();
		return () => {
			urlResolver.abort();
			setUrlResolveError(null);
		};
	}, []);

	if (urlResolveError !== null) {
		const message = l10n.translate('sharedUiPrimitive.imageThumbnail.resolveErrorMessage');
		return (
			<Notification heading={message} details={[urlResolveError.message]} dismissable={false} level={NotificationLevel.WARNING} />
		);
	}

	const handleModal = (): void => {
		if (imageUrl === null) {
			return;
		}
		modalDialogueManager.push({
			caption: file.meta.title ?? file.meta.fileName,
			content: (): ReactNode => {
				return (<ImageModalContent file={file} />);
			}
		});
	};

	const renderThumbnail = (): ReactNode => {
		if (imageUrl === null) {
			return null;
		}
		return (
			<img className="image-thumbnail__img" src={imageUrl} alt={file.meta.title ?? ''} />
		);
	};

	return (
		<div className="image-thumbnail" onClick={handleModal}>
			{renderThumbnail()}
			<div className="image-thumbnail__overlay">
				<span className="image-thumbnail__overlay__icon">
					<Icon name={IconIdentifier.MAGNIFYING_GLASS_PLUS} size={IconSize.MEDIUM} />
				</span>
			</div>
		</div>
	);
}
