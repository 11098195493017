import { ReactNode, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { mobileTopbarContentContext } from './MobileTopbarContent.context';
import { TopbarCaption, TopbarLink } from './MobileTopbarContent.types';
import { buildTopbarLinkChecksum } from './MobileTopbarContent.util';

export type TopbarProps = {
	children: ReactNode
};

export function MobileTopbarContent(props: TopbarProps) {

	const { children } = props;

	const [currentLink, setCurrentLink] = useState<Nullable<TopbarLink>>(null);
	const [currentCaption, setCurrentCaption] = useState<Nullable<TopbarCaption>>(null);
	const [currentIsVisible, setCurrentIsVisible] = useState<boolean>(true);

	const providerValue = {
		setLink: (link: Nullable<TopbarLink>): void => {
			if (buildTopbarLinkChecksum(currentLink) === buildTopbarLinkChecksum(link)) {
				return;
			}
			setCurrentLink(link);
		},
		getLink: (): Nullable<TopbarLink> => {
			return currentLink;
		},
		setCaption: (caption: Nullable<TopbarCaption>): void => {
			if (currentCaption === caption) {
				return;
			}
			setCurrentCaption(caption);
		},
		getCaption: (): Nullable<TopbarCaption> => {
			return currentCaption;
		},
		setIsVisible: (isVisible: boolean): void => {
			if (currentIsVisible === isVisible) {
				return;
			}
			setCurrentIsVisible(isVisible);
		},
		getIsVisible: (): boolean => {
			return currentIsVisible;
		}
	};

	return (
		<mobileTopbarContentContext.Provider value={providerValue}>
			{children}
		</mobileTopbarContentContext.Provider>
	);

}
