import React, { ReactNode } from 'react';

import { useExpandable } from '@abb-emobility/shared/interaction';
import { Nullable } from '@abb-emobility/shared/util';

import './InformationItems.scss';

export type InformationItemsProps = {
	children?: ReactNode
};

export function InformationItems(props: InformationItemsProps): Nullable<React.JSX.Element> {
	const { children } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	if (expanded) {
		return (
			<main className="information-items">
				{children}
			</main>
		);
	}

	return null;
}
