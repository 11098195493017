import { useRef } from 'react';

import { buildGravatarUrl } from '@abb-emobility/shared/gravatar-integration';
import { buildCssClassNameFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { GravatarDensity, GravatarSize } from './Gravatar.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './Gravatar.scss';

export type GravatarProps = {
	name?: string,
	mailAddress?: string,
	size?: GravatarSize,
	densitiy?: GravatarDensity
};

export function Gravatar(props: GravatarProps) {

	const { name, mailAddress, size = GravatarSize.MEDIUM, densitiy = GravatarDensity.FACTOR_2 } = props;

	const gravatarImageElement = useRef<Nullable<HTMLImageElement>>(null);

	const handleImageError = () => {
		if (gravatarImageElement.current === null) {
			return;
		}
		gravatarImageElement.current.classList.add('gravatar__image--failed');
	};

	const getIconSizeFromGravatarSize = (size: GravatarSize): IconSize => {
		if (size === GravatarSize.LARGE || size === GravatarSize.EXTRA_LARGE) {
			return IconSize.MEDIUM;
		}
		return IconSize.SMALL;
	};

	const getImageDensity = (): number => {
		switch (densitiy) {
			case GravatarDensity.FACTOR_1:
				return 1;
			case GravatarDensity.FACTOR_2:
				return 2;
			case GravatarDensity.FACTOR_3:
				return 3;
		}
	};

	const getImageSizeFromGravatarSize = (size: GravatarSize): number => {
		const densityFactor = getImageDensity();
		switch (size) {
			case GravatarSize.EXTRA_SMALL:
				return 20 * densityFactor;
			case GravatarSize.SMALL:
				return 24 * densityFactor;
			case GravatarSize.MEDIUM:
				return 32 * densityFactor;
			case GravatarSize.LARGE:
				return 40 * densityFactor;
			case GravatarSize.EXTRA_LARGE:
				return 48 * densityFactor;
		}
	};

	const sizeCssClass = buildCssClassNameFromEnumValue(size, 'gravatar');

	return (
		<div className={`gravatar ${sizeCssClass}`}>
			<img
				src={buildGravatarUrl(mailAddress, getImageSizeFromGravatarSize(size)).getOrDefault('')}
				alt={name}
				className="gravatar__image"
				onError={handleImageError}
				ref={gravatarImageElement}
			/>
			<Icon name={IconIdentifier.USER} size={getIconSizeFromGravatarSize(size)} />
		</div>
	);

}
