import { useExpandable } from '@abb-emobility/shared/interaction';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './InformationHeader.scss';

export type InformationHeaderProps = {
	heading: string,
	date: string
};

export function InformationHeader(props: InformationHeaderProps) {
	const { heading, date } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const renderIcon = () => {
		if (expanded) {
			return IconIdentifier.CARET_DOWN;
		}
		return IconIdentifier.CARET_RIGHT;
	};

	return (
		<header className="information-header" tabIndex={0} onClick={expandable.toggle}>
			<span className="information-header__icon">
				<Icon name={IconIdentifier.CHECK} />
			</span>
			<section className="information-header__content">
				<h1 className="information-header__content__heading">{heading}</h1>
				<p className="information-header__content__description">{date}</p>
			</section>
			<div className="information-header__action">
				<span className="information-header__action">
					<Icon name={renderIcon()} />
				</span>
			</div>
		</header>
	);
}
