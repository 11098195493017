import React from 'react';

import { useInstallerAppointmentCollectionData } from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { createDateFromTimestamp, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { InstallationDetailAppointmentCollectionItem } from '../installation-detail-appointment-collection-item/InstallationDetailAppointmentCollectionItem';

import './InstallationDetailAppointmentCollection.scss';

export type InstallationDetailCollectionProps = {
	orderId: ModelPrimaryKey
};

export function InstallationDetailAppointmentCollection(props: InstallationDetailCollectionProps) {

	const { orderId } = props;

	const l10n = useL10n();

	const appointmentsSort = (left: InstallerAppointmentModel, right: InstallerAppointmentModel): number => {
		const leftPeriodStartsAt = createDateFromTimestamp(left.period.start).getTime();
		const rightPeriodStartsAt = createDateFromTimestamp(right.period.start).getTime();
		if (leftPeriodStartsAt === rightPeriodStartsAt) {
			return 0;
		}
		return leftPeriodStartsAt > rightPeriodStartsAt ? 1 : -1;
	};

	const appointmentsFilter = (value: InstallerAppointmentModel): boolean => {
		return value.orderId === orderId;
	};

	const installerAppointmentsData = useInstallerAppointmentCollectionData();
	const installerAppointments = installerAppointmentsData.query(appointmentsSort, appointmentsFilter);

	const renderAppointments = (): Array<JSX.Element> => {
		return installerAppointments.map((appointment) => {
				return (
					<InstallationDetailAppointmentCollectionItem
						key={appointment.id}
						appointment={appointment}
					/>
				);
			}
		);
	};

	return (
		<main className="installation-detail-appointment-collection">
			<section className="installation-detail-appointment-collection__section">
				<h1 className="installation-detail-appointment-collection__header">
					{l10n.translate('omsInstallationPartnerServiceApp.installationDetail.appointmentCollection.heading')}
				</h1>
				<section className="installation-detail-appointment-collection__items">
					{renderAppointments()}
				</section>
			</section>
		</main>
	);

}
