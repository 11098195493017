import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Morgens',
				noon: 'Mittags',
				afternoon: 'Nachmittags',
				evening: 'Abends'
			}
		},
		gridOperatorInformation: {
			heading: 'Netzbetreiber'
		},
		informationHead: {
			orderDate: 'Bestelldatum',
			orderNumber: 'Bestellnummer'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Die Auflösung der Bild-URL ist fehlgeschlagen'
		},
		task: {
			status: {
				started: 'Kommend',
				overdue: 'Überfällig',
				escalated: 'Eskaliert',
				done: 'Erledigt',
				doneOverdue: 'Erledigt',
				doneEscalated: 'Erledigt'
			}
		},
		upload: {
			empty: {
				message: 'Keine Dateien gewählt'
			},
			dropMessage: 'Dateien hierher ziehen',
			selectButtonLabel: 'Dateien wählen',
			hint: 'Unterstützte Dateiformate: JPG, PNG oder PDF – insgeasmt kleiner als {{maxFilesize}}.',
			fileSizeWarning: 'Die von Ihnen ausgewählten Dateien überschreiten die zulässige Gesamtdateigröße. Einige der ausgewählten Dateien werden nicht übermittelt.'
		},
		choose: {
			label: 'Wählen'
		},
		search: {
			foundIn: 'Gefunden in',
			noResults: 'Keine Ergebnisse gefunden'
		},
		termsAccept: {
			buttonLabel: 'Akzeptieren'
		},
		collection: {
			filter: {
				filter: 'Filter',
				none: 'keiner',
				active: '{{count}} aktiv',
				resetFilter: 'Zurücksetzen',
				group: {
					dueDate: 'Fälligkeit',
					priority: 'Priorität',
					estimatedCompletionDate: 'Geplantes Installationsdatum',
					state: 'Status',
					installationType: 'Installation',
					runningOrderCount: 'Aktive Bestellungen',
					languageCode: 'Sprache'
				},
				options: {
					dueDateOverdue: 'Überfällig',
					dueDateToday: 'Heute fällig',
					priorityHigh: 'Hohe Priorität',
					priorityMedium: 'Mittlere Priorität',
					priorityLow: 'Geringe Priorität',
					statusDone: 'Erledigt',
					statusOpen: 'Offen',
					installationTypeStandard: 'Standard Installation',
					installationTypeDelta: 'Delta Installation',
					installationTypeUndetermined: 'Noch nicht ermittelt',
					installationTypeCancelled: 'Storniert',
					ordersActive: 'Hat offene Bestellungen',
					ordersSettled: 'Hat erledigte Bestellungen',
					onSchedule: 'Im Zeitplan',
					delayed: 'Verspätet',
					cancelled: 'Storniert',
					imported: 'Importiert',
					validationFailed: 'Ungültig',
					done: 'Erledigt',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Sortierung',
				direction: {
					ascending: 'Aufsteigend',
					descending: 'Absteigend'
				},
				options: {
					priority: 'Priorität',
					estimatedCompletionDate: 'Erwartetes Erfüllungsdatum',
					status: 'Status',
					installationDate: 'Geplantes Installationsdatum',
					orderDate: 'Bestelldatum',
					estimatedDeliveryDate: 'Erwartetes Lieferdatum',
					shippingState: 'Versandstatus',
					name: 'Name',
					firstOrder: 'Erste Bestellung',
					lastOrder: 'Letzte Bestellung',
					dueDate: 'Fälligkeit'
				}
			}
		}
	}
};

export default literals;
