import React, { ReactNode } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './AppointmentCollectionSection.scss';

export type AppointmentCollectionSectionProps = {
	isToday?: boolean,
	children: ReactNode
};

export function AppointmentCollectionSection(props: AppointmentCollectionSectionProps): React.JSX.Element {

	const { isToday, children } = props;

	const l10n = useL10n();

	const cn = buildCssClassStringFromClassMap({
		'appointment-collection-section__header': true,
		'appointment-collection-section__header--today': isToday === true
	});

	const renderHeader = (): React.JSX.Element => {
		if (isToday) {
			return (
				<h1 className={cn}>{l10n.translate('omsInstallationPartnerServiceApp.appointmentCollection.header.today')}</h1>
			);
		}

		return (
			<h1 className={cn}>{l10n.translate('omsInstallationPartnerServiceApp.appointmentCollection.header.upcoming')}</h1>
		);
	};

	return (
		<section className="appointment-collection-section">
			{renderHeader()}
			{children}
		</section>
	);
}
