import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Morning',
				noon: 'Noon',
				afternoon: 'Afternoon',
				evening: 'Evening'
			}
		},
		gridOperatorInformation: {
			heading: 'Grid operator'
		},
		informationHead: {
			orderDate: 'Order date',
			orderNumber: 'Order no.'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Resolving the image URL failed'
		},
		task: {
			status: {
				started: 'Upcoming',
				overdue: 'Overdue',
				escalated: 'Escalated',
				done: 'Done',
				doneOverdue: 'Done',
				doneEscalated: 'Done'
			}
		},
		upload: {
			empty: {
				message: 'No files selected'
			},
			dropMessage: 'Drag and drop files here',
			selectButtonLabel: 'Choose file',
			hint: 'Files supported: JPG, PNG or PDF smaller than {{maxFilesize}} in total.',
			fileSizeWarning: 'The files you selected are exceeding the allowed maximum total file size. Some of the selected files will not be submitted.'
		},
		choose: {
			label: 'Choose'
		},
		search: {
			foundIn: 'Found in',
			noResults: 'No Results Found'
		},
		termsAccept: {
			buttonLabel: 'Accept'
		},
		collection: {
			filter: {
				filter: 'Filter',
				none: 'none',
				active: '{{count}} active',
				resetFilter: 'Reset filter',
				group: {
					dueDate: 'Due date',
					priority: 'Priority',
					estimatedCompletionDate: 'Planned installation date',
					state: 'Status',
					installationType: 'Installation',
					runningOrderCount: 'Running orders',
					languageCode: 'Language'
				},
				options: {
					dueDateOverdue: 'Overdue',
					dueDateToday: 'Due today',
					priorityHigh: 'Priority high',
					priorityMedium: 'Priority medium',
					priorityLow: 'Priority low',
					statusDone: 'Done',
					statusOpen: 'Open',
					installationTypeStandard: 'Standard installation',
					installationTypeDelta: 'Delta installation',
					installationTypeUndetermined: 'Undetermined',
					installationTypeCancelled: 'Cancelled',
					ordersActive: 'Has open orders',
					ordersSettled: 'Has settled orders',
					onSchedule: 'On schedule',
					delayed: 'Delayed',
					cancelled: 'Cancelled',
					done: 'Done',
					imported: 'Imported',
					validationFailed: 'Invalid',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Sort by',
				direction: {
					ascending: 'Ascending',
					descending: 'Descending'
				},
				options: {
					priority: 'Priority',
					estimatedCompletionDate: 'Estimated completion date',
					status: 'Status',
					installationDate: 'Planned installation date',
					orderDate: 'Order date',
					estimatedDeliveryDate: 'Est. delivery date',
					shippingState: 'Shipping state',
					name: 'Name',
					firstOrder: 'First Order',
					lastOrder: 'Last Order',
					dueDate: 'Due date'
				}
			}
		}
	}
};

export default literals;
