import { ReactNode } from 'react';

import './InstallationDetailLayout.scss';

type LayoutProps = {
	children: ReactNode
};

/*
* InstallationDetailLayout is needed for the proper presentation of InstallationDetail in the InstallationPartnerServiceApp. However, it is
* NOT defined in InstallationDetail.scss as it is also needed for proper error and pending state rendering.
* */
export function InstallationDetailLayout(props: LayoutProps) {
	const { children } = props;

	return (
		<section className='installation-detail-layout'>
			{children}
		</section>
	);
}
