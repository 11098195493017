import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { HintLevel } from './Hint.enums';
import { ButtonGhost } from '../button-ghost/ButtonGhost';
import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './Hint.scss';

export type HintAction = {
	label: string,
	onInvoke: () => void
};

export type HintProps = {
	level?: HintLevel,
	icon?: IconIdentifier,
	heading: string,
	message?: string,
	details?: Array<string>,
	actions?: Array<HintAction>,
	gray?: boolean
};

export function Hint(props: HintProps) {
	const { level, heading, message, details = [], actions = [], icon, gray } = props;

	// conditional classNames
	const hintClassMap = {
		'hint': true,
		[`hint--${level}`]: level,
		[`hint--gray`]: gray
	};

	const hintHeadingClassMap = {
		'hint__content__header__heading': true,
		[`hint__content__header__heading--${level}`]: level
	};

	const hintFooterLinkClassMap = {
		'hint__content__footer__link': true,
		'button-ghost': true,
		[`hint__content__footer__link--${level}`]: level
	};

	const hintColorBarClassMap = {
		'hint__color-bar': true,
		[`hint__color-bar--${level}`]: level
	};

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="hint__content__header__message">
				{message}
			</p>
		);
	};

	const renderDetails = (): ReactNode => {
		if (details.length === 0) {
			return;
		}
		const detailListItems = details.map((detail, index): ReactNode => {
			return (
				<li className="hint__content__main__list__item" key={index}>
					{detail}
				</li>
			);
		});
		return (
			<main className="hint__content__main">
				<ul className="hint__content__main__list">
					{detailListItems}
				</ul>
			</main>
		);
	};

	const renderActions = (): ReactNode => {
		if (actions.length === 0) {
			return;
		}
		const actionItems = actions.map((action, index): ReactNode => {
			return (
				<ButtonGhost
					label="Link"
					onClick={(event) => {
						event.preventDefault();
						action.onInvoke();
					}}
					className={buildCssClassStringFromClassMap(hintFooterLinkClassMap)}
					key={index}
				>
					{action.label}
				</ButtonGhost>
			);
		});
		return (
			<footer className="hint__content__footer">
				{actionItems}
			</footer>
		);
	};

	const renderIcon = () => {
		if (icon) {
			return (
				<Icon name={icon} size={IconSize.MEDIUM} />
			);
		}

		return;
	};

	return (
		<article className={buildCssClassStringFromClassMap(hintClassMap)}>
			<span className={buildCssClassStringFromClassMap(hintColorBarClassMap)}>
			</span>
			<div className="hint__content">
				<header className="hint__content__header">
					{renderIcon()}
					<h1 className={buildCssClassStringFromClassMap(hintHeadingClassMap)}>
						{heading}
					</h1>
				</header>
				<section className="hint__content__message">
					{renderMessage()}
				</section>
				{renderDetails()}
				{renderActions()}
			</div>
		</article>
	);
}
