import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Ochtend',
				noon: 'Middaguur',
				afternoon: 'Middag',
				evening: 'Avond'
			}
		},
		gridOperatorInformation: {
			heading: 'Netbeheerder'
		},
		informationHead: {
			orderDate: 'Besteldatum',
			orderNumber: 'Bestelnr.'
		},
		imageThumbnail: {
			resolveErrorMessage: 'Oplossen van de URL van de afbeelding mislukt'
		},
		task: {
			status: {
				started: 'Komend',
				overdue: 'Achterstallig',
				escalated: 'Geëscaleerd',
				done: 'Gereed',
				doneOverdue: 'Gereed',
				doneEscalated: 'Gereed'
			}
		},
		upload: {
			empty: {
				message: 'Geen bestanden geselecteerd'
			},
			dropMessage: 'Sleep bestanden hier naartoe',
			selectButtonLabel: 'Kies bestand',
			hint: 'Ondersteunde bestanden: JPG, PNG of PDF kleiner dan {{maxFilesize}} in totaal.',
			fileSizeWarning: 'De bestanden die je hebt geselecteerd overschrijden de toegestane maximale totale bestandsgrootte. Sommige van de geselecteerde bestanden worden niet ingediend.'
		},
		choose: {
			label: 'Kies'
		},
		search: {
			foundIn: 'Gevonden in',
			noResults: 'Geen resultaten gevonden'
		},
		termsAccept: {
			buttonLabel: 'Accepteren'
		},
		collection: {
			filter: {
				filter: 'Filter',
				none: 'geen',
				active: '{{count}} actief',
				resetFilter: 'Filter resetten',
				group: {
					dueDate: 'Vervaldatum',
					priority: 'Prioriteit',
					estimatedCompletionDate: 'Geplande installatiedatum',
					state: 'Status',
					installationType: 'Installatie',
					runningOrderCount: 'Lopende bestellingen',
					languageCode: 'Taal'
				},
				options: {
					dueDateOverdue: 'Achterstallig',
					dueDateToday: 'Vandaag uitgerekend',
					priorityHigh: 'Prioriteit hoog',
					priorityMedium: 'Prioriteit middelhoog',
					priorityLow: 'Prioriteit laag',
					statusDone: 'Gereed',
					statusOpen: 'Open',
					installationTypeStandard: 'Standaardinstallatie',
					installationTypeDelta: 'Delta-installatie',
					installationTypeUndetermined: 'Onbepaald',
					installationTypeCancelled: 'Geannuleerd',
					ordersActive: 'Heeft openstaande bestellingen',
					ordersSettled: 'Heeft afgehandelde bestellingen',
					onSchedule: 'Op schema',
					delayed: 'Vertraagd',
					cancelled: 'Geannuleerd',
					imported: 'Geïmporteerd',
					validationFailed: 'Ongeldig',
					done: 'Gereed',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Sorteren op',
				direction: {
					ascending: 'Oplopend',
					descending: 'Aflopend'
				},
				options: {
					priority: 'Prioriteit',
					estimatedCompletionDate: 'Geschatte einddatum',
					status: 'Status',
					installationDate: 'Geplande installatiedatum',
					orderDate: 'Besteldatum',
					estimatedDeliveryDate: 'Geschatte leveringsdatum',
					shippingState: 'Verzendstatus',
					name: 'Naam',
					firstOrder: 'Eerste bestelling',
					lastOrder: 'Laatste bestelling',
					dueDate: 'Vervaldatum'
				}
			}
		}
	}
};

export default literals;
