import React from 'react';

import { useInstallationPartnerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { OrderLineItems as OrderLineItemsUiDomain } from '@abb-emobility/oms/ui-domain';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';

export function OrderLineItems() {

	const l10n = useL10n();

	const orderData = useInstallationPartnerOrderEntityData();
	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.orderNotFound')));

	return (
		<OrderLineItemsUiDomain order={order} />
	);
}
