import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiAuthEmitter: {
		error: {
			oauth: {
				retry: {
					label: 'Réessayer'
				},
				heading: 'Échec de l’authentification',
				message: 'Un problème s’est produit lors de votre connexion.'
			}
		}
	}
};

export default literals;
