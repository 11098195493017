import React, { useEffect, useState } from 'react';

import { UrlResolver } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { DownloadListItem } from '../download-list-item/DownloadListItem';

import './ImageModalContent.scss';

export type ImageModalContentProps = {
	file: DownloadFile
};

export function ImageModalContent(props: ImageModalContentProps) {

	const { file } = props;

	const auth = useAuth();
	const documentStoreApiBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_API_BASE_URL'])
		.getOrThrow(new AppError('No document store API base URL provided'));
	const documentStoreMediaBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_MEDIA_BASE_URL'])
		.getOrThrow(new AppError('No document store media base URL provided'));
	const [imageUrl, setImageUrl] = useState<Nullable<string>>(null);

	useEffect(() => {
		const urlResolver = new UrlResolver();
		const resolveUrl = async (): Promise<void> => {
			try {
				const resolvedUrl = await urlResolver.resolve(documentStoreApiBaseUrl, documentStoreMediaBaseUrl, file.url, auth.getToken().get());
				setImageUrl(resolvedUrl);
			} catch (e) {
				console.error(e);
			}
		};
		void resolveUrl();
		return () => {
			urlResolver.abort();
		};
	}, []);

	if (imageUrl === null) {
		return null;
	}

	return (
		<main className="image-modal-content">
			<img
				className="image-modal-content__image"
				src={imageUrl}
				alt={file.meta.title ?? ''}
			/>
			<DownloadListItem file={file} />
		</main>
	);
}
