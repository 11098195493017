import React, { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './MobileTopbar.scss';

export type MobileTopbarProps = {
	children: ReactNode,
	fixed?: boolean,
};

export function MobileTopbar(props: MobileTopbarProps) {
	const { children, fixed } = props;

	const mobileTopbarClassMap = {
		'mobile-topbar': true,
		'mobile-topbar--fixed': fixed
	};
	return (
		<div className={buildCssClassStringFromClassMap(mobileTopbarClassMap)}>
			{children}
		</div>
	);
}
