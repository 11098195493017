import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ButtonGhost, ButtonPrimary, ButtonTertiary, Card, IconIdentifier, SubmissionStatus } from '@abb-emobility/shared/ui-primitive';

import './InstallationCompletionSignature.scss';

type InstallationCompletionSignatureProps = {
	onClose: () => void,
	onSubmit: (signature: HTMLCanvasElement) => void,
	submitButtonState: SubmissionStatus
};

export function InstallationCompletionSignature(props: InstallationCompletionSignatureProps) {

	const { onClose, onSubmit, submitButtonState } = props;

	const l10n = useL10n();
	const padRef = React.useRef<SignatureCanvas>(null);

	const [canvasIsEmpty, setCanvasIsEmpty] = useState(true);

	const handleSubmit = () => {
		const picture = padRef.current?.getCanvas() ?? null;
		if (picture !== null) {
			onSubmit(picture);
		}
	};

	const handleClose = () => {
		onClose();
	};

	const handleInput = () => {
		setCanvasIsEmpty(false);
	};

	const handleReset = () => {
		padRef.current?.clear();
		setCanvasIsEmpty(true);
	};

	return (
		<section className="installation-completion-signature">
			<Card>
				<section className="installation-completion-signature__container">
					<SignatureCanvas
						penColor="black"
						canvasProps={{
							className: 'installation-completion-signature__library'
						}}
						ref={padRef}
						onBegin={handleInput}
						clearOnResize={false}
					/>
					<ButtonTertiary
						onClick={handleReset}
						label={l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.signature.clear')}
						icon={IconIdentifier.ARROW_COUNTER_CLOCKWISE} />
				</section>
				<section className="installation-completion-signature__footer">
					<div className="installation-completion-signature__footer__left" />
					<div className="installation-completion-signature__footer__right">
						<ButtonGhost onClick={handleClose} label={l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.signature.close')} />
						<ButtonPrimary
							onClick={handleSubmit}
							label={l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.signature.done')}
							submissionStatus={submitButtonState}
							disabled={canvasIsEmpty}
						/>
					</div>
				</section>
			</Card>
		</section>
	);
}
