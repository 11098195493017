import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiAuthEmitter: {
		error: {
			oauth: {
				retry: {
					label: 'Tentar novamente'
				},
				heading: 'A autenticação falhou',
				message: 'Algo correu mal ao efetuar o seu início de sessão.'
			}
		}
	}
};

export default literals;
