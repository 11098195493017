import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InstallerAppointmentCollectionDataProvider } from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { AppLayoutMobileTopbar, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { isToday, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { InstallationDetail } from '../../components/installation-detail/InstallationDetail';
import { InstallationDetailLayout } from '../../components/installation-detail-layout/InstallationDetailLayout';
import { MobileTopbar } from '../../components/mobile-topbar/MobileTopbar';
import { MobileTopbarNavigate } from '../../components/mobile-topbar/mobile-topbar-navigate/MobileTopbarNavigate';
import { useTopbar } from '../../components/mobile-topbar-content/MobileTopbarContent.context';
import { useTopbarLinkModifier, useTopbarVisibilityModifier } from '../../components/mobile-topbar-content/MobileTopbarContent.hooks';
import { useBuildPageTitle } from '../../page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function InstallationDetailView() {

	const l10n = useL10n();
	const navigate = useNavigate();
	const params = useParams();
	const topbar = useTopbar();
	const { createRouteUrl } = useRouteUrl();

	usePageTitle(useBuildPageTitle('omsInstallationPartnerServiceApp.pageTitle.detail'));
	// Set topbar link and visibility
	useTopbarLinkModifier({
		link: createRouteUrl(RoutePath.ROOT),
		label: l10n.translate('omsInstallationPartnerServiceApp.topbar.installationDetail.backLinkLabel')
	});
	useTopbarVisibilityModifier(true);

	const installerAppointmentId = params.installerAppointmentId ?? null;
	if (installerAppointmentId === null) {
		throw new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.installerAppointmentNotFound'));
	}

	const handleInstallerAppointmentQuery = (installerAppointment: InstallerAppointmentModel): void => {
		// Set topbar contents
		let topbarCaption: string;
		if (isToday(createDateFromTimestamp(installerAppointment.period.start))) {
			topbarCaption = l10n.translate(l10nLiteralFromEnumValue(
				l10n.formatTimestampDayname(installerAppointment.period.start, '', DaynameFormat.FUZZY),
				'omsInstallationPartnerServiceApp.formatter.fuzzyDaynames'
			));
		} else {
			topbarCaption = l10n.formatTimestampDayname(installerAppointment.period.start, '', DaynameFormat.LONG);
		}

		topbarCaption += ' ' + l10n.translate('omsInstallationPartnerServiceApp.topbar.installationDetail.captionPrefix');
		topbarCaption += ' ' + l10n.formatTimestampTime(installerAppointment.period.start);
		topbar.setCaption(topbarCaption);
	};

	const handleAction = () => {
		navigate(createRouteUrl(RoutePath.INSTALLATION_COMPLETION, ['installerAppointmentId', installerAppointmentId]));
	};

	return (
		<>
			<AppLayoutMobileTopbar>
				<MobileTopbar fixed={true}>
					<MobileTopbarNavigate />
				</MobileTopbar>
			</AppLayoutMobileTopbar>
			<InstallationDetailLayout>
				<UiErrorHandler>
					<InstallerAppointmentCollectionDataProvider
						pendingComponent={SpinnerCircle}
					>
						<InstallationDetail
							installerAppointmentId={installerAppointmentId}
							onQuery={handleInstallerAppointmentQuery}
							onAction={handleAction}
						/>
					</InstallerAppointmentCollectionDataProvider>
				</UiErrorHandler>
			</InstallationDetailLayout>
		</>
	);
}
