import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Svarstyp {{type}} hittades inte.',
			textValueNotSet: 'inte inställd',
			numericValueNotSet: 'inte inställd',
			pictureValueNotSet: 'Ingen bild tillhandahölls',
			documentValueNotSet: 'Inget dokument tillhandahållet',
			singleSelectValueNotSet: 'Inget val',
			multiSelectValueNotSet: 'Inget val',
			consentValueChecked: 'ja',
			consentValueUnchecked: 'nej'
		}
	}
};

export default literals;
