import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/shared/ui-primitive';

import {
	useTopbarCaptionModifier,
	useTopbarLinkModifier,
	useTopbarVisibilityModifier
} from '../../components/mobile-topbar-content/MobileTopbarContent.hooks';
import { useBuildPageTitle } from '../../page-title/PageTitle.hook';

export function ErrorView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('omsInstallationPartnerServiceApp.pageTitle.error'));
	// Reset topbar contents
	useTopbarLinkModifier(null);
	useTopbarCaptionModifier(null);
	useTopbarVisibilityModifier(true);

	const reloadAction: ErrorFeedbackAction = {
		label: l10n.translate('omsInstallationPartnerServiceApp.error.generic.resolveAction.retry.label'),
		onInvoke: (): void => {
			window.location.reload();
		}
	};

	return (
		<ErrorFeedback
			heading={l10n.translate('omsInstallationPartnerServiceApp.error.generic.heading')}
			message={l10n.translate('omsInstallationPartnerServiceApp.error.generic.message')}
			actions={[reloadAction]}
		/>
	);
}
