import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Något gick fel',
				message: 'Det uppstod ett fel som inte hanterades korrekt',
				resolveAction: {
					label: 'Ladda om'
				}
			},
			recoverLabel: 'Försök igen',
			fetchHeading: 'Hämtning misslyckades',
			mutateHeading: 'Uppdatering misslyckades',
			createHeading: 'Skapandet misslyckades',
			deleteHeading: 'Radering misslyckades',
			taskAssignHeading: 'Tilldelning misslyckad',
			taskCompleteHeading: 'Slutförandet misslyckades'
		}
	}
};

export default literals;
