export function TermsIt() {

	return (
		<>
			<h1>Dichiarazione</h1>
			<p>Con la presente confermo di essere pienamente competente e formato in conformit&agrave; alle leggi, ai regolamenti, alle normative e agli standard locali applicabili per eseguire il lavoro di installazione e configurazione elettrica (compresi eventuali diplomi, licenze, registrazioni o certificati necessari) e di avere l&apos;autorizzazione ad agire per conto dell&apos;impresa di installazione nominata da ABB E-mobility B.V. Conosco e comprendo nella sua interezza il presente documento e i requisiti per l&apos;installazione e la configurazione sicura delle centraline a parete ABB E-Mobility e mi impegno a rispettare tutte le leggi e le normative (locali) applicabili, lo stato dell&apos;arte, nonch&eacute; le istruzioni fornite da ABB E-Mobility B.V., in particolare in conformit&agrave; al <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">manuale d&apos;uso e di installazione.</a></p>
			<p>Si applicano le <a href="https://abb.com" target="_blank" rel="noopener noreferrer">condizioni d&apos;uso</a> relative all&apos;accesso e all&apos;utilizzo di questa applicazione.</p>
		</>
	);
}
