import { ReactNode } from 'react';

import './UploadQueue.scss';

export type UploadQueueProps = {
	children: ReactNode
};

export function UploadQueue(props: UploadQueueProps) {

	const { children } = props;

	return (
		<section className="upload-queue">
			{children}
		</section>
	);
}
