import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Type de réponse {{type}} introuvable.',
			textValueNotSet: 'non défini',
			numericValueNotSet: 'non défini',
			pictureValueNotSet: 'Aucune image fournie',
			documentValueNotSet: 'Aucun document fourni',
			singleSelectValueNotSet: 'Aucune sélection',
			multiSelectValueNotSet: 'Aucune sélection',
			consentValueChecked: 'oui',
			consentValueUnchecked: 'non'
		}
	}
};

export default literals;
