import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
	omsDataProviderLiteralsDe,
	omsDataProviderLiteralsEn,
	omsDataProviderLiteralsEs,
	omsDataProviderLiteralsFr,
	omsDataProviderLiteralsIt,
	omsDataProviderLiteralsNl,
	omsDataProviderLiteralsPt,
	omsDataProviderLiteralsSv
} from '@abb-emobility/oms/data-provider';
import { deriveInstallationPartner } from '@abb-emobility/oms/installation-partner-provider';
import {
	uiDomainLiteralsDe,
	uiDomainLiteralsEn,
	uiDomainLiteralsEs,
	uiDomainLiteralsFr,
	uiDomainLiteralsIt,
	uiDomainLiteralsNl,
	uiDomainLiteralsPt,
	uiDomainLiteralsSv
} from '@abb-emobility/oms/ui-domain';
import { EnvProvider } from '@abb-emobility/shared/environment';
import { NotFoundError } from '@abb-emobility/shared/error';
import { IdentityHandler, IdentityProvider } from '@abb-emobility/shared/identity-provider';
import { L10n, L10nProvider, Locale } from '@abb-emobility/shared/localization-provider';
import { ConsoleLogger, Logger, LoggerProvider } from '@abb-emobility/shared/logger';
import { sentryConfig, SentryLogger, TagValue } from '@abb-emobility/shared/sentry-integration';
import {
	sharedUiAuthEmitterLiteralsDe,
	sharedUiAuthEmitterLiteralsEn,
	sharedUiAuthEmitterLiteralsEs,
	sharedUiAuthEmitterLiteralsFr,
	sharedUiAuthEmitterLiteralsIt,
	sharedUiAuthEmitterLiteralsNl,
	sharedUiAuthEmitterLiteralsPt,
	sharedUiAuthEmitterLiteralsSv
} from '@abb-emobility/shared/ui-auth-emitter';
import {
	sharedUiErrorHandlerLiteralsDe,
	sharedUiErrorHandlerLiteralsEn,
	sharedUiErrorHandlerLiteralsEs,
	sharedUiErrorHandlerLiteralsFr,
	sharedUiErrorHandlerLiteralsIt,
	sharedUiErrorHandlerLiteralsNl,
	sharedUiErrorHandlerLiteralsPt,
	sharedUiErrorHandlerLiteralsSv
} from '@abb-emobility/shared/ui-error-handler';
import {
	ErrorFeedback,
	sharedUiPrimitiveLiteralsDe,
	sharedUiPrimitiveLiteralsEn,
	sharedUiPrimitiveLiteralsEs,
	sharedUiPrimitiveLiteralsFr,
	sharedUiPrimitiveLiteralsIt,
	sharedUiPrimitiveLiteralsNl,
	sharedUiPrimitiveLiteralsPt,
	sharedUiPrimitiveLiteralsSv
} from '@abb-emobility/shared/ui-primitive';
import {
	sharedUiQuestionnaireLiteralsDe,
	sharedUiQuestionnaireLiteralsEn,
	sharedUiQuestionnaireLiteralsEs,
	sharedUiQuestionnaireLiteralsFr,
	sharedUiQuestionnaireLiteralsIt,
	sharedUiQuestionnaireLiteralsNl,
	sharedUiQuestionnaireLiteralsPt,
	sharedUiQuestionnaireLiteralsSv
} from '@abb-emobility/shared/ui-questionnaire';
import { WebsocketInstrument } from '@abb-emobility/shared/websocket';

import { AppFactory } from './app/AppFactory';
import { environment } from './environments/environment';
import { default as appLiteralsDe } from './l10n/literals-de';
import { default as appLiteralsEn } from './l10n/literals-en';
import { default as appLiteralsEs } from './l10n/literals-es';
import { default as appLiteralsFr } from './l10n/literals-fr';
import { default as appLiteralsIt } from './l10n/literals-it';
import { default as appLiteralsNl } from './l10n/literals-nl';
import { default as appLiteralsPt } from './l10n/literals-pt';
import { default as appLiteralsSv } from './l10n/literals-sv';
import { store } from './store/store';

// Set up locales to be used by L10n
const locales = [
	new Locale('de', [
		sharedUiAuthEmitterLiteralsDe,
		appLiteralsDe,
		omsDataProviderLiteralsDe,
		sharedUiPrimitiveLiteralsDe,
		uiDomainLiteralsDe,
		sharedUiQuestionnaireLiteralsDe,
		sharedUiErrorHandlerLiteralsDe
	]),
	new Locale('en', [
		sharedUiAuthEmitterLiteralsEn,
		appLiteralsEn,
		omsDataProviderLiteralsEn,
		sharedUiPrimitiveLiteralsEn,
		uiDomainLiteralsEn,
		sharedUiQuestionnaireLiteralsEn,
		sharedUiErrorHandlerLiteralsEn
	]),
	new Locale('es', [
		sharedUiAuthEmitterLiteralsEs,
		appLiteralsEs,
		omsDataProviderLiteralsEs,
		sharedUiPrimitiveLiteralsEs,
		uiDomainLiteralsEs,
		sharedUiQuestionnaireLiteralsEs,
		sharedUiErrorHandlerLiteralsEs
	]),
	new Locale('fr', [
		sharedUiAuthEmitterLiteralsFr,
		appLiteralsFr,
		omsDataProviderLiteralsFr,
		sharedUiPrimitiveLiteralsFr,
		uiDomainLiteralsFr,
		sharedUiQuestionnaireLiteralsFr,
		sharedUiErrorHandlerLiteralsFr
	]),
	new Locale('it', [
		sharedUiAuthEmitterLiteralsIt,
		appLiteralsIt,
		omsDataProviderLiteralsIt,
		sharedUiPrimitiveLiteralsIt,
		uiDomainLiteralsIt,
		sharedUiQuestionnaireLiteralsIt,
		sharedUiErrorHandlerLiteralsIt
	]),
	new Locale('nl', [
		sharedUiAuthEmitterLiteralsNl,
		appLiteralsNl,
		omsDataProviderLiteralsNl,
		sharedUiPrimitiveLiteralsNl,
		uiDomainLiteralsNl,
		sharedUiQuestionnaireLiteralsNl,
		sharedUiErrorHandlerLiteralsNl
	]),
	new Locale('pt', [
		sharedUiAuthEmitterLiteralsPt,
		appLiteralsPt,
		omsDataProviderLiteralsPt,
		sharedUiPrimitiveLiteralsPt,
		uiDomainLiteralsPt,
		sharedUiQuestionnaireLiteralsPt,
		sharedUiErrorHandlerLiteralsPt
	]),
	new Locale('sv', [
		sharedUiAuthEmitterLiteralsSv,
		sharedUiQuestionnaireLiteralsSv,
		appLiteralsSv,
		omsDataProviderLiteralsSv,
		sharedUiPrimitiveLiteralsSv,
		uiDomainLiteralsSv,
		sharedUiQuestionnaireLiteralsSv,
		sharedUiErrorHandlerLiteralsSv
	])
];

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

try {
	const installationPartner = deriveInstallationPartner(environment);
	const oauthRealm = installationPartner.getOrThrow(new NotFoundError('Installation partner not found')).realm;
	const installationPartnerPath = installationPartner.getOrThrow(new NotFoundError('Installation partner not found')).path;
	const installationPartnerClientId = installationPartner.getOrThrow(new NotFoundError('Installation partner not found')).clientId;
	environment.oauthRealm = oauthRealm;
	environment.installationPartnerPath = installationPartnerPath;
	environment.oauthClientId = installationPartnerClientId;

	// Set up logger instances to be used by ErrorHandler components
	const loggers: Array<Logger> = [
		new ConsoleLogger(true, undefined, undefined)
	];
	if (process.env['NX_SENTRY_ENABLED'] === 'true') {
		loggers.push(new SentryLogger(
			sentryConfig(),
			true,
			(error) => !(error instanceof NotFoundError),
			undefined,
			() => IdentityHandler.get(oauthRealm).getIdentity().getOrUndefined()?.userId,
			() => {
				return new Map<string, TagValue>([
					['client.locale', L10n.selectedLocale()],
					['stomp.enabled', process.env['NX_STOMP_ENABLED'] === 'true'],
					['stomp.open', WebsocketInstrument.get().isOpened()],
					['installationPartner.realm', oauthRealm],
					['installationPartner.path', installationPartnerPath],
					['installationPartner.clientId', installationPartnerClientId]
				]);
			}
		));
	}

	root.render(
		<StrictMode>
			<LoggerProvider loggers={loggers}>
				<BrowserRouter>
					<Provider store={store}>
						<EnvProvider env={environment}>
							<L10nProvider
								locales={locales}
								fallbackLocaleIdentifier={'en'}
							>
								<IdentityProvider identityScope={oauthRealm}>
									<AppFactory />
								</IdentityProvider>
							</L10nProvider>
						</EnvProvider>
					</Provider>
				</BrowserRouter>
			</LoggerProvider>
		</StrictMode>
	);

} catch (e) {
	root.render(
		<ErrorFeedback
			heading="Error"
			message="Please start the application using your specific URL that contains your organization name."
		/>
	);
}
