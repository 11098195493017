import { ReactNode } from 'react';

import './Specification.scss';

export type SpecificationProps = {
	children: ReactNode
};

export function Specification(props: SpecificationProps) {
	const { children } = props;

	return (
		<ul className="specification">
			{children}
		</ul>
	);
}
