import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Der Antworttyp {{type}} ist unbekannt.',
			textValueNotSet: 'keine Angabe',
			numericValueNotSet: 'keine Angabe',
			pictureValueNotSet: 'Kein Bild verfügbar',
			documentValueNotSet: 'Kein Dokument verfügbar',
			singleSelectValueNotSet: 'Keine Auswahl',
			multiSelectValueNotSet: 'Keine Auswahl',
			consentValueChecked: 'ja',
			consentValueUnchecked: 'nein'
		}
	}
};

export default literals;
