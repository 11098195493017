import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Answer type {{type}} was not found.',
			textValueNotSet: 'not set',
			numericValueNotSet: 'not set',
			pictureValueNotSet: 'No picture provided',
			documentValueNotSet: 'No document provided',
			singleSelectValueNotSet: 'No selection',
			multiSelectValueNotSet: 'No selection',
			consentValueChecked: 'yes',
			consentValueUnchecked: 'no'
		}
	}
};

export default literals;
