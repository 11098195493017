import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './CardLayout.scss';

export type CardLayoutProps = {
	children: ReactNode,
	wide?: boolean
};

export function Cardlayout(props: CardLayoutProps) {
	const { children, wide } = props;

	const cardLayoutClassMap = {
		'card-layout': true,
		'card-layout--wide': wide
	};

	return (
		<article className={buildCssClassStringFromClassMap(cardLayoutClassMap)}>
			{children}
		</article>
	);
}
