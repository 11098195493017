import { useNavigate } from 'react-router-dom';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/shared/ui-primitive';

import {
	useTopbarCaptionModifier,
	useTopbarLinkModifier,
	useTopbarVisibilityModifier
} from '../../components/mobile-topbar-content/MobileTopbarContent.hooks';
import { useBuildPageTitle } from '../../page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function NotFoundView() {

	const l10n = useL10n();
	const navigate = useNavigate();
	const { createRouteUrl } = useRouteUrl();

	usePageTitle(useBuildPageTitle('omsInstallationPartnerServiceApp.pageTitle.notFound'));
	// Reset topbar contents
	useTopbarLinkModifier(null);
	useTopbarCaptionModifier(null);
	useTopbarVisibilityModifier(true);

	const reloadAction: ErrorFeedbackAction = {
		label: l10n.translate('omsInstallationPartnerServiceApp.error.notFound.resolveAction.label'),
		onInvoke: (): void => {
			navigate(createRouteUrl(RoutePath.ROOT));
		}
	};

	return (
		<ErrorFeedback
			heading={l10n.translate('omsInstallationPartnerServiceApp.error.notFound.heading')}
			message={l10n.translate('omsInstallationPartnerServiceApp.error.notFound.message')}
			actions={[reloadAction]}
		/>
	);
}
