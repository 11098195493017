import React from 'react';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SingleValueFilterComparator } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { getToday, Optional } from '@abb-emobility/shared/util';

import { installerAppointmentCollectionData } from './InstallerAppointmentCollectionData';
import {
	installerAppointmentCollectionStoreAccessors,
	InstallerAppointmentCollectionStore,
	installerAppointmentCollectionStoreName
} from './InstallerAppointmentCollectionSlice';

type InstallerAppointmentCollectionDataProviderProps = Omit<CollectionDataProviderProps<InstallerAppointmentModel>, 'sortCriteria' | 'filterCriteria'>;

/**
 * Uses defaultSortCriteria if they are not provided.
 */
export function InstallerAppointmentCollectionDataProvider(props: InstallerAppointmentCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_WALLBOX_INSTALLATION_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<InstallerAppointmentModel, InstallerAppointmentCollectionStore>(
		installerAppointmentCollectionStoreName,
		installerAppointmentCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('omsDataProvider.error.installerAppointmentCollectionFetchMessage')));

	const filterCriteria: FilterCriteria<InstallerAppointmentModel> = [
		{
			id: 'periodStartsAt',
			criteria: [
				{
					property: 'periodStartsAt',
					comparator: SingleValueFilterComparator.GREATER_THAN_EQUAL,
					value: createTimestampDtoFromDate(getToday())
				}
			]
		}
	];
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(undefined, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<installerAppointmentCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</installerAppointmentCollectionData.Provider>
	);

}
