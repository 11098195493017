import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Un problème est survenu',
				message: 'Une erreur s’est produite et n’a pas été gérée correctement',
				resolveAction: {
					label: 'Recharger'
				}
			},
			recoverLabel: 'Essayez à nouveau',
			fetchHeading: 'Échec de la récupération',
			mutateHeading: 'Échec de la mise à jour',
			createHeading: 'Échec de la création',
			deleteHeading: 'Échec de la suppression',
			taskAssignHeading: 'Échec de l’attribution',
			taskCompleteHeading: 'Échec de l’achèvement'
		}
	}
};

export default literals;
