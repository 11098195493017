export function TermsNl() {

	return (
		<>
			<h1>Verklaring:</h1>
			<p>Hierbij bevestig ik dat ik volledig bevoegd en gekwalificeerd ben in overeenstemming met de toepasselijke wet- en regelgeving, lokale wetgeving en normen om de elektrische installatie- en configuratiewerkzaamheden uit te voeren (inclusief alle benodigde diploma&apos;s, certificaten, licenties of registratie) en de bevoegdheid heb om te handelen voor en namens het door ABB E-mobility BV aangewezen installatiebedrijf. Ik weet en begrijp dit en de vereisten voor de veilige installatie en configuratie van ABB E-Mobility Wallboxen volledig en zal alle toepasselijke (lokale) wet- en regelgeving, de stand van de techniek, alsmede alle door ABB E-Mobility B.V. verstrekte instructies in acht nemen, in het bijzonder in overeenstemming met de <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">bedienings- en installatiehandleiding.</a></p>
			<p>De <a href="https://abb.com" target="_blank" rel="noopener noreferrer">gebruikersvoorwaarden</a> met betrekking tot toegang tot en gebruik van deze applicatie zijn hierop van toepassing.</p>
		</>
	);
}
