export type InstallationPartnerRealmConfig = {
	path: string,
	realm: string,
	clientId: string
};

export const installationPartnerRealmEnvironment: Array<InstallationPartnerRealmConfig> = [
	{ path: 'bp-proenergy-de', realm: 'installation-partner-bp-proenergy-de', clientId: 'ip-bp-proenergy-de-app' },
	{ path: 'iberdrola-fr', realm: 'installation-partner-iberdrola-fr', clientId: 'ip-iberdrola-fr-app' },
	{ path: 'gia-at', realm: 'installation-partner-gia-at', clientId: 'ip-gia-at-app' },
	{ path: 'batenburg-nl', realm: 'installation-partner-batenburg-nl', clientId: 'ip-batenburg-nl-app' }
];
