import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'ABB wallbox installation service',
		appointmentCollection: {
			heading: 'Appointments',
			header: {
				today: 'Today',
				upcoming: 'Upcoming'
			},
			emptyState: {
				heading: 'No appointments',
				message: 'Could not find any appointments.'
			}
		},
		installationCompletion: {
			done: 'Installation completed!',
			upload: {
				heading: 'Provide Documents'
			},
			confirmation: {
				heading: 'Installed Wallbox',
				text: 'Hereby I confirm the proper and professional installation and commissioning of the delivered wallbox with the following serial number {{serialNumber}}.',
				throttle: 'Hereby I grant that the power consumption of the wallbox was reduced according to the grid operator requirements.'
			},
			inspection: {
				heading: 'Inspection',
				customerPresent: 'Inspection with the customer',
				customerNotPresent: 'Customer is not present'
			},
			acceptance: {
				heading: 'Acceptance'
			},
			signature: {
				clear: 'Clear',
				close: 'Close',
				done: 'Done'
			},
			action: {
				signature: 'Customer Signature',
				complete: 'Complete Installation'
			}
		},
		installationDetail: {
			action: 'Complete installation',
			heading: 'Installation',
			appointmentCollection: {
				heading: 'Trades and appointments',
				item: {
					installationPeriod: {
						preparation: 'Installation preparation',
						beginning: 'Beginning of installation',
						permanent: 'Permanent',
						end: 'End of installation',
						followUp: 'Installation follow up'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Undetermined',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Appointments',
			detail: 'Details',
			installationCompletion: 'Installation Completion',
			error: 'Error',
			notFound: 'Not Found'
		},
		formatter: {
			dayNames: [
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday'
			],
			fuzzyDaynames: {
				today: 'today',
				tomorrow: 'tomorrow',
				yesterday: 'yesterday'
			}
		},
		navigation: {
			tasks: 'Tasks',
			logout: 'Logout'
		},
		breadcrumbs: {
			orders: 'Order overview'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'back',
				captionPrefix: ' at '
			},
			installationCompletion: {
				backLinkLabel: 'back',
				captionPrefix: ' at '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Fetching the appointments failed',
				message: 'While loading the requested information an error occurred.'
			},
			installationCompletion: {
				heading: 'Completing the installation failed',
				message: 'While completing the task an error occurred.',
				resolveAction: {
					label: 'Back To Detail Page'
				},
				notAllowed: 'The installation completion is not allowed.'
			},
			installerAppointmentNotFound: 'Installer Appointment was not found',
			orderNotFound: 'Order not found',
			installationDetail: {
				heading: 'Fetching the installation data failed',
				message: 'While loading the requested information an error occurred.'
			},
			validation: {
				heading: 'Invalid data',
				message: 'The data you sent are not valid.'
			},
			generic: {
				heading: 'Something went wrong',
				message: 'An error occurred that was not handled during the render',
				resolveAction: {
					retry: {
						label: 'Reload'
					}
				}
			},
			offline: {
				heading: 'Network connection unavailable',
				message: 'Please connect to a network to use the application.'
			},
			notFound: {
				heading: 'Not found',
				message: 'The requested ressource was not found',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			task: {
				notFound: {
					message: 'Task not found'
				},
				complete: {
					message: 'While completing the task an error occurred.'
				}
			}
		}
	}
};

export default literals;
