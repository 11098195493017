import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'Service d’installation de Wallbox ABB',
		appointmentCollection: {
			heading: 'Rendez-vous',
			header: {
				today: 'Aujourd’hui',
				upcoming: 'À venir'
			},
			emptyState: {
				heading: 'Aucun rendez-vous',
				message: 'Impossible de trouver un rendez-vous.'
			}
		},
		installationCompletion: {
			done: 'Installation terminée !',
			upload: {
				heading: 'Fournir des documents'
			},
			confirmation: {
				heading: 'Wallbox installée',
				text: 'Je confirme par la présente l’installation et la mise en service correctes et professionnelles de la Wallbox livrée comportant le numéro de série suivant {{numéro de série}}.',
				throttle: 'Je reconnais par la présente que la consommation électrique de la Wallbox a été réduite conformément aux exigences de l’opérateur réseau.'
			},
			inspection: {
				heading: 'Inspection',
				customerPresent: 'Inspection avec le client',
				customerNotPresent: 'Le client n’est pas présent'
			},
			acceptance: {
				heading: 'Acceptation'
			},
			signature: {
				clear: 'Effacer',
				close: 'Fermer',
				done: 'Terminé'
			},
			action: {
				signature: 'Signature du client',
				complete: 'Installation terminée'
			}
		},
		installationDetail: {
			action: 'Installation terminée',
			heading: 'Installation',
			appointmentCollection: {
				heading: 'Métiers et nominations',
				item: {
					installationPeriod: {
						preparation: 'Préparation de l’installation',
						beginning: 'Début de l’installation',
						permanent: 'Permanent',
						end: 'Fin de l’installation',
						followUp: 'Suivi de l’installation'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Indéterminé',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Rendez-vous',
			detail: 'Détails',
			installationCompletion: 'Achèvement de l’installation',
			error: 'Erreur',
			notFound: 'Introuvable'
		},
		formatter: {
			dayNames: [
				'Dimanche',
				'Lundi',
				'Mardi',
				'Mercredi',
				'Jeudi',
				'Vendredi',
				'Samedi'
			],
			fuzzyDaynames: {
				today: 'aujourd’hui',
				tomorrow: 'demain',
				yesterday: 'hier'
			}
		},
		navigation: {
			tasks: 'Tâches',
			logout: 'Déconnexion'
		},
		breadcrumbs: {
			orders: 'Aperçu des commandes'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'retour',
				captionPrefix: ' à '
			},
			installationCompletion: {
				backLinkLabel: 'retour',
				captionPrefix: ' à '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Échec de récupération des rendez-vous',
				message: 'Une erreur s’est produite lors du chargement des informations demandées.'
			},
			installationCompletion: {
				heading: 'Échec de l’achèvement de l’installation',
				message: 'Une erreur s’est produite lors de l’exécution de la tâche.',
				resolveAction: {
					label: 'Retour à la page de détail'
				},
				notAllowed: 'La fin de l’installation n’est pas autorisée.'
			},
			installerAppointmentNotFound: 'Rendez-vous de l’installateur introuvable',
			orderNotFound: 'Commande introuvable',
			installationDetail: {
				heading: 'Échec de récupération des données d’installation',
				message: 'Une erreur s’est produite lors du chargement des informations demandées.'
			},
			validation: {
				heading: 'Donnée invalide',
				message: 'Les données que vous avez envoyées ne sont pas valides.'
			},
			generic: {
				heading: 'Un problème est survenu',
				message: 'Une erreur s’est produite et n’a pas été gérée pendant le rendu',
				resolveAction: {
					retry: {
						label: 'Recharger'
					}
				}
			},
			offline: {
				heading: 'Connexion réseau indisponible',
				message: 'Veuillez vous connecter à un réseau pour utiliser l’application.'
			},
			notFound: {
				heading: 'Introuvable',
				message: 'Ressource demandée introuvable',
				resolveAction: {
					label: 'Tableau de bord'
				}
			},
			task: {
				notFound: {
					message: 'Tâche introuvable'
				},
				complete: {
					message: 'Une erreur s’est produite lors de l’exécution de la tâche.'
				}
			}
		}
	}
};

export default literals;
