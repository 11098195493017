import React from 'react';

import './AppLayoutMobileTopbar.scss';

import { AppLayoutNavigationProps } from '../../app-layout/app-layout-navigation/AppLayoutNavigation';

export function AppLayoutMobileTopbar(props: AppLayoutNavigationProps) {
	const { children } = props;

	return (
		<header className="app-layout-mobile-topbar">
			{children}
		</header>
	);
}
