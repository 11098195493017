import { createTimestampFromDate } from '@abb-emobility/shared/domain-model-foundation';

import { CalendarDayViewItemType } from './MobileTopbarCalendar.types';

export const createCalendarDayList = (begin: Date, end: Date): Array<CalendarDayViewItemType> => {
	const list: Array<CalendarDayViewItemType> = [];
	const day = new Date(begin);
	while (day.getTime() < end.getTime()) {
		list.push({
			startTimestamp: createTimestampFromDate(day),
			firstAppointment: null
		});
		day.setDate(day.getDate() + 1);
	}
	return list;
};
