import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { Tag } from '../tag/Tag';

import './InformationIconValue.scss';

export type InformationIconValueProps = {
	icon: IconIdentifier,
	value?: string,
	weekday?: string,
	label?: string
};

export function InformationIconValue(props: InformationIconValueProps) {
	const { icon, value, weekday, label } = props;

	const tag = label ?
		<Tag label={label} /> : null;

	const information =
		weekday ?
			<article className="information-icon-value__date-info">
				<span className="information-icon-value__date-info__weekday">{weekday}</span>
				<span className="information-icon-value__date-info__value">{value}</span>
				{tag}
			</article> :
			<span className="information-icon-value__value">{value}</span>;

	return (
		<article className={"information-icon-value"}>
			<Icon name={icon} />
			{information}
		</article>
	);
}
