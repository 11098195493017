import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Installations-Check',
				installationPartnerArrangeAppointment: 'Installationstermin',
				installationPartnerExecuteRemotePreCheck: 'Finaler Check',
				installationPartnerRegisterAtGridOperator: 'Registrierung Netzbetreiber',
				installationPartnerProvideGridOperatorDecision: 'Rückmeldung Netzbetreiber',
				installationPartnerRevokeGridOperatorRegistration: 'Stornierung Netzbetreiber',
				installationPartnerProvideQuotation: 'Angebot',
				installationPartnerCompleteInstallation: 'Installationsabschluss'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Gesamtkosten (netto)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Im Plan',
				delayed: 'Verspätet',
				cancelPending: 'Wird storniert',
				cancelled: 'Storniert',
				done: 'Abgeschlossen'
			},
			installationType: {
				none: 'Noch nicht ermittelt',
				undetermined: 'Noch nicht ermittelt',
				cancelled: 'Storniert',
				standard: 'Standard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Termine absagen'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Achtung',
				message: 'Die Leistungsaufnahme der Wallbox muss gemäß der Vorgaben des Netzbetreibers angepasst werden.'
			},
			informationList: {
				heading: 'Informationen',
				emptyState: {
					heading: 'Noch keine Bestellinformationen vorhanden',
					message: 'Im weiteren Verlauf der Bestellung finden Sie hier alle relevanten Informationen zu der Bestellung.'
				},
				caption: {
					basicAnswers: 'Installations-Check',
					gridOperatorRegistration: 'Registrierung Netzbetreiber',
					gridOperatorDecision: 'Rückmeldung Netzbetreiber',
					preCheck: 'Finaler Check',
					offer: 'Angebot',
					approval: 'Abnahme'
				},
				precheck: {
					heading: 'Finaler Check',
					effort: 'Ergebnisse: Gewerke und Aufwände',
					questionnaire: {
						heading: 'Antworten des Kunden'
					},
					installationType: {
						undetermined: 'Noch nicht ermittelt',
						standard: 'Standard Installation',
						delta: 'Delta Installation'
					},
					decision: {
						accepted: 'Informationen ausreichend',
						more: 'Weitere Informationen benötigt',
						denied: 'Installation nicht möglich'
					},
					notes: 'Anmerkungen',
					hours: 'Stunden'
				},
				decision: {
					heading: 'Rückmeldung Netzbetreiber',
					decision: {
						pending: 'Die Anfrage ist unbeantwortet',
						accepted: 'Der Netzbetreiber hat der Installation zugestimmt.',
						denied: 'Der Netzbetreiber hat der Installation nicht zugestimmt.'
					}
				},
				registration: {
					heading: 'Registrierung Netzbetreiber',
					documents: 'Zusätzliche Dokumente',
					gridOperator: 'Netzbetreiber'
				},
				quotation: {
					heading: 'Angebot',
					conditions: 'Angebotsbedingungen'
				},
				complete: {
					success: {
						heading: 'Installation erfolgreich'
					},
					notes: 'Anmerkungen',
					images: 'Bilder der Installation',
					documents: 'Dokumente',
					signature: 'Kundenunterschrift'
				}
			}
		},
		orderLineItems: {
			heading: 'Bestellung',
			head: {
				title: 'Position',
				description: 'Beschreibung',
				productCode: 'ABB-Produktcode'
			},
			serial: 'Wallbox Seriennummer',
			shippingStatus: {
				label: 'Versandstatus Sendung #{{shipmentNumber}}',
				pending: 'Geplant',
				delivered: 'Zugestellt',
				expected: 'Zustellung am '
			},
			empty: 'Keine Bestellpositionen verfügbar'
		}
	}
};

export default literals;
