import { AnchorHTMLAttributes, ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './LinkIcon.scss';

export type LinkIconProps = {
	linkUrl: string,
	children: ReactNode
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export function LinkIcon(props: LinkIconProps) {
	const { linkUrl, children, ...rest } = props;

	const classMap = {
		'link-icon': true
	};

	return (
		<a
			className={buildCssClassStringFromClassMap(classMap)}
			href={linkUrl}
			{...rest}
		>
			{children}
		</a>
	);
}
