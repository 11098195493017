import React, { ChangeEvent, DragEvent, InputHTMLAttributes, ReactNode, useEffect, useState } from 'react';

import { createTimestampFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { FileSizeConversion, formatFileSize } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap, numberFromString, Optional } from '@abb-emobility/shared/util';

import { FileListEmpty } from '../file-list-empty/FileListEmpty';
import { Hint } from '../hint/Hint';
import { HintLevel } from '../hint/Hint.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { UploadQueue } from '../upload-queue/UploadQueue';
import { UploadQueueItem } from '../upload-queue-item/UploadQueueItem';

import './Upload.scss';

export type UploadProps = {
	disabled?: boolean,
	maxFileSize?: number,
	files?: ReadonlyArray<File>,
	onChange?: (selectedFiles: ReadonlyArray<File>) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'multiple' | 'disabled'>;

export function Upload(props: UploadProps) {

	const {
		disabled = false,
		maxFileSize = Number.MAX_SAFE_INTEGER,
		onChange,
		...rest
	} = props;

	const initialFiles = props.files ?? [];

	const l10n = useL10n();

	const [files, setFiles] = useState<Map<string, File>>(new Map());
	const [focus, setFocus] = useState<boolean>(false);

	useEffect(() => {
		const initialFileMap = new Map<string, File>();
		for (const initialFile of initialFiles) {
			initialFileMap.set(_createFileId(initialFile), initialFile);
		}
		setFiles(initialFileMap);
	}, [initialFiles]);

	const handleSelect = (event: ChangeEvent<HTMLInputElement>): void => {
		const selectedFiles = event.target.files;
		if (selectedFiles === null) {
			return;
		}
		_appendFiles(Array.from(selectedFiles));
	};

	const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setFocus(true);
	};

	const handleDragLeave = (event: DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setFocus(false);
	};

	const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		let selectedFiles: Array<File>;
		if (event.dataTransfer.items) {
			selectedFiles = Array.from(event.dataTransfer.items)
				.filter((item) => {
					return item.kind === 'file';
				})
				.map((item) => {
					return item.getAsFile();
				})
				.filter((file): file is File => {
					return file !== null;
				});
		} else {
			selectedFiles = Array.from(event.dataTransfer.files);
		}
		_appendFiles(selectedFiles as Array<File>);
		setFocus(false);
	};

	const handleRemove = (fileId: string): void => {
		const updatedFiles = new Map(files);
		updatedFiles.delete(fileId);
		setFiles(updatedFiles);
		handleChange(updatedFiles);
	};

	const handleChange = (updatedFiles: Map<string, File>): void => {
		const files: Array<File> = [];
		let cumulatedFileSize = 0;
		for (const file of Array.from(updatedFiles.values())) {
			cumulatedFileSize += file.size;
			if (cumulatedFileSize > maxFileSize) {
				break;
			}
			files.push(file);
		}
		if (onChange !== undefined) {
			onChange(files);
		}
	};

	const handleInputFocus = () => {
		setFocus(true);
	};

	const handleInputBlur = () => {
		setFocus(false);
	};

	const _appendFiles = (selectedFiles: Array<File>): void => {
		if (selectedFiles.length === 0) {
			return;
		}
		const updatedFiles = new Map(files);
		for (const selectedFile of selectedFiles) {
			updatedFiles.set(_createFileId(selectedFile), selectedFile);
		}
		setFiles(updatedFiles);
		handleChange(updatedFiles);
	};

	const _createFileId = (file: File): string => {
		return file.name + '-' + file.size.toString() + '-' + file.type + file.lastModified.toString();
	};

	const uploadLabelClassmap = {
		'upload__label': true,
		'upload__label--disabled': disabled,
		'upload__label--focus': focus
	};

	const renderQueue = (): ReactNode => {
		if (files.size === 0) {
			return (<FileListEmpty message={l10n.translate('sharedUiPrimitive.upload.empty.message')} />);
		}
		let cumulatedFileSize = 0;
		const queue: Array<ReactNode> = [];
		for (const [fileId, file] of files.entries()) {
			cumulatedFileSize += file.size;
			queue.push(
				<UploadQueueItem
					fileName={file.name}
					fileSize={file.size}
					fileType={file.type}
					fileDate={createTimestampFromDate(new Date(file.lastModified))}
					errorneous={cumulatedFileSize > maxFileSize}
					onRemove={() => {
						handleRemove(fileId);
					}}
					key={fileId}
				/>
			);
		}
		return queue;
	};

	const renderNotification = (): ReactNode => {
		const totalFileSize = Array.from(files.values()).reduce<number>((totalSize, file) => {
			return totalSize + file.size;
		}, 0);
		if (totalFileSize > maxFileSize) {
			return (
				<Hint
					heading={l10n.translate('sharedUiPrimitive.upload.fileSizeWarning')}
					level={HintLevel.WARNING}
				/>
			);
		}
		return null;
	};

	const renderFileSelectionHint = (): string => {
		const maxFileSize = numberFromString(new Optional(process.env['NX_MAX_UPLOAD_FILE_SIZE']).get(), Number.MAX_SAFE_INTEGER);
		const maxFileSizeFormatted = formatFileSize(maxFileSize, FileSizeConversion.BINARY_IEC, 0);
		return l10n.translate('sharedUiPrimitive.upload.hint', new Map([['maxFilesize', maxFileSizeFormatted]]));
	};

	return (
		<section className="upload">
			{renderNotification()}
			<UploadQueue>
				{renderQueue()}
			</UploadQueue>
			<label
				className={buildCssClassStringFromClassMap(uploadLabelClassmap)}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
			>
				<span className="upload__label__icon">
					<Icon name={IconIdentifier.UPLOAD_SIMPLE} />
				</span>
				<div className="upload__label__info">
					<span className="upload__label__info__cta">{l10n.translate('sharedUiPrimitive.upload.dropMessage')}</span>
					<div>{renderFileSelectionHint()}</div>
				</div>
				<span className="upload__label__link">{l10n.translate('sharedUiPrimitive.upload.selectButtonLabel')}</span>
				<input
					className="upload__label__input"
					type="file"
					multiple={true}
					onChange={handleSelect}
					disabled={disabled}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					{...rest}
				/>
			</label>
		</section>
	);

}
