import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'Il tipo di risposta {{type}} non è stato trovato.',
			textValueNotSet: 'non impostato',
			numericValueNotSet: 'non impostato',
			pictureValueNotSet: 'Nessuna immagine fornita',
			documentValueNotSet: 'Nessun documento fornito',
			singleSelectValueNotSet: 'Nessuna selezione',
			multiSelectValueNotSet: 'Nessuna selezione',
			consentValueChecked: 'Sì',
			consentValueUnchecked: 'No'
		}
	}
};

export default literals;
