import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './CardHeader.scss';

export type CardHeaderProps = {
	heading: string,
	wide?: boolean
};

export function CardHeader(props: CardHeaderProps) {
	const { heading, wide } = props;

	const cardHeaderClassMap = {
		'card-header': true,
		'card-header--wide': wide
	};

	return (
		<header className={buildCssClassStringFromClassMap(cardHeaderClassMap)}>
			<h1 className="card-header__heading">{heading}</h1>
		</header>
	);
}
