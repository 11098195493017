import { withProfiler } from '@sentry/react';
import React, { ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { ErrorHandler, AppError } from '@abb-emobility/shared/error';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { KeycloakApiClientFactory, TokenResponseModel } from '@abb-emobility/shared/keycloak-integration';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useNetworkAvailable } from '@abb-emobility/shared/network';
import { OauthEmitterFactory } from '@abb-emobility/shared/ui-auth-emitter';
import { AcceptanceInterceptor, ErrorFeedback, ScrollToTop } from '@abb-emobility/shared/ui-primitive';
import { Optional } from '@abb-emobility/shared/util';

import { AppContainer } from './components/app-container/AppContainer';
import { ErrorView } from './views/error-view/ErrorView';
import { TermsDe } from '../l10n/terms/TermsDe';
import { TermsEn } from '../l10n/terms/TermsEn';
import { TermsEs } from '../l10n/terms/TermsEs';
import { TermsFr } from '../l10n/terms/TermsFr';
import { TermsIt } from '../l10n/terms/TermsIt';
import { TermsNl } from '../l10n/terms/TermsNl';
import { TermsPt } from '../l10n/terms/TermsPt';
import { TermsSv } from '../l10n/terms/TermsSv';
import { UNAUTHENTICATE_SIGNAL } from '../store/store';

export type AdditionalTokenResponse = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_id?: ModelPrimaryKey,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	preferred_username?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_fullname?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_mail_address?: string
};

export function App() {

	const env = useEnv();
	const l10n = useL10n();
	const dispatch = useDispatch();
	const identity = useIdentity();
	const networkStatus = useNetworkAvailable();

	if (!networkStatus) {
		return (
			<ErrorFeedback
				heading={l10n.translate('omsInstallationPartnerServiceApp.error.offline.heading')}
				message={l10n.translate('omsInstallationPartnerServiceApp.error.offline.message')}
			/>
		);
	}

	const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));

	const handleReauthenticate = () => {
		identity.unsetIdentity();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		identity.unsetIdentity();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		try {
			const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
				.getOrThrow(new AppError('Oauth base URL unavailable'));
			KeycloakApiClientFactory.create().logout(oauthBaseUrl, oauthRealm);
		} catch (e) {
			console.warn(e);
		}
	};

	const handleTokenReceived = (oauthTokenResponse: TokenResponseModel & AdditionalTokenResponse) => {
		identity.setIdentity({
			userId: oauthTokenResponse.user_id,
			userName: oauthTokenResponse.preferred_username,
			fullname: oauthTokenResponse.user_fullname,
			mailAddress: oauthTokenResponse.user_mail_address,
			candidateGroups: []
		});
	};

	const renderAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OauthEmitterFactory<AdditionalTokenResponse> onTokenReceived={handleTokenReceived} />);
	};

	const renderTerms = (): ReactNode => {
		switch (l10n.language) {
			case 'de':
				return (<TermsDe />);
			case 'es':
				return (<TermsEs />);
			case 'fr':
				return (<TermsFr />);
			case 'it':
				return (<TermsIt />);
			case 'nl':
				return (<TermsNl />);
			case 'pt':
				return (<TermsPt />);
			case 'sv':
				return (<TermsSv />);
			default:
				return (<TermsEn />);
		}
	};

	return (
		<ErrorHandler errorComponent={ErrorView}>
			<AuthProvider
				interceptingComponent={renderAuthEmitter}
				onReauthenticate={handleReauthenticate}
				onUnauthenticate={handleUnauthenticate}
				authScope={oauthRealm}
			>
				<AcceptanceInterceptor localStorageKey="installer-terms-v1" renderTerms={renderTerms}>
					<AppContainer />
				</AcceptanceInterceptor>
			</AuthProvider>
			<ScrollToTop />
		</ErrorHandler>
	);
}

export const AppWithSentryProfiler = withProfiler(App);
