import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'ABB Wallbox Installation Service',
		appointmentCollection: {
			heading: 'Termine',
			header: {
				today: 'Heute',
				upcoming: 'Kommend'
			},
			emptyState: {
				heading: 'Keine Termine',
				message: 'Es konnten keine Termine gefunden werden.'
			}
		},
		installationCompletion: {
			done: 'Installation abgeschlossen!',
			upload: {
				heading: 'Dokumente anfügen'
			},
			confirmation: {
				heading: 'Installierte Wallbox',
				text: 'Hiermit bestätige ich die ordnungsgemäße und fachgerechte Installation und Inbetriebnahme der gelieferten Wallbox mit der folgenden Seriennummer {{serialNumber}}.',
				throttle: 'Entsprechend der Anforderungen des Netzbetreibers wurde die Lastaufnahme der Wallbox reduziert.'
			},
			inspection: {
				heading: 'Abnahme',
				customerPresent: 'Abnahme mit dem Kunden',
				customerNotPresent: 'Der Kunde ist nicht vor Ort'
			},
			acceptance: {
				heading: 'Abnahme'
			},
			signature: {
				clear: 'Zurücksetzen',
				close: 'Schließen',
				done: 'Erledigt'
			},
			action: {
				signature: 'Kundenunterschrift',
				complete: 'Installation abschließen'
			}
		},
		installationDetail: {
			action: 'Installation abschließen',
			heading: 'Installation',
			appointmentCollection: {
				heading: 'Gewerke und Termine',
				item: {
					installationPeriod: {
						preparation: 'Installationsvorbereitung',
						beginning: 'Installationsbeginn',
						permanent: 'Permanent',
						end: 'Installationsende',
						followUp: 'Installationsnachbereitung'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Noch nicht ermittelt',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Termine',
			detail: 'Details',
			installationCompletion: 'Installationsabschluss',
			error: 'Fehler',
			notFound: 'Nicht gefunden'
		},
		formatter: {
			dayNames: [
				'Sonntag',
				'Montag',
				'Dienstag',
				'Mittwoch',
				'Donnerstag',
				'Freitag',
				'Samstag'
			],
			fuzzyDaynames: {
				today: 'Heute',
				tomorrow: 'Morgen',
				yesterday: 'Gestern'
			}
		},
		navigation: {
			tasks: 'Aufgaben',
			logout: 'Abmelden'
		},
		breadcrumbs: {
			orders: 'Bestellungen'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'zurück',
				captionPrefix: ' um '
			},
			installationCompletion: {
				backLinkLabel: 'zurück',
				captionPrefix: ' um '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Das Laden der Termine ist fehlgeschlagen.',
				message: 'Während des Ladens der Termin ist ein Fehler aufgetreten.'
			},
			installationCompletion: {
				heading: 'Der Abschluss der Installation ist fehlgeschlagen.',
				message: 'Während der Aufgabenverarbeitung ist ein Fehler aufgetreten.',
				resolveAction: {
					label: 'Zurück zur Installation'
				},
				notAllowed: 'Der Abschluss der Installation ist nicht erlaubt.'
			},
			installerAppointmentNotFound: 'Der Termin konnte nicht gefunden werden.',
			orderNotFound: 'Die Bestellung konnte nicht gefunden werden.',
			installationDetail: {
				heading: 'Das Laden der Installationsdaten ist fehlgeschlagen.',
				message: 'Wähend des Ladens der Daten ist ein Fehler aufgetreten.'
			},
			validation: {
				heading: 'Ungültige Daten',
				message: 'Die Daten, die Sie eingegeben haben, sind ungültig.'
			},
			generic: {
				heading: 'Etwas ist schiefgegangen',
				message: 'Ein unbekannter Fehler ist aufgetreten.',
				resolveAction: {
					retry: {
						label: 'Neu laden'
					}
				}
			},
			offline: {
				heading: 'Netzwerkverbindung nicht verfügbar',
				message: 'Bitte verbinden Sie sich mit einem Netzwerk, um die Anwendung nutzen zu können.'
			},
			notFound: {
				heading: 'Nicht gefunden',
				message: 'Die angefragte Ressource konnte nicht gefunden werden.',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			task: {
				notFound: {
					message: 'Die Aufgabe konnte nicht gefunden werden.'
				},
				complete: {
					message: 'Während der Erledigung der Aufgabe ist ein Fehler aufgetreten.'
				}
			}
		}
	}
};

export default literals;
