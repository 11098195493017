import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './InformationInstallationDateTimeAddress.scss';

export type InformationInstalllationDateTimeAddressProps = {
	id?: string,
	children: ReactNode,
	isHighlighted?: boolean,
	onClick: () => void
};

export function InformationInstallationDateTimeAddress(props: InformationInstalllationDateTimeAddressProps) {
	const { children, isHighlighted, id, onClick } = props;

	const dateTimeAddressClassMap = {
		'information-installation-date-time-address': true,
		'information-installation-date-time-address--isHighlighted': isHighlighted
	};

	const handleClick = () => {
		onClick();
	};

	return (
		<article id={id} onClick={handleClick} className={buildCssClassStringFromClassMap(dateTimeAddressClassMap)}>
			{children}
		</article>
	);
}
