import React from 'react';

import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';
import { CalendarItemPicker, Card, CardHeader, Cardlayout, CardSection } from '@abb-emobility/shared/ui-primitive';

import { CalendarDayViewItemType } from './MobileTopbarCalendar.types';

import './MobileTopbarCalendar.scss';

export type CalendarProps = {
	dayList: Array<CalendarDayViewItemType>,
	relPagePath: string
};

export const MobileTopbarCalendar = (props: CalendarProps) => {
	const { dayList, relPagePath } = props;

	const l10n = useL10n();

	const renderList = (): Array<JSX.Element> => {
		return dayList.map((calendarDay, index) => {

			const { firstAppointment, startTimestamp } = calendarDay;
			const startDate = createDateFromTimestamp(startTimestamp);
			const dayAsNumber = startDate.getDate();

			const dayOfWeekLiteral = l10n.formatDayname(startDate, '', DaynameFormat.SHORT);

			if (firstAppointment) {
				return <CalendarItemPicker
					key={index}
					hasAppointment={true}
					to={`${relPagePath}#${firstAppointment.id}`}
					dayName={dayOfWeekLiteral}
					dayNumber={dayAsNumber.toString()} />;
			}

			return <CalendarItemPicker
				key={index}
				dayName={dayOfWeekLiteral}
				dayNumber={dayAsNumber.toString()} />;
		});
	};

	const renderToday = (): React.JSX.Element => {

		const todayItem = dayList[0];
		const startDate = createDateFromTimestamp(todayItem.startTimestamp);

		const dayOfWeekLiteral = l10n.formatDayname(startDate, '', DaynameFormat.SHORT);
		const dayNumber = startDate.getDate().toString();

		if (todayItem.firstAppointment) {
			return <CalendarItemPicker
				hasAppointment={true}
				to={`${relPagePath}#`}
				dayName={dayOfWeekLiteral}
				dayNumber={dayNumber} />;
		}

		return <CalendarItemPicker dayName={dayOfWeekLiteral} dayNumber={dayNumber} />;
	};

	return (
		<div className="mobile-topbar-calendar-layout">
			<Card>
				<Cardlayout>
					<div className="mobile-topbar-calendar-layout__appointments">
						<CardHeader heading={l10n.translate('omsInstallationPartnerServiceApp.appointmentCollection.heading')} />
						<CardSection>
							<div className="mobile-topbar-calendar">
								<div className="mobile-topbar-calendar__items">
									{renderList().slice(1)}
								</div>

								<div className="mobile-topbar-calendar__today">
									{renderToday()}
								</div>
							</div>
						</CardSection>
					</div>
				</Cardlayout>
			</Card>
		</div>
	);
};
