import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Algo salió mal',
				message: 'Se ha producido un error que no se ha gestionado correctamente',
				resolveAction: {
					label: 'Volver a cargar'
				}
			},
			recoverLabel: 'Inténtelo de nuevo',
			fetchHeading: 'Error de búsqueda',
			mutateHeading: 'Error de actualización',
			createHeading: 'Error de creación',
			deleteHeading: 'Error de eliminación',
			taskAssignHeading: 'Error de asignación',
			taskCompleteHeading: 'Error de finalización'
		}
	}
};

export default literals;
