import { GridOperatorModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './GridOperatorInformation.scss';

export type GridOperatorInformationProps = {
	gridOperator: GridOperatorModel
};

export function GridOperatorInformation(props: GridOperatorInformationProps) {
	const { gridOperator } = props;

	const l10n = useL10n();

	return (
		<article className="grid-operator-information">
			<section className="grid-operator-information__content">
				<div className="grid-operator-information__content__heading">{l10n.translate('sharedUiPrimitive.gridOperatorInformation.heading')}</div>
				<div className="grid-operator-information__content__info">
					<span>{gridOperator.name}</span>
				</div>
			</section>
		</article>
	);
}
