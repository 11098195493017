import React, { useState } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { InputRadio, Separator, SubmissionStatus } from '@abb-emobility/shared/ui-primitive';
import {
	ButtonPrimary,
	InputRadioGroup,
	Upload
} from '@abb-emobility/shared/ui-primitive';
import { numberFromString, Optional } from '@abb-emobility/shared/util';
import { InstallationCompletionPayloadModel } from '@abb-emobility/usertask/domain-model';

import './InstallationCompletionUpload.scss';

type InstallationCompletionUploadProps = {
	onSubmit: (customerIsPresent: boolean, selectedFiles: ReadonlyArray<File>) => void,
	files: ReadonlyArray<File>,
	taskPayload: InstallationCompletionPayloadModel,
	submitButtonState: SubmissionStatus,
	submissionDisabled: boolean
};

export function InstallationCompletionUpload(props: InstallationCompletionUploadProps) {

	const { onSubmit, files, taskPayload, submitButtonState, submissionDisabled } = props;

	const l10n = useL10n();

	const [customerIsPresent, setCustomerIsPresent] = useState(true);
	const [selectedFiles, setSelectedFiles] = useState<ReadonlyArray<File>>(files);

	const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === 'isPresent') {
			setCustomerIsPresent(true);
		}
		if (value === 'isNotPresent') {
			setCustomerIsPresent(false);
		}
	};

	const handleSelectedFileChange = (files: ReadonlyArray<File>) => {
		setSelectedFiles(files);
	};

	const handleUploadSubmission = () => {
		onSubmit(customerIsPresent, selectedFiles);
	};

	const renderButton = (): React.JSX.Element => {
		let buttonLabel = l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.action.signature');

		if (!customerIsPresent) {
			buttonLabel = l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.action.complete');
		}

		return (
			<ButtonPrimary
				onClick={handleUploadSubmission}
				label={buttonLabel}
				submissionStatus={submitButtonState}
				disabled={submissionDisabled}
			/>
		);
	};

	const getMaxFileSize = (): number => {
		return numberFromString(new Optional(process.env['NX_MAX_UPLOAD_FILE_SIZE']).get(), Number.MAX_SAFE_INTEGER);
	};

	return (
		<section className="installation-completion-upload">
			{/*Document Selection Section*/}
			<section className="installation-completion-upload__section">
				<span className="installation-completion-upload__section__heading">
					{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.upload.heading')}
				</span>
				<Separator />
				<Upload
					maxFileSize={getMaxFileSize()}
					files={selectedFiles}
					onChange={handleSelectedFileChange}
				/>
			</section>
			{/*Confirmation Section*/}
			<section className="installation-completion-upload__section">
				<span className="installation-completion-upload__section__heading">
					{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.confirmation.heading')}
				</span>
				<Separator />
				<span className="installation-completion-upload__section__confirmation">
					{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.confirmation.text', new Map([['serialNumber', taskPayload.wallboxSerialNumber]]))}
				</span>
				<span className="installation-completion-upload__section__confirmation">
					{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.confirmation.throttle')}
				</span>
			</section>
			{/*Inspection Section*/}
			<section className="installation-completion-upload__section">
				<span className="installation-completion-upload__section__heading">
					{l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.inspection.heading')}
				</span>
				<Separator />
				<InputRadioGroup>
					<InputRadio
						name="customerPresent"
						value="isPresent"
						checked={customerIsPresent}
						onChange={handleRadioChange}
						label={l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.inspection.customerPresent')} />
					<InputRadio
						name="customerPresent"
						value="isNotPresent"
						checked={!customerIsPresent}
						onChange={handleRadioChange}
						label={l10n.translate('omsInstallationPartnerServiceApp.installationCompletion.inspection.customerNotPresent')} />
				</InputRadioGroup>
			</section>
			{/*Button*/}
			<section className="installation-completion-upload__footer">
				{renderButton()}
			</section>
		</section>
	);
}
