import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'No se ha encontrado el tipo de respuesta {{tipo}}.',
			textValueNotSet: 'no establecido',
			numericValueNotSet: 'no establecido',
			pictureValueNotSet: 'No se ha facilitado ninguna imagen',
			documentValueNotSet: 'No se ha facilitado ningún documento',
			singleSelectValueNotSet: 'Sin selección',
			multiSelectValueNotSet: 'Sin selección',
			consentValueChecked: 'sí',
			consentValueUnchecked: 'no'
		}
	}
};

export default literals;
