import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Informations de base',
				installationPartnerArrangeAppointment: 'Fixer un rendez-vous',
				installationPartnerExecuteRemotePreCheck: 'Pré-contrôle',
				installationPartnerRegisterAtGridOperator: 'Enregistrement de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecision: 'Décision de l’opérateur réseau',
				installationPartnerRevokeGridOperatorRegistration: 'Révocation de l’opérateur réseau',
				installationPartnerProvideQuotation: 'Offre',
				installationPartnerCompleteInstallation: 'Achèvement de l’installation'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Prix total (net)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Dans les délais',
				delayed: 'Retardé',
				cancelPending: 'Annuler en attente',
				cancelled: 'Annulé',
				done: 'Terminé'
			},
			installationType: {
				none: 'Indéterminé',
				undetermined: 'Indéterminé',
				cancelled: 'Annulé',
				standard: 'Standard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Annuler les rendez-vous'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Attention',
				message: 'La consommation électrique de la Wallbox doit être réduite en fonction des exigences de l’opérateur réseau.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'Aucune information sur les commandes n’est encore disponible',
					message: 'Au fur et à mesure de l’avancement de la commande, vous trouverez ici toutes les informations utiles concernant la commande.'
				},
				caption: {
					basicAnswers: 'Réponses de base',
					gridOperatorRegistration: 'Enregistrement de l’opérateur réseau',
					gridOperatorDecision: 'Décision de l’opérateur réseau',
					preCheck: 'Pré-contrôle',
					offer: 'Offre',
					approval: 'Homologation'
				},
				precheck: {
					heading: 'Pré-contrôle',
					effort: 'Pré-contrôlez les résultats : Rendez-vous et efforts d’installation',
					questionnaire: {
						heading: 'Réponses des clients'
					},
					installationType: {
						undetermined: 'Indéterminé',
						standard: 'Installation standard',
						delta: 'Installation Delta'
					},
					decision: {
						accepted: 'Informations suffisantes',
						more: 'Plus d’informations nécessaires',
						denied: 'Installation impossible'
					},
					notes: 'Notes',
					hours: 'heures'
				},
				decision: {
					heading: 'Décision de l’opérateur réseau',
					decision: {
						pending: 'La demande d’installation est toujours en cours',
						accepted: 'L’opérateur réseau a accepté l’installation',
						denied: 'L’opérateur réseau a refusé l’installation'
					}
				},
				registration: {
					heading: 'Enregistrement de l’opérateur réseau',
					documents: 'Documents',
					gridOperator: 'Opérateur réseau'
				},
				quotation: {
					heading: 'Devis',
					conditions: 'Conditions de l’offre'
				},
				complete: {
					success: {
						heading: 'Installation réussie'
					},
					notes: 'Notes',
					images: 'Images relatives à l’installation',
					documents: 'Documents relatifs à l’installation',
					signature: 'Signature client'
				}
			}
		},
		orderLineItems: {
			heading: 'Éléments de ligne de commande',
			head: {
				title: 'Éléments de ligne',
				description: 'Description',
				productCode: 'Code produit ABB'
			},
			serial: 'Numéro de série de la Wallbox',
			shippingStatus: {
				label: 'Statut de l’expédition #{{shipmentNumber}}',
				pending: 'En attente',
				delivered: 'Fourni',
				expected: 'Livraison prévue le '
			},
			empty: 'Aucun élément de ligne de commande disponible'
		}
	}
};

export default literals;
