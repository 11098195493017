import { InstallationPartnerRealmConfig } from '@abb-emobility/oms/installation-partner-oauth';
import { Environment } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { Optional, trimFromLeft } from '@abb-emobility/shared/util';

export const deriveInstallationPartner = (environment: Environment) => {
	const pathname = trimFromLeft(window.location.pathname, '/');
	const installationPartnerPath = pathname.split('/').shift();
	if (installationPartnerPath === undefined) {
		return new Optional<InstallationPartnerRealmConfig>(null);
	}
	if (!('installationPartnerRealms' in environment)) {
		throw new AppError('No installation partner config found');
	}

	const installationPartner = (environment['installationPartnerRealms'] as Array<InstallationPartnerRealmConfig>).find((value) => {
		return value.path.toLowerCase() === installationPartnerPath.toLowerCase();
	});

	return new Optional<InstallationPartnerRealmConfig>(installationPartner);
};
