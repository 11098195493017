import React, { ReactNode } from 'react';

import { InstallationPartnerOrderInformationApprovalModel } from '@abb-emobility/oms/domain-model';
import { InstallationState } from '@abb-emobility/shared/domain-model';
import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	DownloadList,
	DownloadListItem,
	Hint,
	HintLevel,
	IconIdentifier,
	ImageThumbnail,
	ImageThumbnailCollection,
	OrderComment,
	SectionHeader,
	Separator
} from '@abb-emobility/shared/ui-primitive';

type ApprovalProps = {
	orderInformation: InstallationPartnerOrderInformationApprovalModel
};

export function OrderInformationApproval(props: ApprovalProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderNotification = (): ReactNode => {
		switch (orderInformation.payload.installationState) {
			case InstallationState.DONE:
				return (<Hint
					heading={l10n.translate('omsUiDomain.orderDetail.informationList.complete.success.heading')}
					level={HintLevel.SUCCESS}
					icon={IconIdentifier.CHECK}
				/>);
			default:
				return null;
		}
	};

	const renderComment = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderImages = (files: Array<DownloadFile>): ReactNode => {
		return files.map((file, index) => renderImage(file, index));
	};

	const renderImage = (file?: DownloadFile, key?: number): ReactNode => {
		if (file === undefined || file === null || !file.meta.mimeType.startsWith('image/')) {
			return undefined;
		}
		return (<ImageThumbnail file={file} key={key} />);
	};

	const renderDocuments = (files: Array<DownloadFile>): ReactNode => {
		if (files.length === 0) {
			return null;
		}
		return (
			<DownloadList>
				{files.map((file, index) => renderDocument(file, index))}
			</DownloadList>
		);
	};

	const renderDocument = (file?: DownloadFile, key?: number): ReactNode => {
		if (file === undefined || file.meta.mimeType.startsWith('image/')) {
			return undefined;
		}
		return (<DownloadListItem file={file} key={key} />);
	};

	const renderImageSection = (): ReactNode => {
		if (orderInformation.payload.installationDocuments.length === 0) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.complete.images')} />
				<ImageThumbnailCollection>
					{renderImages(orderInformation.payload.installationDocuments)}
				</ImageThumbnailCollection>
				<Separator />
			</CollectionItemContentSection>
		);
	};

	const renderDocumentSection = (): ReactNode => {
		if (orderInformation.payload.installationDocuments.length === 0) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.complete.documents')} />
				{renderDocuments(orderInformation.payload.installationDocuments)}
				<Separator />
			</CollectionItemContentSection>
		);
	};

	const renderSignatureSection = (): ReactNode => {
		if (orderInformation.payload.customerSignature === undefined || orderInformation.payload.customerSignature === null) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.complete.signature')} />
				{renderImage(orderInformation.payload.customerSignature)}
			</CollectionItemContentSection>
		);
	};

	return (
		<>
			{renderNotification()}
			{renderImageSection()}
			{renderDocumentSection()}
			{renderSignatureSection()}
			{renderComment()}
		</>
	);

}
