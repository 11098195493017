import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiQuestionnaire: {
		questionnaire: {
			answerTypeNotFound: 'O tipo de resposta {{type}} não foi encontrado.',
			textValueNotSet: 'não definido',
			numericValueNotSet: 'não definido',
			pictureValueNotSet: 'Nenhuma imagem fornecida',
			documentValueNotSet: 'Nenhum documento fornecido',
			singleSelectValueNotSet: 'Nenhuma seleção',
			multiSelectValueNotSet: 'Nenhuma seleção',
			consentValueChecked: 'sim',
			consentValueUnchecked: 'não'
		}
	}
};

export default literals;
