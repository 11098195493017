export function TermsEn() {

	return (
		<>
			<h1>Declaration</h1>
			<p>I hereby confirm that I am fully competent and qualified in accordance with the applicable laws, regulations, local legislation and standards to perform the electrical installation and configuration work (including any necessary diplomas, certificates, licenses or registration) and have the authority to act for and on behalf of the installation company appointed by ABB E-mobility B.V. I fully know and understand this and the requirements for its safe installation and configuration of ABB E-Mobility Wallboxes and will comply with any applicable (local) laws and regulations, the state of the art, as well as any instructions provided by ABB E-Mobility B.V. in particular in accordance with the <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">operation and installation manual.</a></p>
			<p>The <a href="https://abb.com" target="_blank" rel="noopener noreferrer">user terms</a> in relation to access and use of this application apply hereto. </p>
		</>
	);
}
