import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { scrollWindowToTop } from '@abb-emobility/shared/browser';
import { TaskCompleteError } from '@abb-emobility/shared/business-error';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { createTimestampFromDate, createUploadFileFromFile, UploadFile } from '@abb-emobility/shared/domain-model-foundation';
import { AppError, AuthenticationFailedError, AuthenticationRequiredError, NotFoundError, restoreError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { SubmissionStatus } from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { InstallationCompletionPayloadModel, TaskStatus } from '@abb-emobility/usertask/domain-model';

import { InstallationCompletionViews } from './InstallationCompletion.enums';
import { calculateDataUrlFileSize } from './InstallationCompletion.util';
import { useBuildPageTitle } from '../../page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';
import { InstallationCompletionDone } from '../installation-completion-done/InstallationCompletionDone';
import { InstallationCompletionSignature } from '../installation-completion-signature/InstallationCompletionSignature';
import { InstallationCompletionUpload } from '../installation-completion-upload/InstallationCompletionUpload';
import { useTopbar } from '../mobile-topbar-content/MobileTopbarContent.context';

export type InstallationCompletionViewControllerProps = {
	installerAppointmentId: string
};

export function InstallationCompletion(props: InstallationCompletionViewControllerProps) {
	const { installerAppointmentId } = props;

	const completionViewDuration = 3000; //ms

	const l10n = useL10n();
	const navigate = useNavigate();
	const { createRouteUrl } = useRouteUrl();
	const topbar = useTopbar();

	usePageTitle(useBuildPageTitle('omsInstallationPartnerServiceApp.pageTitle.installationCompletion'));

	const [visibleView, setVisibleView] = useState<InstallationCompletionViews>(InstallationCompletionViews.UPLOAD);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const [completionRequested, setCompletionRequested] = useState<boolean>(false);

	const customerIsPresentRef = useRef(true);
	const selectedFilesRef = useRef<ReadonlyArray<File>>([]);
	const signatureRef = useRef<Nullable<HTMLCanvasElement>>(null);

	const anyTaskData = useAnyTaskEntityData();
	const task = anyTaskData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerServiceApp.error.task.notFound.message')));

	const actionErrorObject = anyTaskData.queryActionError();

	const backLabel = l10n.translate('omsInstallationPartnerServiceApp.topbar.installationCompletion.backLinkLabel');

	const navigateHome = () => {
		setTimeout(() => {
			navigate(createRouteUrl(RoutePath.ROOT));
		}, completionViewDuration);
	};

	// define topbar content based on the current page
	useEffect(() => {
		switch (visibleView) {
			case InstallationCompletionViews.UPLOAD:
				topbar.setLink({
					link: createRouteUrl(RoutePath.APPOINTMENT, ['installerAppointmentId', installerAppointmentId]),
					label: backLabel
				});
				topbar.setIsVisible(true);
				return;
			case InstallationCompletionViews.SIGNATURE:
				topbar.setLink({
					callback: () => {
						setVisibleView(InstallationCompletionViews.UPLOAD);
					},
					label: backLabel
				});
				topbar.setIsVisible(true);
				return;
			case InstallationCompletionViews.COMPLETED:
				topbar.setIsVisible(false);
				return;
		}
	}, [visibleView]);

	// complete the task if completionRequested is set to true
	useEffect(() => {
		if (!completionRequested || (completionRequested && task.status === TaskStatus.DONE)) {
			return;
		}

		const taskComplete = async (): Promise<void> => {
			const uploadFiles = await Promise.all(selectedFilesRef.current.map(async (file): Promise<UploadFile> => {
				return createUploadFileFromFile(file);
			}));

			const dataURL = signatureRef.current?.toDataURL();

			const payload = {
				...task.payload,
				documents: uploadFiles,
				customerIsPresent: customerIsPresentRef.current
			} as InstallationCompletionPayloadModel;

			if (dataURL !== undefined && payload.customerIsPresent === true) {
				const signatureUploadFile = {
					dataUri: dataURL,
					discriminator: 'UploadFile',
					meta: {
						fileName: 'Customer Signature',
						lastModifiedDate: createTimestampFromDate(new Date()),
						size: calculateDataUrlFileSize(dataURL, 'data:image/png;base64,')
					}
				} as UploadFile;
				payload.signature = signatureUploadFile;
			}

			anyTaskData.complete(task, payload);
		};

		void taskComplete();

	}, [completionRequested]);

	// actions triggered by changes submitted to the server
	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
		setVisibleView(InstallationCompletionViews.COMPLETED);
		navigateHome();
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskCompleteError(l10n.translate('omsInstallationPartnerServiceApp.error.task.complete.message'), undefined, undefined, error);
	}, true);

	const handleUploadSubmit = (customerIsPresent: boolean, selectedFiles: ReadonlyArray<File>) => {
		addUpload(customerIsPresent, selectedFiles);

		if (customerIsPresent) {
			setVisibleView(InstallationCompletionViews.SIGNATURE);
		}
		if (!customerIsPresent) {
			handleCompletion();
		}
	};

	const handleSignatureSubmit = (signature: HTMLCanvasElement) => {
		addSignature(signature);
		handleCompletion();
	};

	const handleSignatureClose = () => {
		setVisibleView(InstallationCompletionViews.UPLOAD);
	};

	const handleCompletion = () => {
		// triggers the useEffect hook
		setCompletionRequested(true);
	};

	const addUpload = (customerIsPresent: boolean, selectedFiles: ReadonlyArray<File>) => {
		customerIsPresentRef.current = customerIsPresent;
		selectedFilesRef.current = selectedFiles;
	};

	const addSignature = (signature: HTMLCanvasElement) => {
		signatureRef.current = signature;
	};

	const renderCompletionViews = (): React.JSX.Element => {
		switch (visibleView) {
			case InstallationCompletionViews.UPLOAD:
				scrollWindowToTop();
				return (
					<InstallationCompletionUpload
						taskPayload={task.payload as InstallationCompletionPayloadModel}
						files={selectedFilesRef.current}
						onSubmit={handleUploadSubmit}
						submissionDisabled={task.status === TaskStatus.DONE}
						submitButtonState={taskCompletionButtonState}
					/>
				);

			case InstallationCompletionViews.SIGNATURE:
				scrollWindowToTop();
				return (
					<InstallationCompletionSignature
						onClose={handleSignatureClose}
						onSubmit={handleSignatureSubmit}
						submitButtonState={taskCompletionButtonState}
					/>
				);

			case InstallationCompletionViews.COMPLETED:
				return (
					<InstallationCompletionDone />
				);

			default:
				// should never Occur
				throw new AppError('switch case not defined');
		}
	};

	return (
		<>
			{renderCompletionViews()}
		</>

	);
}
