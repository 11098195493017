import React from 'react';

import { AppLayoutMobile, AppLayoutMobileMain } from '@abb-emobility/shared/ui-primitive';

import { Router } from '../../router/Router';
import { MobileTopbarContent } from '../mobile-topbar-content/MobileTopbarContent';

export function AppContainer() {

	return (
		<AppLayoutMobile>
			<MobileTopbarContent>
				<AppLayoutMobileMain>
					<Router />
				</AppLayoutMobileMain>
			</MobileTopbarContent>
		</AppLayoutMobile>
	);
}
