import { MouseEvent, ReactNode, useEffect, useState } from 'react';

import { UrlResolver } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { startDownload } from '@abb-emobility/shared/browser';
import { DownloadFile, Url } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { formatFileSize } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap, Maybe, Nullable, Optional } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './DownloadListItem.scss';

export type DownloadListItemProps = {
	disabled?: boolean,
	file: DownloadFile
};

export function DownloadListItem(props: DownloadListItemProps) {
	const { file, disabled = false } = props;

	const l10n = useL10n();
	const auth = useAuth();
	const documentStoreApiBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_API_BASE_URL'])
		.getOrThrow(new AppError('No document store API base URL provided'));
	const documentStoreMediaBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_MEDIA_BASE_URL'])
		.getOrThrow(new AppError('No document store media base URL provided'));

	const [downloadUrl, setDownloadUrl] = useState<Nullable<Url>>(null);

	useEffect(() => {
		const urlResolver = new UrlResolver();
		const resolveUrl = async (): Promise<void> => {
			if (downloadUrl === null) {
				return;
			}
			try {
				const resolvedUrl = await urlResolver.resolve(documentStoreApiBaseUrl, documentStoreMediaBaseUrl, downloadUrl, auth.getToken().get());
				startDownload(resolvedUrl);
			} catch (e) {
				console.error(e);
			} finally {
				setDownloadUrl(null);
			}
		};
		void resolveUrl();
		return () => {
			urlResolver.abort();
		};
	}, [downloadUrl, auth]);

	const handleStartDownload = (event: MouseEvent) => {
		event.preventDefault();
		setDownloadUrl(file.url);
	};

	const buildFiletype = (filetype?: string): Maybe<string> => {
		return filetype?.split('/').pop()?.toUpperCase() ?? undefined;
	};

	const buildFileSize = (size?: number) => {
		if ((size ?? null) === null) {
			return '';
		}
		return formatFileSize(size);
	};

	const renderFileInfo = (): ReactNode => {
		let fileInfoParts = [
			buildFileSize(file.meta.size),
			buildFiletype(file.meta.mimeType),
			l10n.formatTimestampDateTime(file.meta.lastModifiedDate)
		];
		fileInfoParts = fileInfoParts.filter((fileInfoPart) => {
			return fileInfoPart !== undefined && fileInfoPart !== '';
		});
		if (fileInfoParts.length === 0) {
			return;
		}
		return (
			<div className="download-list-item__file__info__details">{fileInfoParts.join(' | ')}</div>
		);
	};

	const getFileIconIdentifier = (): IconIdentifier => {
		if (file.meta.mimeType.toLowerCase().startsWith('image')) {
			return IconIdentifier.FILE_IMAGE;
		}
		if (file.meta.mimeType.toLowerCase().endsWith('pdf')) {
			return IconIdentifier.FILE_PDF;
		}
		if (file.meta.mimeType.toLowerCase().endsWith('zip')) {
			return IconIdentifier.FILE_ZIP;
		}
		return IconIdentifier.FILE;
	};

	const fileListItemClassmap = {
		'download-list-item': true,
		'download-list-item--disabled': disabled
	};

	return (
		<a href={file.url.discriminator} onClick={handleStartDownload} className={buildCssClassStringFromClassMap(fileListItemClassmap)}>
			<div className="download-list-item__file">
				<span className="download-list-item__file__icon">
					<Icon name={getFileIconIdentifier()} />
				</span>
				<div className="download-list-item__file__info">
					<div className="download-list-item__file__info__title">{file.meta.title ?? file.meta.fileName}</div>
					{renderFileInfo()}
				</div>
			</div>
			<span className="download-list-item__icons">
				<span className="download-list-item__icons__download">
					<Icon name={IconIdentifier.DOWNLOAD_SIMPLE} />
				</span>
			</span>
		</a>
	);

}
