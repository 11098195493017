import { ReactNode } from 'react';

import { Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { formatFileSize } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap, Maybe } from '@abb-emobility/shared/util';

import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './UploadQueueItem.scss';

export type UploadQueueItemProps = {
	fileName: string,
	fileSize: number,
	fileType: string,
	fileDate: Timestamp,
	errorneous?: boolean,
	onRemove?: () => void
};

export function UploadQueueItem(props: UploadQueueItemProps) {
	const { fileName, fileSize, fileType, fileDate, errorneous = false, onRemove } = props;

	const l10n = useL10n();

	const handleDismiss = (): void => {
		if (onRemove !== undefined) {
			onRemove();
		}
	};

	const uploadQueueItemClassmap = {
		'upload-queue-item': true,
		'upload-queue-item--errorneous': errorneous
	};

	const buildFiletype = (filetype?: string): Maybe<string> => {
		return filetype?.split('/').pop()?.toUpperCase() ?? undefined;
	};

	const renderFileInfo = (): ReactNode => {
		let fileInfoParts = [
			formatFileSize(fileSize),
			buildFiletype(fileType),
			l10n.formatTimestampDateTime(fileDate)
		];
		fileInfoParts = fileInfoParts.filter((fileInfoPart) => {
			return fileInfoPart !== undefined && fileInfoPart !== '';
		});
		if (fileInfoParts.length === 0) {
			return;
		}
		return (
			<div className="upload-queue-item__file__info__details">{fileInfoParts.join(' | ')}</div>
		);
	};

	const renderIcon = (): ReactNode => {
		if (errorneous) {
			return (<Icon name={IconIdentifier.WARNING} />);
		}
		return (<Icon name={IconIdentifier.FILE_ARROW_UP} />);
	};

	return (
		<article className={buildCssClassStringFromClassMap(uploadQueueItemClassmap)}>
			<div className="upload-queue-item__file">
				<span className="upload-queue-item__file__icon">
					{renderIcon()}
				</span>
				<div className="upload-queue-item__file__info">
					<div className="upload-queue-item__file__info__title">{fileName}</div>
					{renderFileInfo()}
				</div>
			</div>
			<span className="upload-queue-item__action">
				<ButtonIcon onClick={handleDismiss}>
					<Icon name={IconIdentifier.X} />
				</ButtonIcon>
			</span>
		</article>
	);
}
