import React from 'react';

import { useInstallationPartnerOrderInformationEntityData } from '@abb-emobility/oms/data-provider';
import { InstallationPartnerOrderInformationType } from '@abb-emobility/oms/domain-model';
import { AppError } from '@abb-emobility/shared/error';

import { OrderInformationApproval } from './payload/OrderInformationApproval';
import { OrderInformationBasicAnswers } from './payload/OrderInformationBasicAnswers';
import { OrderInformationGridOperatorDecision } from './payload/OrderInformationGridOperatorDecision';
import { OrderInformationGridOperatorRegistration } from './payload/OrderInformationGridOperatorRegistration';
import { OrderInformationPrecheck } from './payload/OrderInformationPrecheck';
import { OrderInformationQuotation } from './payload/OrderInformationQuotation';

type OrderInformationFactoryProps = {
	includePricing?: boolean
};

export function OrderInformationFactory(props: OrderInformationFactoryProps) {
	const { includePricing } = props;

	const orderInformationData = useInstallationPartnerOrderInformationEntityData();
	const orderInformation = orderInformationData.query().getOrThrow(new AppError('Order information not found'));

	switch (orderInformation.type) {
		case InstallationPartnerOrderInformationType.BASIC_ANSWERS:
			return (<OrderInformationBasicAnswers orderInformation={orderInformation} />);
		case InstallationPartnerOrderInformationType.GRID_OPERATOR_REGISTRATION:
			return (<OrderInformationGridOperatorRegistration orderInformation={orderInformation} />);
		case InstallationPartnerOrderInformationType.GRID_OPERATOR_DECISION:
			return (<OrderInformationGridOperatorDecision orderInformation={orderInformation} />);
		case InstallationPartnerOrderInformationType.PRE_CHECK:
			return (<OrderInformationPrecheck orderInformation={orderInformation} />);
		case InstallationPartnerOrderInformationType.OFFER:
			return (<OrderInformationQuotation orderInformation={orderInformation} includePricing={includePricing} />);
		case InstallationPartnerOrderInformationType.APPROVAL:
			return (<OrderInformationApproval orderInformation={orderInformation} />);
	}
}
