import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Informazioni di base',
				installationPartnerArrangeAppointment: 'Fissa appuntamento',
				installationPartnerExecuteRemotePreCheck: 'Controllo preliminare',
				installationPartnerRegisterAtGridOperator: 'Registrazione gestore di rete',
				installationPartnerProvideGridOperatorDecision: 'Decisione gestore di rete',
				installationPartnerRevokeGridOperatorRegistration: 'Revoca gestore di rete',
				installationPartnerProvideQuotation: 'Offerta',
				installationPartnerCompleteInstallation: 'Installazione completata'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Prezzo totale (netto)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'In programma',
				delayed: 'Rimandato',
				cancelPending: 'Annulla in sospeso',
				cancelled: 'Annullato',
				done: 'Fatto'
			},
			installationType: {
				none: 'Indeterminato',
				undetermined: 'Indeterminato',
				cancelled: 'Annullato',
				standard: 'Standard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Annulla appuntamenti'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Attenzione',
				message: 'Il consumo energetico della centralina a parete deve essere ridotto in base ai requisiti del gestore di rete.'
			},
			informationList: {
				heading: 'Informazioni',
				emptyState: {
					heading: 'Non sono ancora disponibili informazioni sull\'ordine',
					message: 'Man mano che l\'ordine procede, tutte le informazioni rilevanti verranno riportate qui.'
				},
				caption: {
					basicAnswers: 'Risposte di base',
					gridOperatorRegistration: 'Registrazione gestore di rete',
					gridOperatorDecision: 'Decisione gestore di rete',
					preCheck: 'Controllo preliminare',
					offer: 'Offerta',
					approval: 'Approvazione'
				},
				precheck: {
					heading: 'Controllo preliminare',
					effort: 'Risultati del controllo preliminare: Appuntamenti e sforzi per l\'installazione',
					questionnaire: {
						heading: 'Risposte dei clienti'
					},
					installationType: {
						undetermined: 'Indeterminato',
						standard: 'Installazione Standard',
						delta: 'Installazione Delta'
					},
					decision: {
						accepted: 'Informazioni sufficienti',
						more: 'Sono necessarie ulteriori informazioni',
						denied: 'Installazione non possibile'
					},
					notes: 'Note',
					hours: 'ore'
				},
				decision: {
					heading: 'Decisione gestore di rete',
					decision: {
						pending: 'La richiesta di installazione è ancora in sospeso',
						accepted: 'Il gestore di rete ha acconsentito all\'installazione',
						denied: 'Il gestore di rete ha rifiutato l\'installazione'
					}
				},
				registration: {
					heading: 'Registrazione gestore di rete',
					documents: 'Documenti',
					gridOperator: 'Gestore di rete'
				},
				quotation: {
					heading: 'Preventivazione',
					conditions: 'Condizioni dell\'offerta'
				},
				complete: {
					success: {
						heading: 'Installazione riuscita'
					},
					notes: 'Note',
					images: 'Immagini relative all\'installazione',
					documents: 'Documenti relativi all\'installazione',
					signature: 'Firma del cliente'
				}
			}
		},
		orderLineItems: {
			heading: 'Voci dell\'ordine',
			head: {
				title: 'Voce',
				description: 'Descrizione',
				productCode: 'Codice prodotto ABB'
			},
			serial: 'Numero di serie della centralina a parete',
			shippingStatus: {
				label: 'Stato della spedizione #{{shipmentNumber}}',
				pending: 'In attesa',
				delivered: 'Consegnato',
				expected: 'Consegna prevista in data '
			},
			empty: 'Nessuna voce d\'ordine disponibile'
		}
	}
};

export default literals;
