import { Span, startSpanManual } from '@sentry/react';
import { SpanAttributes } from '@sentry/types/types/span';

import { Maybe } from '@abb-emobility/shared/util';

export const startSentrySpan = async (name: string, op?: string, attributes?: SpanAttributes): Promise<Maybe<Span>> => {
	return new Promise<Maybe<Span>>((resolve, reject) => {
		try {
			startSpanManual(
				{ name, op, attributes },
				(span) => {
					resolve(span);
				}
			);
		} catch (e) {
			reject(e);
		}
	});
};

export const endSentrySpan = (span: Span): void => {
	span.end();
};
