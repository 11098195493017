import { useEffect } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { useTopbar } from './MobileTopbarContent.context';
import { TopbarCaption, TopbarLink } from './MobileTopbarContent.types';

export const useTopbarLinkModifier = (link: Nullable<TopbarLink>): void => {
	const topbar = useTopbar();

	useEffect(() => {
		topbar.setLink(link);
	}, []);
};

export const useTopbarCaptionModifier = (caption: Nullable<TopbarCaption>): void => {
	const topbar = useTopbar();

	useEffect(() => {
		topbar.setCaption(caption);
	}, []);
};

export const useTopbarVisibilityModifier = (isVisible: boolean): void => {
	const topbar = useTopbar();

	useEffect(() => {
		topbar.setIsVisible(isVisible);
	}, []);
};
