import React, { MouseEvent, ReactNode } from 'react';

import { createDownloadFileFromUrlString, isDownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { AnswerTypeId, AnyQuestion, PersistedAnswers } from '@abb-emobility/shared/questionnaire';
import { AnswerDocument, AnswerImage, AnswerText, ImageModalContent, useModalDialogueManager } from '@abb-emobility/shared/ui-primitive';

export type AnswerFactoryProps = {
	keyPrefix: string,
	question: AnyQuestion,
	answers: PersistedAnswers
};

export function AnswerFactory(props: AnswerFactoryProps) {
	const { keyPrefix, question, answers } = props;

	const l10n = useL10n();
	const modalDialogueManager = useModalDialogueManager();

	const questionKey = keyPrefix + '.' + question.key;
	const answerValue = answers[questionKey] ?? undefined;

	const handlePictureHint = (event: MouseEvent): void => {
		event.preventDefault();
		const pictureHint = question.pictureHint;
		if (!pictureHint) {
			return;
		}
		const pictureHintTitle = l10n.translate('questionnaire.' + pictureHint.title);
		modalDialogueManager.push({
			caption: pictureHintTitle,
			content: (): ReactNode => {
				return (
					<ImageModalContent
						file={createDownloadFileFromUrlString(pictureHint.url, pictureHintTitle)}
					/>
				);
			}
		});
	};

	const renderPictureHint = (): ReactNode => {
		if (!question.pictureHint) {
			return null;
		}
		return (
			<a href="#" onClick={handlePictureHint}>
				{l10n.translate('questionnaire.' + question.pictureHint.title)}
			</a>
		);
	};

	switch (question.answerType.type) {
		case AnswerTypeId.TEXT:
		case AnswerTypeId.MAIL_ADDRESS:
		case AnswerTypeId.PHONE: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			return (
				<AnswerText
					question={localizedQuestion}
					answer={(answerValue ?? l10n.translate('sharedUiQuestionnaire.questionnaire.textValueNotSet')) as string}
				/>
			);
		}
		case AnswerTypeId.NUMBER: {
			const answerTypeOptions = question.answerType.options;
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			const precision = answerTypeOptions.precision ?? 0;
			const numericAnswerValue = l10n.formatNumber(
				parseFloat(answerValue as string),
				precision,
				undefined,
				l10n.translate('sharedUiQuestionnaire.questionnaire.numericValueNotSet')
			);
			return (
				<AnswerText
					question={localizedQuestion}
					answer={numericAnswerValue}
				/>
			);
		}
		case AnswerTypeId.PICTURE: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			if (!isDownloadFile(answerValue)) {
				return (
					<AnswerText
						question={localizedQuestion}
						hint={renderPictureHint()}
						answer={l10n.translate('sharedUiQuestionnaire.questionnaire.pictureValueNotSet')}
					/>
				);
			}

			return <AnswerImage
				title={localizedQuestion}
				hint={renderPictureHint()}
				file={answerValue}
			/>;
		}
		case AnswerTypeId.DOCUMENT: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			if (!isDownloadFile(answerValue)) {
				return (
					<AnswerText
						question={localizedQuestion}
						hint={renderPictureHint()}
						answer={l10n.translate('sharedUiQuestionnaire.questionnaire.documentValueNotSet')}
					/>
				);
			}
			return (
				<AnswerDocument
					title={localizedQuestion}
					hint={renderPictureHint()}
					file={answerValue}
				/>
			);
		}
		case AnswerTypeId.SINGLE_SELECT: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			const answerTypeOptions = question.answerType.options;
			const selectedOption = answerTypeOptions.options.find(
				(option): boolean => {
					return option.value === answerValue;
				}
			);
			const locliazedLabel = selectedOption?.label
				? l10n.translate('questionnaire.' + selectedOption.label, undefined, selectedOption.label)
				: l10n.translate('sharedUiQuestionnaire.questionnaire.singleSelectValueNotSet');
			return (
				<AnswerText
					question={localizedQuestion}
					answer={locliazedLabel}
				/>
			);
		}
		case AnswerTypeId.MULTI_SELECT: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			const answerTypeOptions = question.answerType.options;
			const selectedOptions = answerTypeOptions.options
				.filter((option): boolean => {
					const multiSelectAnswerValue =
						answers[questionKey + '.' + option.value] ?? undefined;
					return multiSelectAnswerValue === option.value;
				})
				.map((option): string => {
					return l10n.translate('questionnaire.' + option.label, undefined, option.label);
				});
			const displayValue =
				selectedOptions.length > 0
					? selectedOptions.join(', ')
					: l10n.translate('sharedUiQuestionnaire.questionnaire.multiSelectValueNotSet');
			return (
				<AnswerText
					question={localizedQuestion}
					answer={displayValue}
				/>
			);
		}
		case AnswerTypeId.CONSENT: {
			const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);
			const consentAnswerValue = answers[questionKey] ?? undefined;
			const displayValue =
				consentAnswerValue === 'granted'
					? l10n.translate('sharedUiQuestionnaire.questionnaire.consentValueChecked')
					: l10n.translate('sharedUiQuestionnaire.questionnaire.consentValueUnchecked');
			return (
				<AnswerText
					question={localizedQuestion}
					answer={displayValue}
				/>
			);
		}
		default:
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			throw new NotFoundError(l10n.translate('sharedUiQuestionnaire.questionnaire.answerTypeNotFound', new Map([['type', question.answerType.type]])));
	}
}
