import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiPrimitive: {
		customerContactInformation: {
			timeOfDay: {
				morning: 'Mattina',
				noon: 'Mezzogiorno',
				afternoon: 'Pomeriggio',
				evening: 'Sera'
			}
		},
		gridOperatorInformation: {
			heading: 'Gestore di rete'
		},
		informationHead: {
			orderDate: 'Data dell\'ordine',
			orderNumber: 'Numero d\'ordine'
		},
		imageThumbnail: {
			resolveErrorMessage: 'La risoluzione dell\'URL dell\'immagine non è riuscita'
		},
		task: {
			status: {
				started: 'Prossimamente',
				overdue: 'Scaduto',
				escalated: 'Inoltrato',
				done: 'Fatto',
				doneOverdue: 'Fatto',
				doneEscalated: 'Fatto'
			}
		},
		upload: {
			empty: {
				message: 'Nessun file selezionato'
			},
			dropMessage: 'Trascinare e rilasciare i file qui',
			selectButtonLabel: 'Scegli file',
			hint: 'File supportati: JPG, PNG o PDF di dimensioni inferiori a {{maxFilesize}} in totale.',
			fileSizeWarning: 'I file selezionati superano la dimensione totale massima consentita. Alcuni dei file selezionati non saranno inviati.'
		},
		choose: {
			label: 'Scegli'
		},
		search: {
			foundIn: 'Trovato in',
			noResults: 'Nessun risultato trovato'
		},
		termsAccept: {
			buttonLabel: 'Accetta'
		},
		collection: {
			filter: {
				filter: 'Filtro',
				none: 'nessuno',
				active: '{{count}} attivo',
				resetFilter: 'Reimposta filtro',
				group: {
					dueDate: 'Scadenza',
					priority: 'Priorità',
					estimatedCompletionDate: 'Data di installazione prevista',
					state: 'Stato',
					installationType: 'Installazione',
					runningOrderCount: 'Ordini in corso',
					languageCode: 'Lingua'
				},
				options: {
					dueDateOverdue: 'Scaduto',
					dueDateToday: 'In scadenza oggi',
					priorityHigh: 'Priorità alta',
					priorityMedium: 'Priorità media',
					priorityLow: 'Priorità bassa',
					statusDone: 'Fatto',
					statusOpen: 'Apri',
					installationTypeStandard: 'Installazione Standard',
					installationTypeDelta: 'Installazione Delta',
					installationTypeUndetermined: 'Indeterminato',
					installationTypeCancelled: 'Annullato',
					ordersActive: 'Ha ordini aperti',
					ordersSettled: 'Ha ordini completati',
					onSchedule: 'In programma',
					delayed: 'Rimandato',
					cancelled: 'Annullato',
					imported: 'Importato',
					validationFailed: 'Non valido',
					done: 'Fatto',
					de: 'DE',
					en: 'EN'
				}
			},
			sort: {
				sortBy: 'Ordina per',
				direction: {
					ascending: 'Crescente',
					descending: 'Decrescente'
				},
				options: {
					priority: 'Priorità',
					estimatedCompletionDate: 'Data di completamento prevista',
					status: 'Stato',
					installationDate: 'Data di installazione prevista',
					orderDate: 'Data dell\'ordine',
					estimatedDeliveryDate: 'Data di consegna prevista',
					shippingState: 'Stato della spedizione',
					name: 'Nome',
					firstOrder: 'Primo ordine',
					lastOrder: 'Ultimo ordine',
					dueDate: 'Scadenza'
				}
			}
		}
	}
};

export default literals;
