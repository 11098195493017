import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerServiceApp: {
		appName: 'Installationstjänst för ABB-laddbox',
		appointmentCollection: {
			heading: 'Datum',
			header: {
				today: 'I dag',
				upcoming: 'Kommande'
			},
			emptyState: {
				heading: 'Inga möten',
				message: 'Kunde inte hitta några möten.'
			}
		},
		installationCompletion: {
			done: 'Installationen är slutförd!',
			upload: {
				heading: 'Tillhandahåll dokument'
			},
			confirmation: {
				heading: 'Installerad laddbox',
				text: 'Härmed bekräftar jag att den levererade laddboxen med följande serienummer har installerats och tagits i drift på ett fackmannamässigt sätt: {{serialNumber}}.',
				throttle: 'Härmed bekräftar jag att laddboxens strömförbrukning reducerades i enlighet med nätoperatörens krav.'
			},
			inspection: {
				heading: 'Inspektion',
				customerPresent: 'Inspektion med kunden',
				customerNotPresent: 'Kunden är inte närvarande'
			},
			acceptance: {
				heading: 'Acceptans'
			},
			signature: {
				clear: 'Rensa',
				close: 'Stäng',
				done: 'Klar'
			},
			action: {
				signature: 'Kundens underskrift',
				complete: 'Komplett installation'
			}
		},
		installationDetail: {
			action: 'Komplett installation',
			heading: 'Installation',
			appointmentCollection: {
				heading: 'Avtal och möten',
				item: {
					installationPeriod: {
						preparation: 'Förberedelser för installation',
						beginning: 'Början av installationen',
						permanent: 'Permanent',
						end: 'Slut på installationen',
						followUp: 'Uppföljning av installationen'
					}
				}
			}
		},
		order: {
			installationType: {
				undetermined: 'Ej fastställd',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		pageTitle: {
			appointments: 'Möten',
			detail: 'Detaljuppgifter',
			installationCompletion: 'Slutförande av installationen',
			error: 'Fel',
			notFound: 'Hittades inte'
		},
		formatter: {
			dayNames: [
				'Söndag',
				'Måndag',
				'Tisdag',
				'Onsdag',
				'Torsdag',
				'Fredag',
				'Lördag'
			],
			fuzzyDaynames: {
				today: 'idag',
				tomorrow: 'imorgon',
				yesterday: 'igår'
			}
		},
		navigation: {
			tasks: 'Uppgifter',
			logout: 'Logga ut'
		},
		breadcrumbs: {
			orders: 'Översikt över beställning'
		},
		topbar: {
			installationDetail: {
				backLinkLabel: 'bakåt',
				captionPrefix: ' vid '
			},
			installationCompletion: {
				backLinkLabel: 'bakåt',
				captionPrefix: ' vid '
			}
		},
		error: {
			appointmentCollection: {
				heading: 'Hämtning av möten misslyckades',
				message: 'Ett fel uppstod när den begärda informationen lästes in.'
			},
			installationCompletion: {
				heading: 'Slutförandet av installationen misslyckades',
				message: 'Under slutförandet av uppgiften uppstod ett fel.',
				resolveAction: {
					label: 'Tillbaka till informationssidan'
				},
				notAllowed: 'Det är inte tillåtet att slutföra installationen.'
			},
			installerAppointmentNotFound: 'Installatörsbokning hittades inte',
			orderNotFound: 'Ordern hittades inte',
			installationDetail: {
				heading: 'Hämtning av installationsdata misslyckades',
				message: 'Ett fel uppstod när den begärda informationen lästes in.'
			},
			validation: {
				heading: 'Ogiltiga data',
				message: 'De data du skickade är inte giltiga.'
			},
			generic: {
				heading: 'Något gick fel',
				message: 'Ett fel uppstod som inte hanterades under renderingen',
				resolveAction: {
					retry: {
						label: 'Ladda om'
					}
				}
			},
			offline: {
				heading: 'Nätverksanslutningen är inte tillgänglig',
				message: 'Anslut till ett nätverk för att använda applikationen.'
			},
			notFound: {
				heading: 'Hittades inte',
				message: 'Den begärda resursen hittades inte',
				resolveAction: {
					label: 'Översikt'
				}
			},
			task: {
				notFound: {
					message: 'Uppgiften hittades inte'
				},
				complete: {
					message: 'Under slutförandet av uppgiften uppstod ett fel.'
				}
			}
		}
	}
};

export default literals;
