import { ButtonHTMLAttributes } from 'react';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './UserbarSignout.scss';

export type TopbarMobileSignoutProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function UserbarSignout(props: TopbarMobileSignoutProps) {
	const { ...rest } = props;
	return (
		<button className="userbar-signout" {...rest}>
			<Icon name={IconIdentifier.SIGN_OUT} />
		</button>
	);
}
