import React, { ReactNode } from 'react';

import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';

import { ImageThumbnail } from '../image-thumbnail/ImageThumbnail';

import './AnswerImage.scss';

type AnswerImageType = {
	title: string,
	hint?: ReactNode,
	file: DownloadFile
};

export function AnswerImage(props: AnswerImageType) {
	const { title, hint = null, file } = props;

	const renderHint = (): ReactNode => {
		if (hint === null) {
			return null;
		}
		return (
			<span className="answer-image__hint">{hint}</span>
		);
	};

	return (
		<div className="answer-image">
			<div className="answer-image__title">
				<span>{title}</span>
				{renderHint()}
			</div>
			<div className="answer-image__item">
				<ImageThumbnail file={file} />
			</div>
		</div>
	);
}
