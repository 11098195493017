export enum GravatarSize {
	EXTRA_SMALL = 'EXTRA_SMALL',
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
	EXTRA_LARGE = 'EXTRA_LARGE'
}

export enum GravatarDensity {
	FACTOR_1 = 'FACTOR_1',
	FACTOR_2 = 'FACTOR_2',
	FACTOR_3 = 'FACTOR_3'
}
