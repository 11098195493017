import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsUiDomain: {
		task: {
			shortName: {
				installationPartnerEvaluateBasicAnswers: 'Grundläggande information',
				installationPartnerArrangeAppointment: 'Boka tid för möte',
				installationPartnerExecuteRemotePreCheck: 'Inledande kontroll',
				installationPartnerRegisterAtGridOperator: 'Registrering av nätoperatör',
				installationPartnerProvideGridOperatorDecision: 'Beslut från nätoperatör',
				installationPartnerRevokeGridOperatorRegistration: 'Nekande av nätoperatör',
				installationPartnerProvideQuotation: 'Erbjudande',
				installationPartnerCompleteInstallation: 'Slutförande av installationen'
			},
			quotation: {
				deltaPackages: {
					fullPrice: 'Totalt pris (netto)'
				}
			}
		},
		order: {
			status: {
				onSchedule: 'Enligt schema',
				delayed: 'Fördröjd',
				cancelPending: 'Avbryt väntande',
				cancelled: 'Avbruten',
				done: 'Klar'
			},
			installationType: {
				none: 'Ej fastställd',
				undetermined: 'Ej fastställd',
				cancelled: 'Avbokad',
				standard: 'Standard',
				delta: 'Delta'
			},
			action: {
				rescheduleAppointment: 'Avboka möten'
			}
		},
		orderDetail: {
			throttleWallboxHint: {
				caption: 'Obs!',
				message: 'Laddboxens effektförbrukning måste minskas i enlighet med nätoperatörens krav.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'Ingen orderinformation tillgänglig än',
					message: 'Allteftersom ordern fortskrider kommer du att hitta all relevant information om ordern här.'
				},
				caption: {
					basicAnswers: 'Grundläggande svar',
					gridOperatorRegistration: 'Registrering av nätoperatör',
					gridOperatorDecision: 'Beslut av nätoperatör',
					preCheck: 'Förhandskontroll',
					offer: 'Erbjudande',
					approval: 'Godkännande'
				},
				precheck: {
					heading: 'Förhandskontroll',
					effort: 'Resultat av inledande kontroll: Tid och arbetsinsats för installation',
					questionnaire: {
						heading: 'Svar från kund'
					},
					installationType: {
						undetermined: 'Ej fastställd',
						standard: 'Standardinstallation',
						delta: 'Delta-installation'
					},
					decision: {
						accepted: 'Tillräcklig information',
						more: 'Mer information behövs',
						denied: 'Installation ej möjlig'
					},
					notes: 'Anm.',
					hours: 'timmar'
				},
				decision: {
					heading: 'Beslut av nätoperatör',
					decision: {
						pending: 'Ansökan om installation är fortfarande under behandling',
						accepted: 'Nätoperatören godkände installationen',
						denied: 'Nätoperatören nekade installationen'
					}
				},
				registration: {
					heading: 'Registrering av nätoperatör',
					documents: 'Dokument',
					gridOperator: 'Nätoperatör'
				},
				quotation: {
					heading: 'Offert',
					conditions: 'Villkor för erbjudandet'
				},
				complete: {
					success: {
						heading: 'Installationen genomfördes'
					},
					notes: 'Anm.',
					images: 'Bilder relaterade till installationen',
					documents: 'Dokument relaterade till installationen',
					signature: 'Kundens underskrift'
				}
			}
		},
		orderLineItems: {
			heading: 'Orderradartiklar',
			head: {
				title: 'Radartikel',
				description: 'Beskrivning',
				productCode: 'ABB:s produktkod'
			},
			serial: 'Serienummer för laddbox',
			shippingStatus: {
				label: 'Status för leverans #{{shipmentNumber}}',
				pending: 'Väntar',
				delivered: 'Levererad',
				expected: 'Leverans förväntad den '
			},
			empty: 'Inga orderradartiklar tillgängliga'
		}
	}
};

export default literals;
