import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiAuthEmitter: {
		error: {
			oauth: {
				retry: {
					label: 'Probeer opnieuw'
				},
				heading: 'Verificatie mislukt',
				message: 'Er is iets misgegaan tijdens het inloggen.'
			}
		}
	}
};

export default literals;
