import React, { ReactNode } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import { ButtonPrimary } from '../button-primary/ButtonPrimary';

import './TermsAcceptance.scss';

type TermsAcceptanceProps = {
	children: ReactNode,
	onAccept: () => void
};

export function TermsAcceptance(props: TermsAcceptanceProps) {

	const { children, onAccept } = props;

	const l10n = useL10n();

	return (
		<section className="terms-acceptance">
			<main className="terms-acceptance__main">
				{children}
			</main>
			<footer className="terms-acceptance__footer">
				<ButtonPrimary
					label={l10n.translate('sharedUiPrimitive.termsAccept.buttonLabel')}
					onClick={onAccept}
				/>
			</footer>
		</section>
	);
}
