import React from 'react';

import './AppLayoutMobileTopbarStickyBottom.scss';

import { AppLayoutNavigationProps } from '../../app-layout/app-layout-navigation/AppLayoutNavigation';

export function AppLayoutMobileTopbarStickyBottom(props: AppLayoutNavigationProps) {
	const { children } = props;

	return (
		<section className="app-layout-mobile-topbar-sticky-bottom">
			{children}
		</section>
	);
}
