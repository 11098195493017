import { ReactNode } from 'react';

import { ExpandableProvider } from '@abb-emobility/shared/interaction';

import './Information.scss';

export type InformationProps = {
	children?: ReactNode
};

export function Information(props: InformationProps) {
	const { children } = props;

	return (
		<ExpandableProvider>
			<article className="information">
				{children}
			</article>
		</ExpandableProvider>
	);
}
