import React from 'react';

import { InstallationPartnerOrderInformationQuotationModel } from '@abb-emobility/oms/domain-model';
import { calculateMajorUnitFromMinorUnit, getCurrencyByCode, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { OrderedInstallationPartnerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	OrderComment,
	SectionHeader,
	Separator,
	Specification,
	SpecificationItem
} from '@abb-emobility/shared/ui-primitive';

import './OrderInformationQuotation.scss';

type QuotationProps = {
	orderInformation: InstallationPartnerOrderInformationQuotationModel,
	includePricing?: boolean
};

export function OrderInformationQuotation(props: QuotationProps) {

	const { orderInformation, includePricing = true } = props;

	const l10n = useL10n();

	const currency = getCurrencyByCode(orderInformation.payload.currencyCode);
	if (currency === null) {
		throw new AppError('Currency with code ' + orderInformation.payload.currencyCode + ' not found');
	}

	const renderConditions = () => {
		if (orderInformation.payload.conditions) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.quotation.conditions')} />
					<p className="order-information-quotation__conditions">{orderInformation.payload.conditions}</p>
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderComment = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderPrice = (item: OrderedInstallationPartnerDeltaInstallationPackageModel): React.ReactNode => {
		if (!includePricing) {
			return null;
		}

		const currency = getCurrencyByCode(item.deltaPackage.currencyCode);
		const totalPrice = item.amount * item.deltaPackage.purchaseNetPrice;

		return (
			<div className="order-information-quotation-collection-item__header__item">
				<span className="order-information-quotation-collection-item__header__item__price">
					<span className="order-information-quotation-collection-item__header__item__price--default">
						{l10n.formatNumber(item.amount, 0)}
						&nbsp;&times;&nbsp;
						{getCurrencySymbolByCurrency(currency)}
						&nbsp;
						{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, item.deltaPackage.purchaseNetPrice), currency?.fraction ?? 0)}
					</span>
					/
					<span className="order-information-quotation-collection-item__header__item__price--bold">
						{getCurrencySymbolByCurrency(currency)}
						&nbsp;
						{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, totalPrice), currency?.fraction ?? 0)}
					</span>
				</span>
			</div>
		);
	};

	const renderTotalPrice = () => {
		if (!includePricing) {
			return null;
		}
		return (
			<footer className="order-information-quotation-collection__footer">
				<section className="order-information-quotation-collection__footer__wrapper">
					<div className="order-information-quotation-collection__footer__wrapper__price">
						<span className="order-information-quotation-collection__footer__wrapper__price__key">
							{l10n.translate('omsUiDomain.task.quotation.deltaPackages.fullPrice')}
						</span>
						<span className="order-information-quotation-collection__footer__wrapper__price__value">
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, orderInformation.payload.totalPurchaseNetPrice), currency.fraction)}
							{getCurrencySymbolByCurrency(currency)}
						</span>
					</div>
				</section>
			</footer>
		);
	};

	const renderPackages = () => {

		return orderInformation.payload.deltaPackages.map((item) => {
			const renderAttributes = () => {
				return item.deltaPackage.includedGoodsInternal?.map((good: string, index: number) => {
					return <SpecificationItem key={index} attribute={good} />;
				});
			};

			return (
				<article className="order-information-quotation-collection-item" key={item.deltaPackage.id}>
					<header className="order-information-quotation-collection-item__header">
						<div className="order-information-quotation-collection-item__header__item">
							<h1 className="order-information-quotation-collection-item__header__item__heading">
								{item.deltaPackage.name}
							</h1>
							<p className="order-information-quotation-collection-item__header__item__description">
								{item.deltaPackage.description}
							</p>
						</div>
						{renderPrice(item)}
					</header>

					<Separator />

					<main className="order-information-quotation-collection-item__main">
						<Specification>
							{renderAttributes()}
						</Specification>
					</main>
				</article>
			);
		});
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.quotation.heading')} />

				<section className="order-information-quotation-collection">
					<main className="order-information-quotation-collection__main">
						{renderPackages()}
					</main>
					{renderTotalPrice()}

				</section>
			</CollectionItemContentSection>

			{renderConditions()}
			{renderComment()}
		</>
	);

}
