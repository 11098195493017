import React, { ReactNode } from 'react';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { IconIdentifier, InformationIconValue, InformationInstallationDateTimeAddress } from '@abb-emobility/shared/ui-primitive';

import './AppointmentCollectionItem.scss';

export type AppointmentCollectionItemProps = {
	appointment: InstallerAppointmentModel,
	onClick: (appointmentId: string) => void
};

export function AppointmentCollectionItem(props: AppointmentCollectionItemProps) {

	const { appointment, onClick } = props;

	const l10n = useL10n();

	const isHighlighted = (): boolean => {
		return createDateFromTimestamp(appointment.period.start).getDate() === (new Date()).getDate();
	};

	const handleClick = () => {
		onClick(appointment.id);
	};

	const renderInstallationAddress = (): ReactNode => {
		if (appointment.customerFirstName === undefined && appointment.customerLastName === undefined && appointment.installationAddress === undefined) {
			return null;
		}

		let customerName: string | undefined;
		if (appointment.customerFirstName !== undefined && appointment.customerLastName !== undefined) {
			customerName = appointment.customerFirstName + ' ' + appointment.customerLastName;
		} else if (appointment.customerFirstName !== undefined && appointment.customerLastName === undefined) {
			customerName = appointment.customerFirstName;
		} else if (appointment.customerFirstName === undefined && appointment.customerLastName !== undefined) {
			customerName = appointment.customerLastName;
		}

		const values = [
			appointment.customerCompanyName,
			customerName,
			appointment.installationAddress
		];

		const value: string = values
			.filter((value): value is string => {
				return value !== undefined;
			})
			.join(', ');
		return (
			<InformationIconValue
				icon={IconIdentifier.HOUSE}
				value={value}
			/>
		);
	};

	return (
		<div id={appointment.id} className="appointment-collection-item">
			<InformationInstallationDateTimeAddress onClick={handleClick} isHighlighted={isHighlighted()}>
				<InformationIconValue
					icon={IconIdentifier.CALENDAR_BLANK}
					value={l10n.formatTimestampDate(appointment.period.start)}
					weekday={l10n.formatTimestampDayname(appointment.period.start)}
					label={appointment.installationType}
				/>
				<InformationIconValue
					icon={IconIdentifier.CLOCK}
					value={l10n.formatTimestampTime(appointment.period.start) + ' - ' + l10n.formatTimestampTime(appointment.period.end)}
				/>
				{renderInstallationAddress()}
			</InformationInstallationDateTimeAddress>
		</div>
	);
}
