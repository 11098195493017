import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiErrorHandler: {
		error: {
			generic: {
				heading: 'Er ging iets mis',
				message: 'Er is een fout opgetreden die niet goed is afgehandeld',
				resolveAction: {
					label: 'Opnieuw laden'
				}
			},
			recoverLabel: 'Probeer opnieuw',
			fetchHeading: 'Ophalen mislukt',
			mutateHeading: 'Update mislukt',
			createHeading: 'Creëren mislukt',
			deleteHeading: 'Verwijderen mislukt',
			taskAssignHeading: 'Toewijzen mislukt',
			taskCompleteHeading: 'Voltooiing mislukt'
		}
	}
};

export default literals;
