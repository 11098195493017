import './UserbarSection.scss';

import { TopbarSectionProps } from '../../topbar/topbar-section/TopbarSection';

export function UserbarSection(props: TopbarSectionProps) {

	const { children } = props;

	return (
		<div className="userbar-section">
			{children}
		</div>
	);
}
