import React, { ReactNode } from 'react';

import {
	InstallerAppointmentCollectionDataInterceptEmpty,
	InstallerAppointmentCollectionDataSuspense,
	useInstallerAppointmentCollectionData
} from '@abb-emobility/oms/data-provider';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import {
	createDateFromTimestamp
} from '@abb-emobility/shared/domain-model-foundation';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	AppLayoutMobileTopbarStickyBottom,
	NotificationBig,
	NotificationBigStatus,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';


import { groupAppointmentsByMonth, preprocessAppointments } from './AppointmentCollection.util';
import { AppointmentCollectionItem } from './appointment-collection-item/AppointmentCollectionItem';
import { AppointmentCollectionSection } from './appointment-collection-section/AppointmentCollectionSection';
import { AppointmentCollectionSubsection } from './appointment-collection-subsection/AppointmentCollectionSubsection';
import { useInstallationPartnerPath } from '../../router/Routes';
import { MobileTopbarCalendar } from '../mobile-topbar/mobile-topbar-calendar/MobileTopbarCalendar';

import './AppointmentCollection.scss';

export type AppointmentCollectionProps = {
	onNavigate: (appointmentId: string) => void
};

export function AppointmentCollection(props: AppointmentCollectionProps): React.JSX.Element {

	const { onNavigate } = props;

	const identity = useIdentity();
	const l10n = useL10n();
	const installationPartnerPath = useInstallationPartnerPath();

	const appointmentsSort = (left: InstallerAppointmentModel, right: InstallerAppointmentModel): number => {
		const leftPeriodStartsAt = createDateFromTimestamp(left.period.start).getTime();
		const rightPeriodStartsAt = createDateFromTimestamp(right.period.start).getTime();
		if (leftPeriodStartsAt === rightPeriodStartsAt) {
			return 0;
		}
		return leftPeriodStartsAt > rightPeriodStartsAt ? 1 : -1;
	};

	const appointmentsFilter = (value: InstallerAppointmentModel): boolean => {
		return value.installer.userName === identity.getIdentity().get()?.userName;
	};

	const installerAppointmentsData = useInstallerAppointmentCollectionData();
	const installerAppointments = installerAppointmentsData.query(appointmentsSort, appointmentsFilter);

	// preprocess installer appointments
	const { calendarDayList, appointmentsToday, appointmentsUpcoming } = preprocessAppointments(installerAppointments);
	const groupedAppointments = groupAppointmentsByMonth(appointmentsUpcoming);

	// NAVIGATE
	const handleNavigate = (appointmentId: string) => {
		onNavigate(appointmentId);
	};

	const renderTodayItems = (): Array<JSX.Element> => {
		return appointmentsToday.map((appointmentToday) => {
			return <AppointmentCollectionItem onClick={handleNavigate} appointment={appointmentToday} key={appointmentToday.id} />;
		});
	};

	const renderTodaySection = (): ReactNode => {
		if (appointmentsToday.length === 0) {
			return null;
		}

		return (
			<AppointmentCollectionSection isToday={true}>
				<AppointmentCollectionSubsection>
					{renderTodayItems()}
				</AppointmentCollectionSubsection>
			</AppointmentCollectionSection>
		);
	};

	const renderUpcomingItems = (): Array<JSX.Element> => {
		return groupedAppointments.map((appointmentMonthViewItem, index) => {
			const month = l10n.formatMonthname(createDateFromTimestamp(appointmentMonthViewItem.periodStartsAt), 'month');
			return (
				<AppointmentCollectionSubsection month={month} key={index}>
					{
						appointmentMonthViewItem.appointments.map((appointment): React.JSX.Element => {
							return <AppointmentCollectionItem onClick={handleNavigate} appointment={appointment} key={appointment.id} />;
						})
					}
				</AppointmentCollectionSubsection>);
		});

	};

	const renderUpcomingSection = (): ReactNode => {
		if (appointmentsUpcoming.length === 0) {
			return null;
		}

		return (
			<AppointmentCollectionSection>
				{renderUpcomingItems()}
			</AppointmentCollectionSection>
		);
	};

	const renderEmptyState = (): React.JSX.Element => {
		return (
			<NotificationBig
				status={NotificationBigStatus.NO_APPOINTMENT}
				heading={l10n.translate('omsInstallationPartnerServiceApp.appointmentCollection.emptyState.heading')}
				message={l10n.translate('omsInstallationPartnerServiceApp.appointmentCollection.emptyState.message')}
				mobile={true}
			/>
		);
	};

	return (
		<>
			<AppLayoutMobileTopbarStickyBottom>
				<MobileTopbarCalendar dayList={calendarDayList} relPagePath={installationPartnerPath} />
			</AppLayoutMobileTopbarStickyBottom>
			<main className="appointment-collection">
				<InstallerAppointmentCollectionDataSuspense pendingComponent={SpinnerCircle}>
					<InstallerAppointmentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState} filter={appointmentsFilter}>
						{renderTodaySection()}
						{renderUpcomingSection()}
					</InstallerAppointmentCollectionDataInterceptEmpty>
				</InstallerAppointmentCollectionDataSuspense>
			</main>
		</>
	);
}
