import { Gravatar } from '../../gravatar/Gravatar';
import { GravatarSize } from '../../gravatar/Gravatar.enums';

import './UserbarUser.scss';

export type TopbarMobileUserProps = {
	name?: string,
	mailAddress?: string,
	message: string
};

export function UserbarUser(props: TopbarMobileUserProps) {

	const { name, mailAddress, message } = props;

	const renderName = () => {
		if (name === undefined) {
			return null;
		}
		return (
			<div className="topbar-mobile-user__text__name">{name}</div>
		);
	};

	return (
		<div className="topbar-mobile-user">
			<Gravatar name={name} mailAddress={mailAddress} size={GravatarSize.EXTRA_LARGE} />
			<div className="topbar-mobile-user__text">
				<div className="topbar-mobile-user__text__message">{message}</div>
				{renderName()}
			</div>
		</div>
	);

}
