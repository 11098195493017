import { ButtonHTMLAttributes } from 'react';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './ButtonTertiary.scss';

export type ButtonTertiaryProps = {
	label: string,
	icon?: IconIdentifier
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonTertiary(props: ButtonTertiaryProps) {
	const { type = 'button', label, icon = IconIdentifier.CIRCLE, ...rest } = props;

	return (
		<button
			type={type}
			className="button-tertiary"
			{...rest}
		>
			<Icon name={icon} />
			{label}
		</button>
	);
}
