import React, { ReactNode } from 'react';

import {
	InstallationPartnerOrderInformationCollectionDataAppear,
	InstallationPartnerOrderInformationCollectionDataInterceptEmpty,
	InstallationPartnerOrderInformationCollectionDataSuspense,
	InstallationPartnerOrderInformationEntityDataProvider,
	useInstallationPartnerOrderInformationCollectionData
} from '@abb-emobility/oms/data-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card,
	CardHeader,
	Cardlayout,
	CardSection,
	Direction,
	Information,
	InformationHeader,
	InformationItems, NotificationBig, NotificationBigStatus,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { OrderInformationFactory } from './OrderInformationFactory';

type OrderInformationProps = {
	orderId: ModelPrimaryKey,
	includePricing?: boolean
};

export function OrderInformation(props: OrderInformationProps) {

	const { orderId, includePricing } = props;

	const l10n = useL10n();

	const orderInformationCollectionData = useInstallationPartnerOrderInformationCollectionData();
	const orderInformationCollection = orderInformationCollectionData.query();

	const renderSections = () => {
		return orderInformationCollection.map((orderInformation) => {
			return (
				<Information key={orderInformation.id}>
					<InformationHeader
						heading={l10n.translate(l10nLiteralFromEnumValue(orderInformation.type, 'omsUiDomain.orderDetail.informationList.caption'))}
						date={l10n.formatTimestampDateTime(orderInformation.createdAt)}
					/>
					<InformationItems>
						<UiErrorHandler>
							<InstallationPartnerOrderInformationEntityDataProvider
								primaryKey={orderInformation.id}
								pendingComponent={SpinnerCircle}
							>
								<OrderInformationFactory includePricing={includePricing} />
							</InstallationPartnerOrderInformationEntityDataProvider>
						</UiErrorHandler>
					</InformationItems>
				</Information>

			);
		});
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.NORESULT}
				heading={l10n.translate('omsUiDomain.orderDetail.informationList.emptyState.heading')}
				message={l10n.translate('omsUiDomain.orderDetail.informationList.emptyState.message')}
			/>
		);
	};

	return (
		<Card>
			<Cardlayout>
				<CardHeader heading={l10n.translate('omsUiDomain.orderDetail.informationList.heading')} />
				<Separator />
				<InstallationPartnerOrderInformationCollectionDataAppear orderId={orderId}>
					<InstallationPartnerOrderInformationCollectionDataSuspense pendingComponent={SpinnerCircle}>
						<InstallationPartnerOrderInformationCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
							<CardSection direction={Direction.COLUMN}>
								{renderSections()}
							</CardSection>
						</InstallationPartnerOrderInformationCollectionDataInterceptEmpty>
					</InstallationPartnerOrderInformationCollectionDataSuspense>
				</InstallationPartnerOrderInformationCollectionDataAppear>
			</Cardlayout>
		</Card>
	);
}
