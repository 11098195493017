import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	sharedUiAuthEmitter: {
		error: {
			oauth: {
				retry: {
					label: 'Retry'
				},
				heading: 'Authentication failed',
				message: 'Something went wrong while performing your login.'
			}
		}
	}
};

export default literals;
