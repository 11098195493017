import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import {
	createDateFromTimestamp,
	Timestamp
} from '@abb-emobility/shared/domain-model-foundation';
import { getCalendarDay, getToday, isSameDay, isSameMonth, isToday } from '@abb-emobility/shared/util';

import { CalendarDayViewItemType } from '../mobile-topbar/mobile-topbar-calendar/MobileTopbarCalendar.types';
import { createCalendarDayList } from '../mobile-topbar/mobile-topbar-calendar/MobileTopbarCalendar.util';

export type GroupedAppointments = {
	periodStartsAt: Timestamp,
	appointments: Array<InstallerAppointmentModel>
};

type PreprocessedAppointments = {
	calendarDayList: Array<CalendarDayViewItemType>,
	appointmentsToday: Array<InstallerAppointmentModel>,
	appointmentsUpcoming: Array<InstallerAppointmentModel>
};

export const preprocessAppointments = (appointments: ReadonlyArray<InstallerAppointmentModel>): PreprocessedAppointments => {
	appointments = [...appointments].sort((left, right) => {
		const leftDate = createDateFromTimestamp(left.periodStartsAt).getTime();
		const rightDate = createDateFromTimestamp(right.periodStartsAt).getTime();
		if (leftDate === rightDate) {
			return 0;
		}
		return leftDate < rightDate ? -1 : 1;
	});

	let end = getCalendarDay(10);
	if (appointments.length > 0) {
		const appointmentEnd = createDateFromTimestamp(appointments[appointments.length - 1].periodStartsAt);
		end = new Date(Math.max(end.getTime(), appointmentEnd.getTime()));
	}

	const calendarDayList = createCalendarDayList(getToday(), end);
	const appointmentsToday: Array<InstallerAppointmentModel> = [];
	const appointmentsUpcoming: Array<InstallerAppointmentModel> = [];

	appointments.forEach((appointment) => {

		calendarDayList.forEach((item) => {
				if (item.firstAppointment === null && isSameDay(createDateFromTimestamp(item.startTimestamp), createDateFromTimestamp(appointment.periodStartsAt))) {
					item.firstAppointment = appointment;
				}
				return;
			}
		);

		if (isToday(createDateFromTimestamp(appointment.periodStartsAt))) {
			appointmentsToday.push(appointment);
			return;
		}

		appointmentsUpcoming.push(appointment);
	});
	return { calendarDayList, appointmentsToday, appointmentsUpcoming };
};

export const groupAppointmentsByMonth = (appointments: Array<InstallerAppointmentModel>): Array<GroupedAppointments> => {
	const appointmentsGroupedByMonth: Array<GroupedAppointments> = [];
	let accumulator: Array<InstallerAppointmentModel> = [];

	appointments.forEach((appointment, index, appointments) => {

			accumulator.push(appointment);
			const lastElement = (): boolean => {
				return index === appointments.length - 1;
			};

			if (lastElement() || !isSameMonth(createDateFromTimestamp(appointment.periodStartsAt), createDateFromTimestamp(appointments[index + 1].periodStartsAt))) {
				appointmentsGroupedByMonth.push({
					periodStartsAt: appointment.periodStartsAt,
					appointments: accumulator
				});
				accumulator = [];
			}
		}
	);
	return appointmentsGroupedByMonth;
};
